import AdminLogin from 'project-name/components/AdminSection/LoginSection/AdminLogin'
import React from 'react'
const LoginScreen: React.FC = () => {
  return (
    <div>
        <AdminLogin/>
      
    </div>
  )
}

export default LoginScreen

import React, { useState, useEffect } from 'react';
import styles from './OnboardingSlider.module.css';
import profileImg from 'project-name/assets/img/admin/profile/guy-plaid-shirt.jpg';
import AdminTable from '../../../Global/AdminTable/AdminTable';
import Button from '@mui/material/Button';
import CandidateDetails from './CandidateDetailsSection/CandidateDetails';
import DocumentsSection from './DocumentsSection';
import CandidateForms from './CandidateForms/CandidateForms';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCandidateDocuments } from 'project-name/Redux/features/candidateDocumentSlice';
import { useDispatch } from 'react-redux';

import { RootState } from 'project-name/Redux/Store';
import apiClient from 'project-name/api/apiClient';
import {
   AppDispatch } from 'project-name/Redux/Store';
import { fetchAdminProfile } from 'project-name/Redux/features/adminProfileSlice';
import ConfirmationDialog from 'project-name/components/ConfirmationDialog';
const sections = {
  candidateDetails: 'CandidateDetails',
  documents: 'DocumentsSection',
  candidateForms: 'CandidateForms',
  actions: 'AdminTable',
};

const CandidateSlider: React.FC = () => {
  const [open, setOpen] = useState(false); // State to control the dialog open/close

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch: AppDispatch = useDispatch();
 
  const userId = localStorage.getItem("user_id")
  useEffect(() => {
   
    dispatch(fetchAdminProfile());
   
  }, []);
  
  useEffect(() => {
   
    dispatch(fetchCandidateDocuments(userId));
   
  }, []);
  let profileDetail = useSelector((state: RootState) => state.adminProfile.profile);
  const filteredProfile = profileDetail?.filter((profile:any) => profile.user_id === Number(userId));
  const profileList = filteredProfile?.[0] ?? [];
  
  const [activeSection, setActiveSection] = useState<string>(sections.candidateDetails);

  useEffect(() => {
    if (window.innerWidth > 960) {
      setActiveSection(sections.candidateDetails);
    }
  }, []);

  const handleDelete = async () => {
    try {
      const response = await apiClient.delete(`/api/admin/${userId}/delete-candidate`,
        {
          withCredentials:true,
        });
     
        if (response.status === 200) {
          toast.success('Candidate deleted successfully!');
        } else {
          
          toast.warn('Unexpected response, but the candidate was deleted.');
        }
        
      
    } catch (error) {
      
      toast.error('Failed to create candidate');
    }
    handleClose();
  };

  const renderSection = () => {
    switch (activeSection) {
      case sections.candidateDetails:
        return <CandidateDetails profileList={profileList} />;
      case sections.documents:
        return <DocumentsSection />;
      case sections.candidateForms:
        return <CandidateForms />;
      case sections.actions:
        return <AdminTable />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.mDiv}>
      
      <div className={styles.sDiv}>
        <h4>Candidate profiles</h4>
        <div>
          <Button
            color="primary"
            variant="contained"
            sx={{ padding: '10px 30px', textTransform: 'capitalize', fontWeight: '600' }}
            onClick={handleOpen}
            className={styles.positionStatic}
          >
            Delete Profile
          </Button>
          <ConfirmationDialog
          open={open}
          onClose={handleClose}
          onConfirm={handleDelete}
          title="Confirm Delete"
          message="Are you sure you want to delete this user?"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          />
        </div>
      </div>

      <div className={styles.profileDiv}>
        <div>
          <img src={profileImg} alt="Candidate profile" />
        </div>
        <div>
          <p className={styles.head1}>Candidate name</p>
          <p className={styles.head2}>{profileList.full_name}</p>
        </div>
        <div>
          <p className={styles.head1}>Tower name</p>
          <p className={styles.head2}>{profileList.tower}</p>
        </div>
        <div>
          <p className={styles.head1}>Candidate PID</p>
          <p className={styles.head2}>{profileList.position_id}</p>
        </div>
        <div>
          <p className={styles.head1}>Joining date</p>
          <p className={styles.head2}>{new Date(profileList.d_o_j).toLocaleDateString('en-GB')}</p>
        </div>
        <div>
          <p className={styles.head1}>Onboarding status</p>
          <p className={styles.head2}>{profileList.status_name}</p>
        </div>
        <div>
          <p className={styles.head1}>Location</p>
          <p className={styles.head2}>{profileList.office_location}</p>
        </div>
      </div>

      <div className={styles.onboardDiv}>
        {Object.entries(sections).map(([key, value]) => (
          <div key={key}>
            <span
              onClick={() => setActiveSection(value)}
              className={activeSection === value ? styles.activespanButton : styles.spanButton}
            >
              {value.replace(/([A-Z])/g, ' $1').trim()}
            </span>
          </div>
        ))}
      </div>

      {renderSection()}
      
    </div>
  );
};

export default CandidateSlider;

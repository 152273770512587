import HorizontalNavbar from 'project-name/components/AdminSection/Global/Navbar/HorizontalNavbar'
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb'
import AdminPersonalDetails from 'project-name/components/AdminSection/AdminProfileSection/AdminPersonalDetails/AdminPersonalDetails'
import styles from 'project-name/css/admin/adminLayout.module.css'

const adminPersonalLayout: React.FC = () => {
  return (
    <div className={styles.mDiv}>
      <HorizontalNavbar />
      <div style={{padding:'40px'}}><Breadcrumb bread1='Home' bread2='Candidate profile' link1='/' /></div>
      
      <AdminPersonalDetails/>
  
      
    </div>
  )
}

export default adminPersonalLayout

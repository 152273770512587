import React from 'react'
import CandidateSection from 'project-name/components/AdminSection/CandiateSection/CandidateSection'


const candidateScreen: React.FC = () => {
  return (
    <div>
      <CandidateSection data-testid="candidate-section" />
      
    </div>
  )
}

export default candidateScreen
import styles from './BreadCumbs.module.css';

interface Breadcrumb {
  bread1: string;
  bread2: string;
  bread3?: string;
  link1?: string;
  link2?: string;
  link3?: string;
  threeDiv?: boolean;
}

const Breadcrumb: React.FC<Breadcrumb> = ({ bread1, bread2, bread3, threeDiv, link1, link2, link3 }) => {
  // const handleClick = (index: number) => {
  //   setActiveItem(index);
  // };

  return (
    <ul className={styles.breadcrumb}>
      {threeDiv ? (
        <>
          {' '}
          <li>
            <a href={link1}> {bread1}</a>
          </li>
          <li>
            <a href={link2}>{bread2}</a>
          </li>
          <li className={styles.active}>
            <a href={link3}>{bread3}</a>
          </li>
        </>
      ) : (
        <>
          <li>
            <a href={link1}> {bread1}</a>
          </li>
          <li className={styles.active}>
            <a href={link2}>{bread2}</a>
          </li>
        </>
      )}
    </ul>
  );
};

export default Breadcrumb;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styles from './CandidateTable.module.css'; // Import your CSS module here
import { Box } from '@mui/material';

interface CandidateRowProps {
  profile: {
    full_name: string;
    tower: string;
    function: string;
    employee_band: string;
    office_location: string;
    bgv_status: string;
    d_o_j: string;
    is_documents_moved: string;
    status_name: string;
    user_id: number;
  };
}

const getStatusIcon = (documents: string) => {
 
  if (documents === 'Finished') {
    return <CheckCircleIcon style={{ color: 'green' }} />;
  } else if (documents === 'Rejected') {
    return <CancelIcon style={{ color: 'red' }} />;
  } else if (documents === 'Pending') {
    return <AccessTimeIcon style={{ color: '#745500' }} />;
  }
  return null;
};


const CandidateRow: React.FC<CandidateRowProps> = ({ profile }) => {
  const navigate = useNavigate();
  const getToCandidate = (id: number) => {
    navigate("/candidate-profile",{
      state: {user_id: id}
  
    })
  }
  return (

    <div onClick={()=>getToCandidate(profile.user_id)} >
      <div className={styles.savedProfilesList}>
        <p>{profile.full_name}</p>
        <p>{profile.tower}</p>
        <p>{profile.function}</p>
        <p>{profile.employee_band}</p>
        <p>{profile.office_location}</p>
        <p>{profile.bgv_status}</p>
        <p>{new Date(profile.d_o_j).toLocaleDateString()}</p>
        <p className={`${styles.docstatus} ${styles[profile.is_documents_moved?.toLowerCase()]}`}>
          {getStatusIcon(profile.is_documents_moved)} {profile.is_documents_moved}
        </p>
        <p className={`${styles[profile.status_name?.toLowerCase().replace(/\s/g, '')]}`}>{profile.status_name}</p>
      </div>
    </div>
  );
};

export default CandidateRow;

import React from 'react';
import styles from './profilebar2Styles.module.css';
import user from '../../../../assets/img/user/user1.png';
import Notification from 'project-name/components/CandidateSecion/Global/Notification/Notification';

const Profilebar = () => {
  return (
    <div className={styles.mPro}>
      <div className={styles.mPro2}>
        <div className={styles.userpic}>
          <img src={user} alt="" />
        </div>
        <div className={styles.username}>
          <h2>Alexandera</h2>
          <p>Intern</p>
        </div>
      </div>
      <div>
        <Notification />
      </div>
    </div>
  );
};

export default Profilebar;

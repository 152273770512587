import React, { createContext, useState, useEffect, ReactNode, FC } from 'react';
import apiClient from 'project-name/api/apiClient';
interface AuthContextProps {
  authenticated: boolean | null;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean | null>>;
  userName: string | "";
  setUserName: React.Dispatch<React.SetStateAction<string | "">>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  const [userName, setUserName] = useState<string>("")

  useEffect(() => {
    // Check authentication status once when the app loads
    apiClient.get(`/api/protected`, { withCredentials: true })
      .then(response => {
        if (response.status === 200) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      })
      .catch(() => {
        setAuthenticated(false);
      });
  }, []);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, userName, setUserName }}>
      {children}
    </AuthContext.Provider>
  );
};

import React from 'react';
import styles from './RecruiterTable.module.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className={styles.ListPaginationBox}>
      <div className={styles.pagination} id="pagination">
        <a href="#" onClick={() => onPageChange(currentPage - 1)}>
          &lt;
        </a>
        {Array.from(Array(totalPages).keys()).map((pageNumber) => (
          <a
            key={pageNumber}
            href="#"
            className={currentPage === pageNumber + 1 ? styles.active : ''}
            onClick={() => onPageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </a>
        ))}
        <a href="#" onClick={() => onPageChange(currentPage + 1)}>
          &gt;
        </a>
      </div>
    </div>
  );
};

export default Pagination;


import { useState } from 'react';
import styles from "../Navbar/phoneMenu.module.css"
import { RiHomeLine } from 'react-icons/ri';

import { RiTeamLine } from 'react-icons/ri';
import DescriptionIcon from '@mui/icons-material/Description';


import { IoMdHelpCircleOutline } from 'react-icons/io';


const PhoneMenu = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index:any) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const handleClick = (index:any) => {
    setActiveIndex(index);
  };

  const icons = [
    { icon: <RiHomeLine />, text: 'Home' },
    { icon: <RiTeamLine />, text: 'Teams' },
    { icon: <DescriptionIcon />, text: 'Docs' },
    { icon: <IoMdHelpCircleOutline />, text: 'Help' },
  ];
  return (
    <div className={styles.bottomMenu}>
    {icons.map((item, index) => (
      <div
        key={index}
        className={`${styles.iconContainer} ${activeIndex === index ? styles.active : ''}`}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(index)}
      >
        {item.icon}
        {(hoveredIndex === index || activeIndex === index) && (
          <p className={styles.iconText}>{item.text}</p>
        )}
      </div>
    ))}
  </div>
  )
}

export default PhoneMenu

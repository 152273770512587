import styles from 'project-name/css/admin/adminLayout.module.css'
import { useEffect } from 'react';

import HorizontalNavbar from 'project-name/components/AdminSection/Global/Navbar/HorizontalNavbar';
import AdminProgress from 'project-name/components/AdminSection/AdminHome/adminProgress';
import SavedProfilesSection from 'project-name/components/AdminSection/Global/SavedProfilesSection/SavedProfilesSection';
import { fetchAdminProfile } from 'project-name/Redux/features/adminProfileSlice';
import { useDispatch } from 'react-redux';
import {
   AppDispatch } from 'project-name/Redux/Store';


const adminHome = () => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    console.log("hey")
    dispatch(fetchAdminProfile());
   
  }, [dispatch]);
  return (
    <div className={styles.mDiv}>
      
        <HorizontalNavbar/>
        <AdminProgress/>
        <SavedProfilesSection/>
        
      
    </div>
  )
}

export default adminHome

import React from 'react'
import HorizontalNavbar from '../../Global/Navbar/HorizontalNavbar'
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb'
import Onboarding from 'project-name/components/AdminSection/Global/RecruiterSection/OnboardingSlider/OnboardingSlider'
import styles from 'project-name/css/admin/adminLayout.module.css'

const RecruiterProfileSection: React.FC = () => {
  return (
    <div className={styles.mDiv} data-testid="main-div">
        <HorizontalNavbar/>
        <div style={{padding:'40px'}}>
        <Breadcrumb bread1="Home" bread2="Recruiter profile" bread3="Recruiter" link1="/admin-home" link2="/recruiters"  threeDiv={true}/>

        </div>
        <Onboarding/>
      
    </div>
  )
}

export default RecruiterProfileSection

// GeneralSettings.tsx

import  { useState, ChangeEvent } from 'react';
import { SelectChangeEvent } from '@mui/material';
import FormSelectWrapper from './FormSelectWrapper';
import GlobalButton from './GlobalButton';
import styles from './OnboardingSlider.module.css';

interface FormData {
  form_data: string;
}

const GeneralSettings = () => {
  const [formData, setFormData] = useState<FormData>({
    form_data: '',
  });

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({
    form_data: '',
  });

  const validateField = (name: keyof FormData, value: string) => {
    return value.trim() ? '' : 'This field is required';
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string>,
  ) => {
    if ('target' in event) {
      const { name, value } = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    } else {
      // Handle SelectChangeEvent<string> event
      const { value } = event;
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          form_data: value,
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
  };

  const confirmOption = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  return (
    <div>
      <div className={styles.mainformDiv}>
        <div>
          <FormSelectWrapper
            label="Download report 1"
            name="download_report_1"
          
            inputlabel="Full time"
            value={formData.form_data}
            error={formErrors.form_data}
            handleChange={handleChange}
            options={confirmOption}

          />

          <FormSelectWrapper
            label="Download report 2"
            name="download_report_2"
            inputlabel="Full time"
            value={formData.form_data}
            error={formErrors.form_data}
            handleChange={handleChange}
            options={confirmOption}
      
          />
        </div>

        <div>
          <FormSelectWrapper
            label="Allow update candidate 1"
            name="download_report_3"
            inputlabel="Full time"
            value={formData.form_data}
            error={formErrors.form_data}
            handleChange={handleChange}
            options={confirmOption}

          />

          <FormSelectWrapper
            label="Allow update candidate 2"
            name="download_report_4"
            inputlabel="Full time"
            value={formData.form_data}
            error={formErrors.form_data}
            handleChange={handleChange}
            options={confirmOption}

          />
        </div>

        <div>
          <FormSelectWrapper
            label="Selection based permission 1"
            name="download_report_5"
            inputlabel="Full time"
            value={formData.form_data}
            error={formErrors.form_data}
            handleChange={handleChange}
            options={confirmOption}

          />

          <FormSelectWrapper
            label="Selection based permission 2"
            name="download_report_6"
            inputlabel="Full time"
            value={formData.form_data}
            error={formErrors.form_data}
            handleChange={handleChange}
            options={confirmOption}

          />
        </div>
      </div>

      <GlobalButton />
    </div>
  );
};

export default GeneralSettings;

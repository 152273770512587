import React, { useState } from 'react';
import FormSelect from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormSelect';
import styles from './OnboardingSlider.module.css';
import GlobalButton from './GlobalButton';

interface FormData {
  form_data: string;
}

interface FormHandlerProps {
  formTitle: string;
}

const initialFormData: FormData = {
  form_data: '', // Initial value for form_data
};

const FormHandler: React.FC<FormHandlerProps> = ({ formTitle }) => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});

  const validateField = (name: keyof FormData, value: string) => {
    if (value.trim() === '') {
      return 'This field is required';
    }
    return ''; // No error
  };

  const handleChange = (name: keyof FormData, value: string) => {
    const newFormData = {
      ...formData,
      [name]: value,
    };

    // Update form data
    setFormData(newFormData);

    // Validate field and update errors
    const error = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const confirmOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  return (
    <div>
      <div className={styles.candidateAccessDiv}>
        <div className={styles.onboardStatusDiv}>
          <div>
            <p className={styles.head}>{formTitle}</p>
            <FormSelect
              label="Lorem Ipsum is simply dummy text of the printing industry."
              name="form_data"
              inputlabel="Full time"
              value={formData.form_data}
              error={formErrors.form_data}
              handleChange={(event) => handleChange('form_data', event.target.value)}
              options={confirmOptions}
              datatestid="form-select"
            
              
            />
            {formErrors.form_data && <div className="error">{formErrors.form_data}</div>}
          </div>
          <div></div>
        </div>
      </div>
      <GlobalButton />
    </div>
  );
};

export default FormHandler;

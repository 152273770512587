import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#e6b611' : '#308fe8',
  },
}));

function GradientCircularProgress() {
  return (
    <React.Fragment>
      <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#e01cd5" />
        <stop offset="100%" stopColor="#1CB5E0" />
      </linearGradient>
    </React.Fragment>
  );
}

interface CustomizedProgressBarsProps {
  value: number;
}

export default function CustomizedProgressBars({ value }: CustomizedProgressBarsProps) {
  return (
    <Stack sx={{ flexGrow: 1 }} width={'92%'}>
      <GradientCircularProgress />
      <br />
      <BorderLinearProgress variant="determinate" value={value} />
    </Stack>
  );
}
import styles from 'project-name/css/layout.module.css';
import VerticalNavbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
import Layer2 from 'project-name/components/CandidateSecion/Documents/Layer2';
import BankForm from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/BankForm/BankForm';
import { ActiveItemProvider } from 'project-name/components/CandidateSecion/Global/Navbar/ActiveItemContext';
import useBeforeUnload from 'project-name/components/hooks/useBeforeUnload';
import DocumentsLayer from 'project-name/components/CandidateSecion/Documents/DocumentsLayer';


const BankFormLayout = () => {
  useBeforeUnload();
    return (

      <ActiveItemProvider>
        <div className={styles.mDiv}>
          <div>
            <VerticalNavbar />
          </div>
          <div className={styles.Layout3Div}>
            <Breadcrumb
              bread1="Home"
              bread2="Documentation"
              bread3="Personal account details"
              threeDiv={true}
              link1="/"
              link2="/documents"
            />
            <DocumentsLayer title="Personal account details" />
            <BankForm />
   
          </div>
        </div>
        </ActiveItemProvider>
      );
}

export default BankFormLayout;




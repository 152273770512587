// candidateSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Candidate {
    id: number;
    candidate_id: number | null;
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
    designation: string;
    employee_band: string;
    office_location: string;
    local_entity: string;
    function: string;
    tower: string;
    hiring_manager: string;
    hiring_manager_email: string;
    pbp_name: string;
    pbp_email: string;
    position_id: number;
    gender: string;
    d_o_j: string;
    offer_accept_status: string;
    bgv_initiated: string;
    bgv_status: string;
    bgv_case_id: number;
    bgv_report_url: string;
    bgv_start_time: string;
    is_documents_moved: string;
    laptop_requested: string;
    doj_status: string;
    parking_space_required: string;
    is_acknowledged: string;
    user_id: number;
    recruiter_id: number | null;
    created_by: number | null;
    created_at: string;
    updated_at: string | null;
}

interface pdfData{
    [category:string]:{
        [filename:string]:string
    }
}

interface PersonalDetails{
    phone_no: string;
}

interface CandidateState {
    candidate: Candidate | null;
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null;
    pdfData: pdfData;
    personalDetails: PersonalDetails;

}

const initialState: CandidateState = {
    candidate: null,
    status: 'idle',
    error: null,
    pdfData: {},
    personalDetails: {
        phone_no: ''
    }
};

const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        setCandidate: (state, action: PayloadAction<Candidate>) => {
            state.candidate = action.payload;
        },
        setPdfData: (state, action: PayloadAction<pdfData>) => {
            state.pdfData = action.payload;
        },
        setPersonalDetails: (state, action: PayloadAction<PersonalDetails>) => {
            state.personalDetails = action.payload;
        }
    },
});

export const { setCandidate ,setPdfData , setPersonalDetails} = candidateSlice.actions;
export default candidateSlice.reducer;
import React from 'react'
import { Button } from '@mui/material';

const GlobalButton = () => {
  return (
    <div className="globalBtn">
    <Button variant="outlined">Clear field</Button>
    <Button type="submit" variant="contained" color="primary">
      Save settings
    </Button>
  </div>
  )
}

export default GlobalButton

import React, { useState, ChangeEvent, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material';
import styles from "../../../../CandiateSection/CandidateProfileSection/OnboardingSlider/OnboardingSlider.module.css"
// import styles from '../../components/AdminSection/CandiateSection/CandidateProfileSection/OnboardingSlider/OnboardingSlider.module.css';
import FormField from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormField';
import FormSelect from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormSelect';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'project-name/Redux/Store';

import { submitCandidateData } from 'project-name/Redux/features/createCandidateSlice';
import { updateCandidateDetails } from 'project-name/Redux/features/updateCandidateSlice';


interface CandidateData {

  designation: string;
  full_name: string
  cost_center: string;
  employee_band: string;
  office_location: string;
  tower: string;
  official_mail_id: string;
  local_entity: string;
  function: string;
  bgv_initiated: string;
  laptop_requested: string;
  doj_status: string;
  parking_space_required: string;
d_o_j: string;
  recruiter_email: string;
  hiring_manager: string;
  hiring_manager_email: string;
  pbp_name: string;
  pbp_email: string;
  position_id: string
  bgv_start_time: string
  bgv_status: string
  bgv_case_id: string
  bgv_report_url: string
  is_documents_moved: string
  offer_accept_status: string
  email: string,
  employee_id: string,
  first_name: string
  last_name: string,
  gender: string,
  candidate_type:string
}
interface CandidateDetailsProps {
  profileList?: CandidateData[]; // Replace `any` with the exact type if possible
}
const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};
const CandidateDetails: React.FC<CandidateDetailsProps> = ({ profileList }) => {
  const dispatch: AppDispatch = useDispatch();
  

  const [addCandidateData, setAddCandidateData] = useState<any>({
    designation: '',
    cost_center: '',
    employee_band: '',
    employee_id: "",
    office_location: '',
    tower: '',
    first_name: "",
    last_name: "",
    full_name: "",
    gender: "",
    local_entity: '',
    function: '',
    bgv_initiated: '',
    laptop_requested: '',
    d_o_j: '',
    parking_space_required: '',
is_acknowledged: '',
    recruiter_email: '',
    hiring_manager: '',
    hiring_manager_email: '',
    pbp_name: '',
    pbp_email: '',
    position_id: "",
    email: "",
    bgv_start_time: "",
    bgv_status: "",
    bgv_case_id: "",
    bgv_report_url: "",
    offer_accept_status: "",
    is_documents_moved: "",
    candidate_type:""




  })

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if ((profileList && !isEmptyObject(profileList)) && !isInitialized) {
      setAddCandidateData(profileList);
      setIsInitialized(true);
    }
  }, [profileList, isInitialized]);



  const [formErrors, setFormErrors] = useState<Partial<CandidateData>>({});

  const validateForm = () => {
    const errors: Partial<CandidateData> = {};
    Object.keys(addCandidateData).forEach((key) => {
      if (!addCandidateData[key as keyof CandidateData]) {
        errors[key as keyof CandidateData] = 'This field is required';
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdate = () => {


    //     if (validateForm()) {
    //       const formData = new FormData();
    //       Object.keys(addCandidateData).forEach((key) => {
    //         formData.append(key, addCandidateData[key]);
    //       });
    // alert("update")

    dispatch(updateCandidateDetails(addCandidateData));




  };




  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string>,
  ) => {
    const { name, value } = event.target as { name: string; value: string; };

    setAddCandidateData((prevData: any) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };


      if (name === 'firstName' || name === 'lastName') {
        updatedData.full_name = `${updatedData.firstName} ${updatedData.lastName}`.trim();
      }

      return updatedData;
    });
  };


  const selectOptions = [
    { value: 'Accepted', label: 'Accepted' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'InProgress', label: 'InProgress' },
  ];
  const CandidateOptions = [
    { value: 'Full time Fresher', label: 'Full time Fresher' },
    { value: 'Full time Experienced', label: 'Full time Experienced' },
    { value: 'Intern', label: 'Intern' },
  ];

  return (
    <div className={styles.candDiv}>
      
      <div className={styles.candDiv1}>
        <div className={styles.mainFormDiv}>
          <h4>Primary details</h4>
          <div className={styles.formDiv}>

            <FormField
              label="First Name"
              name="first_name"
              type="text"
              value={addCandidateData.first_name}
              onChange={handleChange}
              error={formErrors.designation}
              cssInput1="formGroup"
              cssInput2="label1"
              placeholder="Enter Details"
            />
            <FormField
              label="Last Name"
              name="last_name"
              type="text"
              value={addCandidateData.last_name}
              onChange={handleChange}
              error={formErrors.last_name}
              cssInput1="formGroup"
              cssInput2="label1"
              placeholder="Enter Details"
            />

            <FormField
              label="Gender"
              name="gender"
              type="text"
              value={addCandidateData.gender}
              onChange={handleChange}
              error={formErrors.gender}
              cssInput1="formGroup"
              cssInput2="label1"
              placeholder="Enter Details"
              
            />


            <FormField
              label="Designation"
              name="designation"
              type="text"
              value={addCandidateData.designation}
              onChange={handleChange}
              error={formErrors.designation}
              cssInput1="formGroup"
              cssInput2="label1"
              placeholder="Enter Details"
            />
            <FormField
              label="Cost center"
              name="cost_center"
              type="text"

              value={addCandidateData.cost_center}
              onChange={handleChange}
              error={formErrors.cost_center}
              cssInput1="formGroup"
              cssInput2="label1"
              cssInput4="formControl"
              placeholder="Enter Details"
            />
            
            <FormSelect
                  label="Candidate Type"
                  name="candidate_type"
                  value={addCandidateData.candidate_type}
                  error={formErrors.candidate_type}
                  inputlabel="Full time"
                  handleChange={handleChange}
                  options={ CandidateOptions}
                  selectCss1="formGroup"
                  selectCss2="label1"
                  selectCss3="formControl"
                />
            <FormField

              label="Employee Band"
              name="employee_band"
              type="text"
              value={addCandidateData.employee_band}
              onChange={handleChange}
              error={formErrors.employee_band}
              cssInput1="formGroup"
              cssInput2="label1"
              placeholder="Enter Details"
            />
            <div className={styles.subInput}>
            <FormField

label="Location"
name="Location"
type="text"
value={addCandidateData.office_location}
onChange={handleChange}
error={formErrors.office_location}
cssInput1="formGroup"
cssInput2="label1"
placeholder="Enter Details"
/>
              <FormField

label="Tower"
name="tower"
type="text"
value={addCandidateData.tower}
onChange={handleChange}
error={formErrors.tower}
cssInput1="formGroup"
cssInput2="label1"
placeholder="Enter Details"
/>
            </div>
            <FormField
              label="Official mail ID"
              name="email"
              type="text"

              value={addCandidateData.email}
              onChange={handleChange}
              error={formErrors.email}
              cssInput1="formGroup"
              cssInput2="label1"
              placeholder="aaravtanvar44@gmail.com"
            />
            <div className={styles.subInput}>
              <FormField
                label="Local entity"
                name="local_entity"
                type="text"

                value={addCandidateData.local_entity}
                onChange={handleChange}
                error={formErrors.local_entity}
                cssInput1="formGroup"
                cssInput2="label1"
                placeholder="Entry here"
              />
              <FormField
                label="Date of Joining"
                name="d_o_j"
                type="date"
                value={addCandidateData.d_o_j ? addCandidateData.d_o_j.split('T')[0] : ''}
                onChange={handleChange}
                error={formErrors.d_o_j}
                cssInput1="formGroup"
                cssInput2="label1"
                cssInput4="formControl"
                placeholder="None"
              />
            </div>
            <div className={styles.subInput}>
            <FormField
                  label="Function"
                  name="function"
                  type="text"

                  value={addCandidateData.function}
                  onChange={handleChange}
                  error={formErrors.function}
                  cssInput1="formGroup"
                  cssInput2="label1"
                  cssInput4="formControl"
                  placeholder="None"
                />


              <FormField
                label="Position Id"
                name="position_id"
                type="text"

                value={addCandidateData.position_id}
                onChange={handleChange}
                error={formErrors.position_id}
                cssInput1="formGroup"
                cssInput2="label1"
                cssInput4="formControl"
                placeholder="None"
              />
            </div>
          </div>
        </div>
       
      </div>
      <div className={styles.candDiv1}>
      {/* <div className={styles.mainFormDiv}>
          <h4>People business partner details</h4>
          <div className={styles.formDiv}>
            <div className={styles.subInput}>
              <FormSelect
                label="BGV Initiated"
                name="bgv_initiated"
                value={addCandidateData.bgv_initiated}
                error={formErrors.bgv_initiated}
                inputlabel="Full time"
                handleChange={handleChange}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                selectCss1="formGroup"
                selectCss2="label1"
                selectCss3="formControl"
              />

              <FormField
                label="BGV Initiation TimeStamp"
                name="bgv_start_time"
                type="date"
                value={addCandidateData.bgv_start_time ? addCandidateData.bgv_start_time.split('T')[0] : ''}

                onChange={handleChange}
                error={formErrors.bgv_start_time}
                cssInput1="formGroup"
                cssInput2="label1"
                cssInput4="formControl"
                placeholder="None"
              />
            </div>
            <div className={styles.subInput}>

              <FormSelect
                label="BGV Status"
                name="bgv_status"
                value={addCandidateData.bgv_status}
                error={formErrors.bgv_status}
                inputlabel="Full time"
                handleChange={handleChange}
                options={[
                  { value: 'Initiated', label: 'Initiated' },
                  { value: 'Uninitiated', label: 'Uninitiated' },
                  { value: 'InProgress', label: 'InProgress' },
                  { value: 'Rejected', label: 'Rejected' },
                  { value: 'Confirmed', label: 'Confirmed' },
                ]}
                selectCss1="formGroup"
                selectCss2="label1"
                selectCss3="formControl"
              />
              <FormField
                label="BGV CaseId"
                name="bgv_case_id"
                type="text"

                value={addCandidateData.bgv_case_id}
                onChange={handleChange}
                error={formErrors.bgv_case_id}
                cssInput1="formGroup"
                cssInput2="label1"
                cssInput4="formControl"
                placeholder="None"
              />
            </div>
            <div className={styles.subInput}>
              <FormField
                label="BGV Report URL"
                name="bgv_report_url"
                type="text"

                value={addCandidateData.bgv_report_url}
                onChange={handleChange}
                error={formErrors.bgv_report_url}
                cssInput1="formGroup"
                cssInput2="label1"
                 cssInput4="formControl"
                placeholder="Entry here"
              />
<FormField
            label="Documents to Azure"
            name="is_documents_moved"
            type="text"

            value={addCandidateData.is_documents_moved}
            onChange={handleChange}
            error={formErrors.is_documents_moved}
            cssInput1="formGroup"
            cssInput2="label1"
            cssInput4="formControl"
            placeholder="None"
          />



         

            </div>
          </div>
        </div> */}

        <div className={styles.mainFormDiv}>
          
          <h4>Recruiter details</h4>
          <div className={styles.formDiv}>

            <FormField
              label="Recruiter email"
              name="recruiter_email"
              type="text"

              value={addCandidateData.recruiter_email}
              onChange={handleChange}
              error={formErrors.recruiter_email}
              cssInput1="formGroup"
              cssInput2="label1"
              cssInput4="formControl"
              placeholder="reddysandeep88@gmail.com"
            />
          </div>
          <h4 className={styles.head4}>Manager Details</h4>
          <div className={styles.formDiv}>
            <FormField
              label="Hiring Manager name"
              name="hiring_manager"
              type="text"

              value={addCandidateData.hiring_manager}
              onChange={handleChange}
              error={formErrors.hiring_manager}
              cssInput1="formGroup"
              cssInput2="label1"
              cssInput4="formControl"
              placeholder="Sandeep Reddy"
            />
            <FormField
              label="Manager email"
              name="hiring_manager_email"
              type="text"

              value={addCandidateData.hiring_manager_email}
              onChange={handleChange}
              error={formErrors.hiring_manager_email}
              cssInput1="formGroup"
              cssInput2="label1"
              cssInput4="formControl"
              
            />
          </div>
          <h4 className={styles.head4}>People business partner details</h4>
          <div className={styles.formDiv}>
            <FormField
              label="People business partner name"
              name="pbp_name"
              type="text"

              value={addCandidateData.pbp_name}
              onChange={handleChange}
              error={formErrors.pbp_name}
              cssInput1="formGroup"
              cssInput2="label1"
              cssInput4="formControl"
              placeholder="Sandeep Reddy"
            />
            <FormField
              label="People business partner email ID"
              name="pbp_email"
              type="text"

              value={addCandidateData.pbp_email}
              onChange={handleChange}
              error={formErrors.pbp_email}
              cssInput1="formGroup"
              cssInput2="label1"
              cssInput4="formControl"
              placeholder="reddysandeep88@gmail.com"
            />
          </div>

          {/* <div className={styles.mainFormDiv}> */}
          <h4>Other Details</h4>
          <div className={styles.formDiv}>
            <div className={styles.subInput}>
              <FormSelect
                label="offer acceptance status"
                name="offer_accept_status"
                value={addCandidateData.offer_accept_status}
                error={formErrors.offer_accept_status}
                inputlabel="Full time"
                handleChange={handleChange}
                options={selectOptions}
                selectCss1="formGroup"
                selectCss2="label1"
                selectCss3="formControl"
              />


              <FormSelect
                label="Laptop type requested"
                name="laptop_requested"
                value={addCandidateData.laptop_requested}
                error={formErrors.laptop_requested}
                inputlabel="Full time"
                handleChange={handleChange}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                selectCss1="formGroup"
                selectCss2="label1"
                selectCss3="formControl"
              />

            </div>
            <div className={styles.subInput}>
              <FormSelect
                label="DOJ conformation status"
                name="doj_status"
                value={addCandidateData.doj_status}
                error={formErrors.doj_status}
                inputlabel="Full time"
                handleChange={handleChange}
                options={[
                  { value: 'Confirmed', label: 'Confirmed' },
                  { value: 'Rejected', label: 'Rejected' }
                ]}
                selectCss1="formGroup"
                selectCss2="label1"
                selectCss3="formControl"
              />

              <FormSelect
                label="Parking space required"
                name="parking_space_required"
                value={addCandidateData.parking_space_required}
                error={formErrors.parking_space_required}
                inputlabel="Full time"
                handleChange={handleChange}
                options={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                selectCss1="formGroup"
                selectCss2="label1"
                selectCss3="formControl"
              />

            </div>
          </div>
          {/* </div> */}
          <div >

            <Button variant="contained" color="primary" sx={{ marginLeft: 2, py: "15px", fontSize: "12px", borderRadius: "10px", px: "50px", marginTop: "20px" }} onClick={handleUpdate}       >
              Update Candidate
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateDetails
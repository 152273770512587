import React, { useState, useEffect } from 'react';
import styles from './TicketTable.module.css';
import imgUser from 'project-name/assets/img/admin/profile/guy-plaid-shirt.jpg';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Ticket } from 'project-name/Redux/features/adminTicketSlice';
import apiClient from 'project-name/api/apiClient';
import { useDispatch } from 'react-redux';
import { fetchTicketsRaised } from 'project-name/Redux/features/adminTicketSlice';
import { RootState  , AppDispatch } from 'project-name/Redux/Store';

interface TableProps{
  variants: string[];
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  background: '#F5F5F5',
};

// interface Ticket {
//   ticketID: string;
//   subject: string;
//   time: string;
//   assignedRecruiter: string;
// }

const TicketListItem: React.FC<{ ticket: Ticket; onClick: () => void }> = ({ ticket, onClick }) => (
  <div  key={ticket.id} className={styles.savedProfilesList} onClick={onClick}>
    <p>{ticket.user_id}</p>
    <p>{ticket.ticketType}</p>
    <p>{ticket.dateRaised}</p>
    <div className={styles.recruiterDiv}>
      <img src={imgUser} alt="" width={40} height={40} className={styles.imgUser} />
      <p>{ticket.dateRaised}</p>
    </div>
  </div>
);

 export const TicketModal: React.FC<{ ticket: Ticket | null; open: boolean; onClose: () => void }> = ({ ticket, open, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const [reply, setReply] = useState('');
  useEffect(() => {
    dispatch(fetchTicketsRaised());
  }, [dispatch]);
 
  const handleReplyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReply(event.target.value);
  };

  const handleAddReply = async () => {
    if (ticket) {
      const payload = {
        id: ticket.id,
        admin_reply: reply,
      };

      try {
       const response =  await apiClient.post('/api/admin/ticket_reply',payload,{
        withCredentials: true,
    });
      if(response.status === 200){
        toast.success('Reply added successfully!');
        console.log("reply added successfully");
        onClose();
      }
      } catch (error) {
        toast.error('Error adding reply. Please try again.');
        
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title"  aria-describedby="modal-modal-description" className={styles.mainModal}>
      <Box sx={style}>
        {ticket && (
          <>
            <div className={styles.modalDiv}>
              <div>
                <p className={styles.ticketID}>Ticket ID : {ticket.id}</p>
              </div>
              <div className={styles.cancelDiv} onClick={onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path d="M11.2891 20.7132L20.7171 11.2852" stroke="black" strokeWidth="2" strokeLinecap="round" />
                  <path d="M11.2891 11.2852L20.7171 20.7132" stroke="black" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </div>
            </div>
            <div>
              <p className={styles.mainHead}>Details</p>
              <div className={styles.ticketProfile}>
                <div className={styles.imgDiv}>
                  <div>
                    <img src={imgUser} alt="" width={40} height={40} className={styles.imgUser} />
                  </div>
                  <div>
                    <p className={styles.head}>Raised by</p>
                    <p className={styles.para}>Mathew</p>
                  </div>
                </div>
                <div>
                  <p className={styles.head}>Employee ID</p>
                  <p className={styles.para}>488530097</p>
                </div>
                <div>
                  <p className={styles.head}>Time</p>
                  <p className={styles.para}>
  {new Date(ticket.dateRaised).toLocaleDateString()}
</p>
                </div>
                <div>
                  <p className={styles.head}>Status</p>
                  <p className={styles.para}>{ticket.supportStatus}</p>
                </div>
              </div>
            </div>
            <div className={styles.mainDiv}>
              <div className={styles.desc}>
                <p className={styles.mainHead}>Description</p>
                <div className={ticket.subject}>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since then.</p>
                </div>
              </div>
              <div className={styles.attach}>
                <p className={styles.mainHead}>Attachment</p>
                <div className={styles.attachDiv}>
                  <div className={styles.iconDiv}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.0951 1.77083C10.0951 1.67303 10.0158 1.59375 9.91797 1.59375H4.95964C3.88383 1.59375 3.01172 2.46586 3.01172 3.54167V13.4583C3.01172 14.5342 3.88383 15.4062 4.95964 15.4062H12.043C13.1188 15.4062 13.9909 14.5342 13.9909 13.4583V6.47917C13.9909 6.38137 13.9116 6.30208 13.8138 6.30208H10.6263C10.3329 6.30208 10.0951 6.06423 10.0951 5.77083V1.77083ZM10.6263 8.67708C10.9197 8.67708 11.1576 8.91494 11.1576 9.20833C11.1576 9.50172 10.9197 9.73958 10.6263 9.73958H6.3763C6.0829 9.73958 5.84505 9.50172 5.84505 9.20833C5.84505 8.91494 6.0829 8.67708 6.3763 8.67708H10.6263ZM10.6263 11.5104C10.9197 11.5104 11.1576 11.7483 11.1576 12.0417C11.1576 12.3351 10.9197 12.5729 10.6263 12.5729H6.3763C6.0829 12.5729 5.84505 12.3351 5.84505 12.0417C5.84505 11.7483 6.0829 11.5104 6.3763 11.5104H10.6263Z" fill="#CB1313"/>
                      <path d="M11.1562 2.00023C11.1562 1.86954 11.2927 1.78656 11.3944 1.86865C11.48 1.93779 11.557 2.0188 11.6227 2.11031L13.757 5.08382C13.8056 5.15152 13.753 5.23938 13.6696 5.23938H11.3333C11.2355 5.23938 11.1562 5.16009 11.1562 5.06229V2.00023Z" fill="#CB1313"/>
                    </svg>
                    <p className={styles.iconPara}>10thmarksh...</p>
                  </div>
                  <div>
                    <img src={imgUser} width={100} height={62} alt="" style={{ borderRadius: '6px' }} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className={styles.mainHead}>Post reply</p>
              <textarea name="reply"
                    id="reply"
                    rows={5}
                    className={styles.postReply}
                    placeholder="type here ..."
                    value={reply}
                    onChange={handleReplyChange}
                    ></textarea>
            </div>
            <div className="globalBtn">
              <Button variant="outlined" onClick={onClose}>Close ticket</Button>
              <Button type="submit" variant="contained" color="primary" onClick={handleAddReply}>Add reply</Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
}

const TicketTable: React.FC<TableProps> = ({
  variants,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const itemsPerPage: number = 5;
  const ticketData = useSelector((state:RootState)=>state.tickets.tickets);
  let tickets: Ticket[] ;
  if(variants.length === 0){
   tickets= ticketData;
  }
  else{
    
    tickets = ticketData.filter((ticket)=>variants.includes(ticket.supportStatus));
  }

  const totalPages: number = Math.ceil(tickets.length / itemsPerPage);

  const getCurrentPageTickets = (): Ticket[] => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return tickets.slice(startIndex, startIndex + itemsPerPage);
  };

  const handlePageChange = (page: number): void => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    // Check if window object is defined (i.e., we are in a browser environment)
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage]);

  const handleOpen = (ticket: Ticket) => {
    
    setSelectedTicket(ticket);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTicket(null);
  };

  return (
    <div>
      <section>
        <div className={styles.savedProfilesContainer} style={{ padding: '0px 40px 20px 40px' }}>
          <div className={styles.savedProfilesListContainer}>
            <div className={styles.savedProfilesListTitle}>
              <p>Ticket ID</p>
              <p>Subject</p>
              <p>Last updated</p>
              <p>Assigned recruiter</p>
            </div>
            {getCurrentPageTickets().map((ticket) => (
              <TicketListItem key={ticket.id} ticket={ticket} onClick={() => handleOpen(ticket)} />
            ))}
          </div>
        </div>
        <div className={styles.ListPaginationBox}>
          <div className={styles.pagination} id="pagination">
            <a href="#" onClick={() => handlePageChange(currentPage - 1)}>&lt;</a>
            {Array.from(Array(totalPages).keys()).map((pageNumber) => (
              <a
                key={pageNumber} // Ensure this key is unique
                href="#"
                className={currentPage === pageNumber + 1 ? styles.active : ''}
                onClick={() => handlePageChange(pageNumber + 1)}
              >
                {pageNumber + 1}
              </a>
            ))}
            <a href="#" onClick={() => handlePageChange(currentPage + 1)}>&gt;</a>
          </div>
        </div>
        {selectedTicket && <TicketModal ticket={selectedTicket} open={open} onClose={handleClose} />}
      </section>
    </div>
  );
};

export default TicketTable;

import {useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { MdEmail, MdVisibility, MdVisibilityOff, MdLock } from 'react-icons/md';
import styles from 'project-name/components/AdminSection/LoginSection/AdminLogin.module.css';
import logo from 'project-name/assets/img/admin/logo/logo.png';
import { useDispatch } from 'react-redux';
import {  toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState , AppDispatch } from 'project-name/Redux/Store';
import { changePassword } from 'project-name/Redux/features/forgetPasswordSlice';

const ForgetPasswordPage = () => {
    const { status} = useSelector((state: RootState) => state.forgetPassword);
    const dispatch: AppDispatch = useDispatch();
    const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const userId = localStorage.getItem( "user_id");
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
console.log(currentPassword , newPassword)
   
  dispatch(changePassword({ old_password: currentPassword, new_password: newPassword, id: userId}));
    
    if (status === 'succeeded') {
        toast.success('Password changed successfully!');
      } else if (status === 'failed') {
        toast.error('Failed to change password.');
      }
  };


  return (
    <div>
        <div className={styles.mainDiv}>
      <div className={styles.logoDiv}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.maincontainer}>
        <div className={styles.subContainer}>
          <div>
            <Typography variant="h4" className={styles.head}>
              Forgot Password?
            </Typography>
            <Typography className={styles.para}>
              Please reset your password
            </Typography>
          </div>
          <div className={styles.formInput1}>
            <form onSubmit={handleSubmit}> 
              <div className={styles.formGroup}>
                <label htmlFor="password">Current Password</label>
                <TextField
                  type="password"
                  id="current-password"
                  name="current-password"
                 
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className={styles.formControl}
                  placeholder="Enter your current password"
                  
                
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="new-password"> New Password</label>
                <TextField
                  type='password'
                  id="new-password"
                  name="new-password"
                  value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  
                  className={styles.formControl}
                  placeholder="Enter new password"
                
                 
                />
              </div>

            

              <div className={styles.btn}>
                <Button type="submit" variant="contained" data-testid="signin-button">
                  Submit
                </Button>
              </div>
            </form>
          </div>
          <div className={styles.helpsec}>
            <MdEmail className={styles.emaiIcon} />
            <Typography variant="body2">
              <Link to="/need-help" className={styles.help}>
                Need help?
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ForgetPasswordPage

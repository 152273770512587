import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "project-name/api/apiClient";
import axios 
from "axios";
interface AdminProfileState {
    profile:any
    status:'idle'|'loading'|'succeeded'|'failed'
    error:string|null
}
const initialState:AdminProfileState={
  profile: [],
      status:'idle',
error:null
}
export const fetchAdminProfile = createAsyncThunk(
  'adminProfile/fetchAdminProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get('/api/admin/candiadate_profiles', {
        withCredentials: true, // Include credentials such as cookies
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || 'Failed to fetch admin profile'
      );
    }
  }
);
  
  
  const adminProfileSlice = createSlice({
    name: 'adminProfile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAdminProfile.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(fetchAdminProfile.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.profile = action.payload;
        })
        .addCase(fetchAdminProfile.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload as string;
        })
       
    },
  });
  
  export default adminProfileSlice.reducer;
import React, { useState, useEffect } from 'react';
import styles from './OnboardingSlider.module.css';
import { Button } from '@mui/material';
import profileImg from 'project-name/assets/img/admin/profile/guy-plaid-shirt.jpg';
import GeneralSettings from './GeneralSettings';
import CandidateAccess from './CandidateAccess';
import OnboardingStatus from './OnboardingStatus';
import Activities from './Activities';
import Forms from './Forms';
import Actions from './Actions';

const CandidateSlider: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>('GeneralSettings');

  useEffect(() => {
    if (window.innerWidth > 960) {
      setActiveSection('GeneralSettings');
    }
  }, []);

  const renderSection = () => {
    switch (activeSection) {
      case 'GeneralSettings':
        return <GeneralSettings />;
      case 'CandidateAccess':
        return <CandidateAccess />;
      case 'OnboardingStatus':
        return <OnboardingStatus />;
      case 'Activities':
        return <Activities />;
      case 'Forms':
        return <Forms />;
      case 'Actions':
        return <Actions />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.mDiv}>
      <div className={styles.sDiv}>
        <h4>Recruiter profile</h4>
        <div>
          <Button
            color="primary"
            variant="contained"
            sx={{ padding: '10px 30px', textTransform: 'capitalize', fontWeight: '600' }}
          >
            Stop onboarding
          </Button>
        </div>
      </div>

      <div className={styles.profileDiv}>
        <div>
          <img src={profileImg} alt="Recruiter" />
        </div>
        <div>
          <p className={styles.head1}>Recruiter name</p>
          <p className={styles.head2}>Neeraj Singh</p>
        </div>
        <div>
          <p className={styles.head1}>Email ID</p>
          <p className={styles.head2}>neerajsingh223@gmail.com</p>
        </div>
        <div>
          <p className={styles.head1}>Candidates</p>
          <p className={styles.head2}>5 profiles</p>
        </div>
        <div>
          <p className={styles.head1}>Recruiter role</p>
          <p className={styles.head2}>
            <span style={{ color: '#237813' }}>Onboarding agent</span>,{' '}
            <span style={{ color: '#7E5413' }}>Onboarding admin</span>
          </p>
        </div>
      </div>

      <div className={styles.onboardDiv}>
        {['GeneralSettings', 'CandidateAccess', 'OnboardingStatus', 'Activities', 'Forms', 'Actions'].map((section) => (
          <div key={section}>
            <span
              onClick={() => setActiveSection(section)}
              className={activeSection === section ? styles.activespanButton : styles.spanButton}
            >
              {section.replace(/([A-Z])/g, ' $1').trim()}
            </span>
          </div>
        ))}
      </div>

      {renderSection()}
    </div>
  );
};

export default CandidateSlider;

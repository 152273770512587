import React, { useState } from 'react';
import styles from "../TableSection/phoneScreenTabs.module.css"

interface TabProps {
  label: string;
  children: React.ReactNode;
}

interface TabsProps {
  children: React.ReactElement<TabProps>[];
}

const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div className={styles.tabHeaders}>
        {children.map((tab, index) => (
          <button
            key={index}
            className={activeTab === index ? styles.active : ''}
            onClick={() => handleTabClick(index)}
          >
            {tab.props.label}
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>{children[activeTab]}</div>
    </div>
  );
};

const Tab: React.FC<TabProps> = ({ children }) => {
  return <div></div>;
};

export { Tabs, Tab };

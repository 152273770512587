// FileInput.tsx
import React from 'react';
import styles from '../Documents.module.css'; // Import your CSS module

interface FileInputProps {
  label: string;
  file: File | null | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  fileCss1?: keyof typeof styles; 
  fileCss2?: keyof typeof styles; 
  fileCss3?: keyof typeof styles; 
  fileCss4?: keyof typeof styles; 
  readOnly?: boolean;  
}

const FileInput: React.FC<FileInputProps> = ({ label, file, onChange, id, fileCss1, fileCss2, fileCss3, fileCss4 ,readOnly }) => {
  return (
    <div className={fileCss1 ? styles[fileCss1] : styles.fileCss1}>
      <p className={fileCss2 ? styles[fileCss2] : styles.fileCss2}>
        {label} <span style={{color:'red'}}>*</span>

      </p>
      {(label === 'Adhaar card photo' || label === 'Pan card photo') && <p  style={{fontSize:"12px" ,fontWeight:"600"}}>(If you have multiple documents , kindly combine them and make a pdf)</p>}
      <div className={fileCss3 ? styles[fileCss3] : styles.fileCss3}>
        <div className={fileCss4 ? styles[fileCss4] : styles.fileCss3} onClick={() => document.getElementById(id)?.click()}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <g opacity="0.4">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 16C3 11.0294 7.02944 7 12 7H22.6667C26.1645 7 29 9.83553 29 13.3333C29 16.8312 26.1645 19.6667 22.6667 19.6667H11.8095C9.78448 19.6667 8.14285 18.0251 8.14285 16C8.14285 13.9749 9.78448 12.3333 11.8095 12.3333H22.2857C22.838 12.3333 23.2857 12.7811 23.2857 13.3333C23.2857 13.8856 22.838 14.3333 22.2857 14.3333H11.8095C10.8891 14.3333 10.1429 15.0795 10.1429 16C10.1429 16.9205 10.8891 17.6667 11.8095 17.6667H22.6667C25.0599 17.6667 27 15.7265 27 13.3333C27 10.9401 25.0599 9 22.6667 9H12C8.134 9 5 12.134 5 16C5 19.866 8.13401 23 12 23H22.2857C22.838 23 23.2857 23.4477 23.2857 24C23.2857 24.5523 22.838 25 22.2857 25H12C7.02944 25 3 20.9705 3 16Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
          {file && <p>{file.name}</p>}
          <p>Click to upload</p>
          <input type="file" id={id} style={{ display: 'none' }} onChange={onChange} disabled={readOnly} />
        </div>
      </div>
    </div>
  );
};

export default FileInput;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "./apiClient";

const useSetupInterceptors = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          alert('Your Session has expired. Please log in again.');
          // Redirect to login
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to remove the interceptor
    return () => {
      apiClient.interceptors.response.eject(interceptor);
    };
  }, [navigate]);
};

export default useSetupInterceptors;
import RecruiterSection from 'project-name/components/AdminSection/Global/RecruiterSection/RecruiterSection'
import React from 'react'

const recruitersLayout:React.FC = () => {
  return (
    <div>
        <RecruiterSection/>
      
    </div>
  )
}

export default recruitersLayout

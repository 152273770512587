import React from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import styles from './personalDetails.module.css';

interface TextInputProps {
  label: string;
  defaultValue: string;
  onChange?: (e:any) => void;
}

const TextInput: React.FC<TextInputProps> = ({ label, defaultValue,onChange }) => {
  return (
    <div className={styles.textField}>
      <TextField
        fullWidth
        label={label}
        defaultValue={defaultValue}
        onChange={onChange}
        margin="normal"
        variant="outlined"
        className={styles.input}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <BorderColorIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default TextInput;

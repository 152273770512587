import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiClient from 'project-name/api/apiClient';

interface ProfileState {
  data: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ProfileState = {
  data: null,
  status: 'idle',
  error: null,
};

// Async thunk for fetching profile details
export const fetchProfileDetails = createAsyncThunk(
    'profile/fetchProfileDetails',
    async (_, { rejectWithValue }) => {
      try {
        const response = await apiClient.get('/api/admin/profile_details', {
          withCredentials: true, 
        });


        console.log('response.data',response.data);
        return response.data;
      } catch (err: any) {
        
        return rejectWithValue(err.response?.data || 'Something went wrong');
      }
    }
  );

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export default profileSlice.reducer;

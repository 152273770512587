import { createSlice  , createAsyncThunk} from '@reduxjs/toolkit';
import apiClient from 'project-name/api/apiClient';



interface CandidateState {
    data: any;
    loading: boolean;
    error: string | null;
  }
  
  const initialState: CandidateState = {
    data: null,
    loading: false,
    error: null,
  };
  
  export const submitCandidateData = createAsyncThunk(
    'candidate/submitCandidateData',
    async (formData: FormData, { rejectWithValue }) => {
      try {
        const response = await apiClient.post('/api/admin/create-candidate', formData, {withCredentials: true});
        return response.data;
      } catch (err: any) {
        return rejectWithValue(err.response?.data || 'Something went wrong');
      }
    }
  );


  const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(submitCandidateData.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(submitCandidateData.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(submitCandidateData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
    },
  });
  
  export default candidateSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'project-name/api/apiClient';

interface CandidateDetails {
  id: number;
  
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  designation: string;
  employee_band: string;
  office_location: string;
  local_entity: string;
  function: string;
  tower: string;
  hiring_manager: string;
  hiring_manager_email: string;
  pbp_name: string;
  pbp_email: string;
  position_id: number;
  gender: string;
  d_o_j: string;
  offer_accept_status: string;
  bgv_initiated: string;
  bgv_status: string;
  bgv_case_id: number;
  bgv_report_url: string;
  bgv_start_time: string;
  is_documents_moved: string;
  laptop_requested: string;
  doj_status: string;
  parking_space_required: string;
  is_acknowledged: string;
  user_id: number;
  
  
 
  cost_center: string | null;
  status_name: string;
}

interface UpdateCandidateState {
  candidate: CandidateDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: UpdateCandidateState = {
  candidate: null,
  loading: false,
  error: null,
};

// Create AsyncThunk for updating candidate details
export const updateCandidateDetails = createAsyncThunk(
  'candidate/updateCandidateDetails',
  async (candidateData: CandidateDetails, { rejectWithValue }) => {
    try {
      const response = await apiClient.post('/api/admin/update-candidate-details', candidateData , {withCredentials:true,});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const candidateSlice = createSlice({
  name: 'updateCandidate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCandidateDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCandidateDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.candidate = action.payload;
      })
      .addCase(updateCandidateDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default candidateSlice.reducer;

import React from 'react';
import styles from './SubmitPopup.module.css';
import checkIcon from 'project-name/assets/img/check.png';
import Box from '@mui/material/Box';

interface SubmitPopupProps {
  handleClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #FFF',
  p: 2,
  borderRadius: '8px',
  background: 'rgba(255, 255, 255, 0.95)',
  boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
};

const SubmitPopup: React.FC<SubmitPopupProps> = ({ handleClose }) => {
  return (
    <Box sx={style}>
      <div className={styles.closeIcon} onClick={handleClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
          <path d="M9.52344 17.4784L17.4783 9.52344" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
          <path d="M9.52344 9.52344L17.4783 17.4783" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
      </div>
      <div className={styles.submitDiv}>
        <img src={checkIcon} alt="" />
        <h3>Submitted</h3>
        <p>Your document has been successfully submitted. Please submit the remaining documents to finish your onboarding.</p>
      </div>
    </Box>
  );
};

export default SubmitPopup;

import { Box, Typography } from '@mui/material';

import userImg from '../../../../assets/img/muser.png';



// const style = {
//   display: 'flex',
//   gap: 30,
//   marginTop: 10,
//   margin:'0px 100px',
//   padding: 30,
//   borderRadius: '9px',
//   border: '1px solid #FFF',
//   boxShadow: '4px 0px 25px 0px rgba(0, 0, 0, 0.06)',
//   bgColor: 'linear-gradient(270deg, rgba(255, 255, 255, 0.50) -173.25%, rgba(255, 255, 255, 0.00) 100%) !important',
//   backdropFilter: 'blur(5px)',


// };


const ProfileDesc = () => {
  

  return (            
    
    <Box  sx={{
      display: { xs: 'none', sm: 'flex' },
      gap: 3,
      mt: 1,
      mx: {sm:'50px' , md:'100px'},
      p: 3,
      borderRadius: '9px',
      border: '1px solid #FFF',
      boxShadow: '4px 0px 25px 0px rgba(0, 0, 0, 0.06)',
      background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.50) -173.25%, rgba(255, 255, 255, 0.00) 100%) !important',
      backdropFilter: 'blur(5px)',
    }} >
      <Box>
        <img src={userImg} alt="User" style={{ height: 200, width: 200 ,  }}  />
      </Box>
      <Box>
        {/* <Typography variant="h5" sx={{ marginTop: '30px', fontWeight: 'bold',fontSize:{sm:'22px' ,md:'25px'} } }>
          A word from our CEO, Michel Doukeris
        </Typography>
        <Typography variant="body1" sx={{ mt: '10px', fontSize: { sm: '12px', md: '15px' } }} >
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '10px', fontWeight: '400', fontSize:{sm:'18px' , md:'20px'} }}>
          To a future with more cheers!
        </Typography> */}
      </Box>
    </Box>
  );
};

export default ProfileDesc;

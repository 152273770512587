import React, { useState } from 'react';
import mainstyles from '../../../css/layout.module.css';
import VerticalNavbar from '../Global/Navbar/VerticalNavbar';
import styles from './ContactSection.module.css';
import { Button } from '@mui/material';
import Breadcrumb from '../Documents/Breadcrumb';
import LabeledTextarea from './LabeledTextarea';
import PhoneMenu from '../Global/Navbar/phoneMenu';
import FormField from '../Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormField';
import FileInput from '../Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FileInput';
import apiClient from 'project-name/api/apiClient';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import QueryPopup from '../Global/Popup/Query Popup';
interface attachmentFile {
  attachmentFile: File | null;
}
const ContactSection: React.FC = () => {
  const [attachmentFile, setattachmentFile] = useState<attachmentFile>({
    attachmentFile: null,
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleClose = () => {
    setIsPopupOpen(false);
  };

  let candidateDetails = useSelector((state: RootState) => state.candidate.candidate);
  let personal_details = useSelector((state: RootState) => state.candidate.personalDetails);

  if (!candidateDetails || !personal_details) {
    candidateDetails = JSON.parse(localStorage.getItem("candidateData") || "");
    personal_details = JSON.parse(localStorage.getItem("personalDetails") || "");
  }

  const [TopicformData, setFormData] = useState({
    topic: '',
    name: candidateDetails?.full_name,
    email: candidateDetails?.email,
    contact: personal_details?.phone_no,
    description: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleattachmentFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'passportImageInput':
          setattachmentFile((prevData) => ({
            ...prevData,
            attachmentFile: files[0] || null, // Set to null if no file selected
          }));
          break;
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('topic', TopicformData.topic);
    formData.append('name', TopicformData.name || '');
    formData.append('personal_email', TopicformData.email || '');
    formData.append('contact_number', TopicformData.contact);
    formData.append('description', TopicformData.description);
    formData.append('document', attachmentFile.attachmentFile || '');
    const response = await apiClient.post(
      `/api/candidate/contact_us`,
      formData,
      {
        withCredentials: true,
      }
    );

    setIsPopupOpen(true);
    if (response.status !== 200) {
      throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
    }
  };

  const handleClear = () => {
    setFormData({
      topic: '',
      name: '',
      email: '',
      contact: '',
      description: '',
    });
  };



  return (
    <div className={mainstyles.mDiv}>
      <div>
        <VerticalNavbar />
      </div>

      <div>
        <div style={{ margin: '40px 100px' }}>
          <Breadcrumb bread1="Home" bread2="Contact us" link1="/" />
        </div>

        <form onSubmit={handleSubmit}>
          <div className={styles.formDiv}>
            <div className={styles.msgdiv}>
              <h2>Get in touch with us</h2>
              <p>Describe your problem so that our team can help you out better.</p>
            </div>

            <div className={styles.pdi}>
              <FormField label="Topic" name="topic" type="" value={TopicformData.topic} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter topic'
                cssInput2="label" />
              <FormField label="Your name" name="name" type="" value={TopicformData.name} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter your name'
                cssInput2="label" />
            </div>

            <div className={styles.pdi}>
              <FormField label="Personal Email" name="email" type="" value={TopicformData.email} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter your email'
                cssInput2="label" />
              <FormField label="Contact number" name="contact" type="" value={TopicformData.contact} onChange={handleChange} cssInput1="subDiv2" placeholder='Enter your contact number'
                cssInput2="label" />
            </div>
            <div className={styles.pdi}>
              <div >
                <FormField label="Description" type="" name="description" value={TopicformData.description} onChange={handleChange} isTextarea cssInput1="subDiv2 customDescriptionField"
                  cssInput2="label" rows={3} cols={12} placeholder='Description' />
              </div>

              <div className={styles.attach}>
                <FileInput
                  label="Upload Attachments if any"
                  file={attachmentFile.attachmentFile}
                  onChange={handleattachmentFileChange}
                  id="passportImageInput"
                  fileCss1="docDiv1"
                  fileCss2="label"
                  fileCss3="fileDiv"
                  fileCss4="fileDiv1"

                />
              </div>

            </div>
          </div>

          <div className={styles.btnDiv}>
            <div></div>
            <div className={styles.subbtnDiv}>
              <div className={styles.btn}>
                <Button type="submit" variant="contained" color="primary">
                  Send query
                </Button>
              </div>

              <div className={styles.btn}>
                <Button variant="outlined" onClick={handleClear}>
                  Clear field
                </Button>
              </div>
            </div>

            {isPopupOpen && (
              <QueryPopup handleClose={handleClose} />
            )}
          </div>
        </form>
      </div>
      <PhoneMenu />
    </div>
  );
};

export default ContactSection;
// candidateSlice.ts
import { createSlice } from '@reduxjs/toolkit';

export interface Status {
    education_type: string;
  id:string
}

interface StatusState {
   EducationList:Status[]
}

const initialState: StatusState = {
    EducationList:[]
    
};

const EducationStatusSlice = createSlice({
    name: 'StatusList',
    initialState,
    reducers: {
        setEducationDetails: (state, action:any) => {
        // update document List
        state.EducationList = action.payload;
        },
    },
});

export const { setEducationDetails } = EducationStatusSlice.actions;
export default EducationStatusSlice.reducer;
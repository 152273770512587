// FormSelect.tsx
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import styles from '../Documents.module.css';

interface FormSelectProps {
  label?: string;
  inputlabel?: string;
  value?: string; // Ensure this is properly typed
  error?: string | undefined;
  name?: string;
  handleChange?: (event: SelectChangeEvent<string>) => void;
  options: { value: string; label: string }[];
  selectCss1?: keyof typeof styles;
  selectCss2?: keyof typeof styles;
  selectCss3?: keyof typeof styles;
  id?: string;
  readOnly?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  inputlabel,
  value,
  name,
  error,
  handleChange,
  options,
  selectCss1,
  selectCss2,
  selectCss3,
  id,
  readOnly,
}) => (
  <div className={selectCss1 ? styles[selectCss1] : styles.selectCss1} data-testid={id} >
    <p className={selectCss2 ? styles[selectCss2] : styles.selectCss2}>
      {label} <span style={{ color: 'red' }}>*</span>
    </p>
    <div className={selectCss3 ? styles[selectCss3] : styles.selectCss3}>
      <FormControl fullWidth>
        {/* <InputLabel id={`${name}-label`}>{inputlabel}</InputLabel> */}
        <Select
          labelId={`${name}-label`}
          id={`${name}-select`}
          value={value || ''} // Ensure value is properly handled
          onChange={handleChange}
          name={name}
          sx={{ background: '#fff' }}
          data-testid={`${name}-select`} // Ensure correct data-testid
          aria-labelledby={name}
          disabled={readOnly}
        >
          
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value || value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error && <div className="error">{error}</div>}
      </FormControl>
    </div>
  </div>
);

export default FormSelect;

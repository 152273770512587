import React, {useState} from 'react';
import styles from './AdminTable.module.css';
import { Link } from 'react-router-dom';

import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  SelectChangeEvent,
  
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import { requiredDocTypes, RequiredEducationTypes } from 'project-name/util/util';
import ConfirmationDialog from 'project-name/components/ConfirmationDialog';

interface Document {
  id: number;
  name: string;
  category: string;
  status: string;
  action: string;
}

const AdminTable: React.FC = () => {
  const [open, setOpen] = useState(false); // State to control the dialog open/close
  const [selectedOption, setSelectedOption] = useState<string>('');
  const candidateData = useSelector((state: RootState) => state.candidateDocuments.candidateDetails);
  const documents = useSelector((state: RootState) => state.candidateDocuments.documents);
 const hasAllRequiredDocs = requiredDocTypes.every(docType =>
  documents.some((doc: { doc_type_name: string; }) => doc.doc_type_name === docType)
);

const other_status = hasAllRequiredDocs ? "Completed" : "Incomplete";
  const status=[];
  const personalDetails = candidateData?.personal_details;
  const bankDetails = candidateData?.bank_details;
  const addressDetails = candidateData?.address_details;
  const workExperience = candidateData?.work_experience_details;
  const education = candidateData?.education_details ?? [];
console.log('education',education)
  const hasAllEducationTypes = RequiredEducationTypes.every(educationType =>
    education.some((doc: { education_type: string; }) => doc.education_type === educationType)
  );
  
  status.push({
    id:1,
    name: 'Personal Details',
    status: personalDetails.length>0 ? 'Complete' : 'Incomplete',
    category: 'Task',
  },{  
    id:2,
    name: 'Bank Details',
    status: bankDetails.length>0 ? 'Complete' : 'Incomplete',
    category: 'Task',
  },{
    id:3,
    name: 'Address Details',
    status: addressDetails.length>0 ? 'Complete' : 'Incomplete',
    category: 'Task',
  },{
    id:4,
    name: 'Work Experience',
    status: workExperience.length>0 ? 'Complete' : 'Incomplete',
    category: 'Task',
  },{
    id:5,
    name: 'Education',
    status: hasAllEducationTypes ? 'Complete' : 'Incomplete',
    category: 'Task',
  },{
    id:6,
    name:'Other Documents',
  status: other_status ,
    category: 'Task',
  });
  const handleOpen = () => {
    setOpen(true);
  };

  
  const handleClose = () => {
    setOpen(false);
  };
  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string); 
    console.log(event.target.value);
  };
  return (
    <div className={styles['documents-list-container']} data-testid="admin-table-container">
      <div className={styles['documents-list-title']}>
        <p>Activity name</p>
        <p>Category</p>
        <p>Status</p>
        <p>Action</p>
      </div>

      <div className={styles['documents-list-rows-container']}>
        {status.map((doc) => (
          <div key={doc.id} data-testid={`document-container-${doc.id}`} className={styles['documents-list-rows']}>
            <div className={styles.td1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 28 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.16271 6.38391C5.5031 5.7833 6.26591 5.57234 6.86652 5.91271L12.715 9.22715C13.3921 9.59122 14.1083 9.59122 14.7855 9.22715L20.634 5.91271C21.2345 5.57234 21.9973 5.7833 22.3377 6.38391C22.6781 6.98451 22.4671 7.74734 21.8666 8.08771L16.0072 11.4083L15.9872 11.4194C14.5606 12.1939 12.9398 12.1939 11.5132 11.4194L11.4933 11.4083L5.63391 8.08771C5.0333 7.74734 4.82234 6.98451 5.16271 6.38391Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.7716 3.25C4.05755 3.25 2.5 4.78451 2.5 6.96551V17.0345C2.5 19.2155 4.05755 20.75 5.7716 20.75H21.8484C23.5679 20.75 25.0926 19.2205 24.9957 17.0914C24.9949 17.0724 24.9944 17.0535 24.9944 17.0345V6.96551C24.9944 4.78451 23.4369 3.25 21.7229 3.25H5.7716ZM0 6.96551C0 3.62929 2.46165 0.75 5.7716 0.75H21.7229C25.0327 0.75 27.4944 3.62929 27.4944 6.96551V17.0076C27.6326 20.3827 25.1433 23.25 21.8484 23.25H5.7716C2.46165 23.25 0 20.3707 0 17.0345V6.96551Z"
                  fill="black"
                />
              </svg>
              <p>{doc.name}</p>
            </div>
            <p>{doc.category}</p>
<p className={doc.status === 'Complete' ? styles.complete : styles.incomplete}>
  {doc.status}
</p>

            <p>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 5, textTransform: 'capitalize' }}
                  disabled={doc.status === 'Complete'}
                  onClick={handleOpen}
                >
                  Trigger
                </Button>
            </p>
            <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>Please choose one of the available options below to proceed.</Typography>
          <Select
            displayEmpty
            fullWidth
            variant="outlined"
            margin="dense"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <MenuItem value="" disabled>
              Select any option
            </MenuItem>
            <MenuItem value="notification">Notifications</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTrigger} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
          </div>
          
        ))}
      </div>
    </div>
  );
};

export default AdminTable;

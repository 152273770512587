import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { Variant, MenuProps } from 'project-name/util/util';
import styles from './FilterTab.module.css';

interface SelectWithCheckmarksProps {
  selected: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  variants: Variant[];
  label: string;
  filterHead: string;
}

const FilterTab: React.FC<SelectWithCheckmarksProps> = ({ selected, onChange, variants, label, filterHead }) => (
  <div className={styles.parentCheck}>
    <div className={styles.checkHead}>{filterHead}</div>
    <FormControl sx={{  }}>
      <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={selected}
        className={styles.selectDiv}
        onChange={onChange}
        input={
          <OutlinedInput 
            label={label}
            sx={{
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none', // Remove border
                },
                '&:hover fieldset': {
                  border: 'none', // Remove border on hover
                },
                '&.Mui-focused fieldset': {
                  border: 'none', // Remove border on focus
                },
              }
            }} 
          />
        }
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {variants.map((variant) => (
          <MenuItem key={variant.id} value={variant.name}>
            <Checkbox checked={selected.includes(variant.name)} />
            <ListItemText primary={variant.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default FilterTab;

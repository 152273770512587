/* eslint-disable prettier/prettier */
import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import * as React from 'react';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    commonTitle: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  commonTitle: React.CSSProperties;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#222',
    },
  
  },

  typography: {
    commonTitle: {
      color: '#E1E1E1',
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      textTransform: 'capitalize',
      maxWidth: '30%',
      position: 'relative',
      textAlign: 'center',
      margin: '0 auto',
    },
    h2Bold: {
      // Add your h2Bold styles here if needed
    },
  } as ExtendedTypographyOptions,
});

export default theme;

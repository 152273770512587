import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypePng } from "react-icons/bs";
import styles from './Doctable.module.css';
import { AckIdsToCheck, addIdsToCheck, bankIdsToCheck, docTypeIdsToCheck, getSubmitPath, optionalEducationTypes, otherIdsToCheck, perIdsToCheck, requiredDocTypes, RequiredEducationTypes } from 'project-name/util/util';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { setCandidate, setPdfData, setPersonalDetails } from 'project-name/Redux/features/Candidate';
import apiClient from 'project-name/api/apiClient';
import { setStatusList } from 'project-name/Redux/features/DocumentStatus';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { setOtherCompletedDocuments } from 'project-name/Redux/features/OtherCompletedDocuments';
import { setEducationDetails } from 'project-name/Redux/features/Education';
import CustomizedProgressBars from 'project-name/components/CandidateSecion/TableSection/Doctable2/ProgressBar';

interface DoctableProps {
  thead1: string;
  thead2: string;
  thead3: string;
  thead4: string;
  progressLayout: boolean;
}

const Doctable: React.FC<DoctableProps> = ({ thead1, thead2, thead3, thead4, progressLayout }) => {
  const statusList = useSelector((state: RootState) => state.status.statusList);
  const percentage = useSelector((state: RootState) => state.status.percentage);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  let docTypeIdsWithStatus105: number[] = [];

  const filterDocsByStatus = (
    docs: { doc_type_id: number; status_id: number }[],
    docTypeIdsToCheck: number[]
  ) => {
    return docs
      .filter((doc: { doc_type_id: number; }) => docTypeIdsToCheck.includes(doc.doc_type_id))
      .map((doc: { status_id: number; doc_type_id: number }) => {
        if (doc.status_id === 105) {
          docTypeIdsWithStatus105.push(doc.doc_type_id);
        }
        return doc.status_id !== 105;
      });

  };
  const fetchData = async () => {
    try {
      const response = await apiClient.get(`/api/candidate/candidate-details`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch candidate details: ${response.status}`);
      }
      const status = [];
      const data = response.data;
      if (data) {
        if (data.candidate) {
          let acknowledgement = data.candidate && data.candidate.is_acknowledged == "yes";
          let personal_details_status = data.personal_details && data.personal_details.length !== 0;
          let address_status = data.address && data.address.length !== 0;
          const bank_status = data.bank_details && data.bank_details.length !== 0;
          const hasAllEducationTypes = RequiredEducationTypes.every(educationType =>
            data.education_details.some((doc: { education_type: string; }) => doc.education_type === educationType)
          );

          const canDocs = data.candidate_documents;

          const educationDocStatus = filterDocsByStatus(canDocs, docTypeIdsToCheck);
          const ackDocsStatus = filterDocsByStatus(canDocs, AckIdsToCheck)?.every(status => status === true);
          const perDocsStatus = filterDocsByStatus(canDocs, perIdsToCheck)?.every(status => status === true);

          const addStatus = filterDocsByStatus(canDocs, addIdsToCheck)?.every(status => status === true);
          const bankStatus = filterDocsByStatus(canDocs, bankIdsToCheck)?.every(status => status === true);

          const allDocsValid = educationDocStatus.every(status => status === true);

          let education_status;
          if (hasAllEducationTypes && allDocsValid) {
            education_status = "Completed";
          } else if (!allDocsValid) {
            education_status = "Rejected";
          } else if (!hasAllEducationTypes) {
            education_status = "Pending";
          } else {
            education_status = 0;
          }

          // const education_status = hasAllEducationTypes ? "Completed" : 0;
          const matchingEducationTypes = data.education_details.filter((doc: { education_type: string; }) =>
            RequiredEducationTypes.includes(doc.education_type) || optionalEducationTypes.includes(doc.education_type)
          );


          const work_status = data.work_experience && data.work_experience.length !== 0;
          const hasAllRequiredDocs = requiredDocTypes.every(docType =>
            data.candidate_documents.some((doc: { doc_type_name: string; }) => doc.doc_type_name === docType)
          );

          const other_status = hasAllRequiredDocs ? "Completed" : data.other_documents && data.other_documents.length !== 0;
          const matchingDocuments = data.candidate_documents.filter((doc: { doc_type_name: string; }) =>
            requiredDocTypes.includes(doc.doc_type_name)
          );
          const otherStatus = filterDocsByStatus(canDocs, otherIdsToCheck)?.every(status => status === true);

          localStorage.setItem("matchingEducationTypes", JSON.stringify(matchingEducationTypes));
          localStorage.setItem("docTypeIdsWithStatus105", JSON.stringify(docTypeIdsWithStatus105));

          localStorage.setItem("matchingDocuments", JSON.stringify(matchingDocuments));
          const totalKeys = 7; // Total number of keys to check
          let completedKeys = 0;
          if (acknowledgement) completedKeys++;
          if (personal_details_status) completedKeys++;
          if (address_status) completedKeys++;
          if (bank_status) completedKeys++;
          if (education_status) completedKeys++;
          if (work_status) completedKeys++;
          if (other_status) completedKeys++;
          const percentage = (completedKeys / totalKeys) * 100;
          const createdDate = dayjs(data.candidate.created_at);
          const deadline = createdDate.add(3, 'day').format('YYYY-MM-DD');
          status.push({
            name: 'Acknowledgement form',
            status: !ackDocsStatus ? 'Rejected' : acknowledgement ? 'Completed' : 'Pending',
            type: '.pdf'
          }, {
            name: 'Education documents',
            status: education_status,
            type: '.pdf'
          }, {
            name: 'Personal details',
            status: !perDocsStatus ? 'Rejected' : personal_details_status ? 'Completed' : 'Pending',
            type: '.pdf'

          }, {
            name: 'Address details',
            status: !addStatus ? 'Rejected' : address_status ? 'Completed' : 'Pending',
            type: '.pdf'

          }, {
            name: 'Bank account details',
            status: !bankStatus ? 'Rejected' : bank_status ? 'Completed' : 'Pending',
            type: '.pdf'

          }, {
            name: 'Statutory documents',
            status: !otherStatus ? 'Rejected' : other_status ? 'Completed' : 'Pending',
            type: '.pdf'

          }, {
            name: 'Work experience',
            status: work_status ? 'Completed' : 'Pending',
            type: '.pdf'
          });
          dispatch(setStatusList({ statusList: status, percentage: percentage, deadline: deadline }));
          dispatch(setOtherCompletedDocuments(matchingDocuments))
          dispatch(setEducationDetails(matchingEducationTypes))
        }
        dispatch(setCandidate(data.candidate));
        dispatch(setPersonalDetails(data.personal_details));
        localStorage.setItem("candidateData", JSON.stringify(data.candidate));
        localStorage.setItem("personalDetails", JSON.stringify(data.personal_details));
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      const FailedStatus = [];
      setIsError(true);
      FailedStatus.push({
        name: 'Acknowledgement form',
        status: 'Pending',
        type: '.pdf',
        error: 'Failed to fetch data',
      }, {
        name: 'Education documents',
        status: 'Pending',
        type: '.pdf',
        error: 'Failed to fetch data',
      }, {
        name: 'Personal details',
        status: 'Pending',
        type: '.pdf',
        error: 'Failed to fetch data',
      }, {
        name: 'Address details',
        status: 'Pending',
        type: '.pdf',
        error: 'Failed to fetch data',
      }, {
        name: 'Bank account details',
        status: 'Pending',
        type: '.pdf',
        error: 'Failed to fetch data',
      }, {
        name: 'Statutory documents',
        status: 'Pending',
        type: '.pdf',
        error: 'Failed to fetch data',
      }, {
        name: 'Work experience',
        status: 'Pending',
        type: '.pdf',
        error: 'Failed to fetch data',
      });
      dispatch(setStatusList({ statusList: FailedStatus, percentage: 0, deadline: "" }));
    }

  }
  const fetchFiles = async () => {
    try {
      const response = await apiClient.get(`/api/get_files`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch candidate documents: ${response.status}`);
      }
      const data = response.data;
      // localStorage.setItem("pdfData", JSON.stringify(data));
      if (data) {
        dispatch(setPdfData(data));

      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }

  useEffect(() => {
    fetchData();
    fetchFiles();
  }, []);

  const getStatusIcon = (status: string) => {
    if (status === 'Uploaded' || status === 'Completed') {
      return <CheckCircleIcon style={{ color: '#026547' }} />;
    } else if (status === 'Rejected') {
      return <CancelIcon style={{ color: '#C01212' }} />;
    } else if (status === 'Pending') {
      return <AccessTimeIcon style={{ color: '#745500' }} />;
    }
    return null;
  };
  const getTypeIcon = (type: string) => {
    if (type === '.pdf') {
      return <FaRegFilePdf style={{ fontSize: '22px' }} />;
    } else if (type === '.png') {
      return <BsFiletypePng style={{ fontSize: '22px' }} />;
    }
    return null;
  };


  return (
    <>

      <div className={styles.mdocDiv}>
        <h2>Documentation Overview</h2>
        {/* {!progressLayout && ( */}

        <div className={styles.proDiv}>

          <div className={styles.percentDiv}>
            <h2>{Math.round(percentage)}%</h2>

          </div>
          <div className={styles.percentDiv2}>
            <h3>Documentation Completed</h3>
            <p>Upload the remaining documents to finish your onboarding.</p>
            <CustomizedProgressBars value={percentage} />

          </div>
          <div className={styles.percentDiv3}>
            <p>Deadline: 18/07/2024</p>
            <Button variant="contained" component={Link} to={`/documents`} sx={{
              backgroundImage: "linear-gradient(to bottom right, #f1d513, #d0a23b)", color: "black", width: {
                lg: "230px",
                md: "165px",
                sm: "120px",
              }, padding: {sm:"8px" , md:"5px" , lg:"10px"}, fontSize: { sm: "10px", md: "10px" }, fontWeight: "500"
              
            }}>
              Finish Documentation <ArrowForwardIcon />
            </Button>
          </div>
        </div>

        {/* )}  */}

        <div className={styles.docDiv}>
          <div className={styles.tableDiv}>
            <div className={styles['documents-list-title']}>
              <p>{thead1}</p>
              <p>{thead2}</p>
              <p>{thead3}</p>
              <p>{thead4}</p>
            </div>
            {isError && (
              <div className={styles.overlay}>
                <div className={styles.overlayContent}>
                  <p>Server is currently unavailable</p>
                </div>
              </div>
            )}
            {!isError && statusList.length !== 0 && (
              <div className={styles['documents-list-rows-container']}>
                {statusList.map((doc) => (
                  <div className={styles['documents-list-rows']}>
                    <p>{doc.name}</p>
                    <p style={{ display: "flex", alignItems: "center", gap: "5px" }}> {getStatusIcon(doc.status)}{doc.status}</p>
                    <p>
                      {doc.type && getTypeIcon(doc.type)}
                      {doc.type}
                    </p>
                    <p>
                      {doc.status === 'Completed' ? (
                        <Button
                          sx={{
                            fontWeight: 600,
                            me: 'px',
                            fontSize: { sm: "8px", md: '11px', lg: "10px" },
                            color: 'white',
                            py: '8px',
                            pe: '5px',
                            px: '5px',
                            width: "80px",
                            bgcolor: 'black', // Ensures background color is black
                            cursor: 'not-allowed', // Changes cursor to not-allowed
                            '&.Mui-disabled': { // Apply styles when disabled
                              color: 'white',
                              cursor: 'not-allowed',
                              bgcolor: 'black', // Ensures background color remains black when disabled
                            },
                          }}
                          disabled
                        >
                          Completed
                        </Button>

                      ) : (
                        <Button
                          sx={{ fontWeight: 600, me: 'px', fontSize: { sm: "8px", md: '11px', }, color: 'white', bgcolor: 'black', py: '8px', pe: '0', px: '5px', width: "80px" }}
                          //  color="primary"
                          //  variant="contained"
                          //  style={{ marginRight: 10 }}
                          component={Link}
                          to={getSubmitPath(doc.name)}
                        >
                          Submit
                        </Button>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            )}

          </div>
          {/* <div className={styles.dDiv}>
            <div className={styles.dDiv1}>
              <h1>{Math.round(percentage)}%</h1>
              <h3>Documentation completed</h3>
              <p>Upload the remaining documents to finish your onboarding.</p>
              <Button
                variant="contained"
                style={{
                  marginTop: '20px',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingRight: 30,
                  paddingLeft: 30,
                  background: `linear-gradient(180deg, #F1D513 0%, #D0A23B 135.94%)`,
                  color: '#000',
                  fontSize: '15px',
                  fontWeight: '600',
                  width: '100%',
                  borderRadius: '9px',
                  textTransform: 'capitalize'
                }}
                component={Link}
                to={`/documents`}
              >
                Get started
              </Button>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className=''>
    

    </div> */}
    </>
  );
};

export default Doctable;

import React, { useState } from 'react';
import styles from './personalDetails.module.css';
import VerticalNavbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';
import mainstyles from '../../../../css/layout.module.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
import TextInput from './TextInput';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import { Candidate } from 'project-name/Redux/features/Candidate';
import apiClient from 'project-name/api/apiClient';
import QueryPopup from '../../Global/Popup/Query Popup';

const PersonalDetails: React.FC = () => {
  let candidateDetails = useSelector((state: RootState) => state.candidate.candidate);
  let personal_details = useSelector((state: RootState) => state.candidate.personalDetails);

  if(!candidateDetails || !personal_details){
    candidateDetails=  JSON.parse(localStorage.getItem("candidateData") || "");
    personal_details = JSON.parse(localStorage.getItem("personalDetails") || "");
  }
  const [firstName, setFirstName] = useState(candidateDetails?.first_name || '');
  const [lastName, setLastName] = useState(candidateDetails?.last_name || '');
  const [personalEmail, setPersonalEmail] = useState(candidateDetails?.email || '');
  const [contactNumber, setContactNumber] = useState(personal_details?.phone_no || '');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState<File | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null); // Store the photo URL
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = file.type;

        // Check if the file type is PNG or JPEG
        if (fileType === 'image/png' || fileType === 'image/jpeg') {
          setPhoto(file);
          setProfilePhotoUrl(URL.createObjectURL(file)); // Set the image URL
        } else {
          alert('Please upload a PNG or JPEG file.');
        }
      }
    };
    const handleSubmit = async () => {
      try {
        const formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('personal_email', personalEmail);
        formData.append('contact_number', contactNumber);
        formData.append('description', description);
        if (photo) {
          formData.append('photo', photo);
        }
  
        await apiClient.patch(`/api/candidate/edit-candidate-details`, formData,{
          withCredentials: true,
        });
  
        setIsPopupOpen(true);
      } catch (error) {
        console.error('Error updating details:', error);
        alert('Failed to update details.');
      }
    };
    const handleClose = () => {
      setIsPopupOpen(false);
    };
      // Extract initials from full name
  const getInitials = (fullName: string): string => {
    const nameParts = fullName.split(' ');
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
    return initials;
  };
  return (
    <div className={mainstyles.mDiv}>
      <div>
        <VerticalNavbar />
      </div>
      <div>

      <div style={{ margin: '40px 100px' }}>
        <Breadcrumb bread1="Home" bread2="Personal details" link1="/" />
        </div>
        <div className={styles.personaldetailsContainer}>
          <div className={styles.personaldetailsTopbar}>
            <Link to="/#" className={styles.chevIcon}>
              <ChevronLeftIcon />
            </Link>
            <h3>Personal Details</h3>
          </div>
          <div className={styles.personaldetailsHero}>
            <div className={styles.personaldetailsHerobanner}>
              <div className={styles.pdhbRoles}>
                <b>
                  Position:<p>{candidateDetails?.designation}</p>
                </b>
                <b>
                  Team :<p>{candidateDetails?.tower}</p>
                </b>
              </div>
            </div>
            <div className={styles.heropfp}>
              <div className={styles.heropfpBox}>
              <div
                  className={styles.profileImg}
                  style={{
                    backgroundImage: profilePhotoUrl ? `url(${profilePhotoUrl})` : 'none',
                    backgroundColor: !profilePhotoUrl ? '#ccc' : 'transparent', // Background color if no photo
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#000',
                    fontSize: '24px',
                    fontWeight: 'bold',
                  }}
                >
                  <p style={{position:"absolute"}}>
                  {!profilePhotoUrl && getInitials(candidateDetails?.full_name ?? '')}
                  </p>
                  <input
                    type="file"
                    id="photo-upload"
                    onChange={handleFileChange}
                    style={{ opacity: '0', width: '80px', height: '80px' }}
                  />
                </div>
                <h3>{candidateDetails?.full_name}</h3>
              </div>
            </div>
          </div>
          <div className={styles.formDiv}>
          <div className={styles.pdi}>
              <TextInput label="First Name" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} />
              <TextInput label="Last Name" defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
            <div className={styles.pdi}>
              <TextInput label="Personal Email" defaultValue={personalEmail} onChange={(e) => setPersonalEmail(e.target.value)} />
              <TextInput label="Contact number" defaultValue={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
            </div>
            <div className={styles.pdi2}>
              <p className={styles.label}>Description</p>
              <div>
                <div className={styles.textField}>
                <textarea
                    placeholder="enter details"
                    rows={10}
                    className={styles.descInput}
                    value={description}
                    style={{padding:"10px"}}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.btnDiv}>
            <div></div>
            <div className={styles.subbtnDiv}>
              <div className={styles.btn}>
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                  Submit Changes
                </Button>
              </div>
              <div className={styles.btn2}>
                <Button variant="outlined" >Cancel</Button>
              </div>
            </div>
            {isPopupOpen && (
            <QueryPopup handleClose={handleClose} />
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import styles from './FilterButton.module.css';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {  useNavigate } from 'react-router-dom';

interface Tower {
  id: number;
  name: string;
}

const FilterButton: React.FC = () => {
  const navigate = useNavigate()
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [selectedTowers, setSelectedTowers] = useState<number[]>([]);
  const [towers, setTowers] = useState<Tower[]>([
    { id: 1, name: 'Tower 1' },
    { id: 2, name: 'Tower 2' },
    { id: 3, name: 'Tower 3' },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    if (filter !== 'towers') {
      setSelectedTowers([]);
    }
  };

  const handleTowerChange = (towerId: number) => {
    let updatedSelectedTowers: number[];

    if (towerId === 0) {
      if (selectedTowers.length === towers.length) {
        updatedSelectedTowers = [];
      } else {
        updatedSelectedTowers = towers.map(tower => tower.id);
      }
    } else {
      if (selectedTowers.includes(towerId)) {
        updatedSelectedTowers = selectedTowers.filter(id => id !== towerId);
      } else {
        updatedSelectedTowers = [...selectedTowers, towerId];
      }
    }

    setSelectedTowers(updatedSelectedTowers);
  };

  const toggleDropdown = () => {
    if (dropdownOpen) {
      setAppliedFilters([]);
      setDropdownOpen(false);
    } else {
      setDropdownOpen(true);
    }
  };

  const applyFilters = () => {
    let filtersToAdd: string[] = [];
    if (selectedFilter === 'towers') {
      if (selectedTowers.length === towers.length) {
        filtersToAdd.push("All towers");
      } else {
        const selectedTowerNames = selectedTowers.map((towerId) =>
          towers.find((tower) => tower.id === towerId)?.name || ''
        );
        filtersToAdd = [...new Set([...appliedFilters, ...selectedTowerNames])];
      }

      if (!appliedFilters.includes("Towers")) {
        filtersToAdd.push("Towers");
      }
    }
    setAppliedFilters(filtersToAdd);
    setDropdownOpen(false);
  };
  const addCandidate = ()=>{
    navigate('/add-candidate')
  }

  return (
    <>
    
    <div className={styles.btnDiv}>
      
      <div className={styles.dropdown}>
        <Button variant="outlined" className={styles.btn} onClick={toggleDropdown} data-testid="filter-button" sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },
          
        cursor:"pointer"}}>
          + add filter
        </Button>
       
        {appliedFilters.length > 0 && (
          <div className={styles.filterDiv}>
            <p>x</p>
            <div className={styles.appliedFilters}>
              <span>{appliedFilters.join(', ')}</span>
            </div>
          </div>
        )}
        {dropdownOpen && (
          <div className={styles.dropdownContent}>
            <div className={styles.subContent} onClick={() => handleFilterChange('towers')}>
              <p>Towers </p>
              <FaAngleRight />
            </div>
            <div className={styles.subContent} onClick={() => handleFilterChange('functions')}>
              <p>Functions </p>
              <FaAngleRight />
            </div>
            <div className={styles.subContent} onClick={() => handleFilterChange('band')}>
              <p>Band </p>
              <FaAngleRight />
            </div>
            <div className={styles.subContent} onClick={() => handleFilterChange('locations')}>
              <p>Locations </p>
              <FaAngleRight />
            </div>

            {selectedFilter === 'towers' && (
              <div className={styles.subdropDown}>
                <FormGroup sx={{ padding: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedTowers.length === towers.length}
                        onChange={() => handleTowerChange(0)}
                      />
                    }
                    label="All towers"
                  />
                  {towers.map((tower) => (
                    <FormControlLabel
                      key={tower.id}
                      control={<Checkbox checked={selectedTowers.includes(tower.id)} />}
                      label={tower.name}
                      onChange={() => handleTowerChange(tower.id)}
                      data-testid={`tower-checkbox-${tower.id}`}
                    />
                  ))}
                  <Button color="primary" variant="contained" className={styles.btn} onClick={applyFilters} >
                    Apply filter
                  </Button>
                </FormGroup>
              </div>
            )}
          </div>
        )}
      </div>

      <Button variant="outlined" className={styles.btn} onClick={addCandidate} sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },   
        cursor:"pointer"}}>
        + Add candidate
      </Button>
      <Button color="primary" variant="contained" className={styles.btn} sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },
        
          
        cursor:"pointer",}}>
        Bulk action <FaAngleDown style={{ fontSize: '22px' }} />
      </Button>
      <Button variant="outlined" className={styles.btn} sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },
        
        cursor:"pointer",}}>
        All profiles <FaAngleDown style={{ fontSize: '22px' }} />{' '}
      </Button>
    </div>
    </>
  );
};

export default FilterButton;

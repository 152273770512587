import React from "react";

const CompletedIcon = () => {
    
    return (
        <>
         <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
              <circle cx="11.4545" cy="11.4545" r="11.4545" fill="#026547" />
              <path
                d="M6.36328 11.7486L9.62316 14.9727C9.78759 15.1353 10.0553 15.125 10.2067 14.9502L16.5451 7.63672"
                stroke="white"
                strokeWidth="1.3"
                strokeLinecap="round"
              />
            </svg>
        </>
    )

}


export default CompletedIcon;
import React from 'react'
import HelpSection from 'project-name/components/CandidateSecion/HelpSection/HelpSection';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
import Navbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';
import styles from 'project-name/css/layout.module.css';

const DocumentHelp: React.FC = () => {
  return (
    <div className={styles.mDiv}>
    <div>
        <Navbar/>
    </div>
    <div className={styles.secondDiv}>
      <div style={{margin:'30px 100px'}}>    <Breadcrumb bread1="Home" bread2="Documentation" bread3='Documents help' link1='/' link2='/documents' link3='/' threeDiv={true} /></div>
    
        <HelpSection/>
      
    </div>
    </div>
  )
}     

export default DocumentHelp

import React, { useState, FormEvent, useRef, useEffect } from 'react';
import styles from '../Documents.module.css';
import { Button, SelectChangeEvent } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';

import FileInput from '../GlobalFormsField/FileInput';
import FormField from '../GlobalFormsField/FormField';
import FormSelect from '../GlobalFormsField/FormSelect';
import Modal from '@mui/material/Modal';
import apiClient from 'project-name/api/apiClient';
import { cities, countries, union, validatePhoneNumber } from 'project-name/util/util';
import PhoneMenu from 'project-name/components/CandidateSecion/Global/Navbar/phoneMenu';
import AutocompleteInput from '../GlobalFormsField/AutoCompleteInput';
import { useLocation } from 'react-router-dom';

interface WorkExperienceFormProps {
  onSubmit?: (formData: any) => void;
  persona?: string;
  formData?: any;
}
interface FormData {
  organization_name: string;
  specify_organization: string;
  currently_working: string;
  start_date: Dayjs | string;
  end_date: Dayjs | string;
  tenure: string;
  reason_for_leaving: string;
  consent: string;
  is_fresher: string;
  street_address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  company_contact_number: string;
  employee_id: string;
  designation: string;
  supervisor_name: string;
  supervisor_phone_number: string;
  supervisor_email: string;
  hr_name: string;
  hr_phone_number: string;
  hr_email: string;
  gap_details: string;
}

interface FileInputData {
  paySlipFile?: File | null;
  resumeFile?: File | null;
  relLetterFile?: File | null;
}

const WorkExperienceForm: React.FC<WorkExperienceFormProps> = ({persona,formData:form}) => {
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(()=> persona === 'admin' ? false : true);
  const resumeInputRef = useRef<HTMLInputElement | null>(null);
  const paySlipInputRef = useRef<HTMLInputElement | null>(null);
  const relLetterInputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const [user_id, setUserId] = useState<number>(()=> persona === 'admin'? location.state.user_id : 0);
  const [formData, setFormData] = useState<FormData>({
    organization_name: '',
    specify_organization: '',
    currently_working: '',
    start_date: dayjs(),
    end_date: dayjs(),
    tenure: '',
    consent:'',
    reason_for_leaving: '',
    street_address: '',
    city: '',
    state: '',
    is_fresher: '',
    country: '',
    pincode: '',
    company_contact_number: '',
    employee_id: '',
    designation: '',
    supervisor_name: '',
    supervisor_phone_number: '',
    supervisor_email: '',
    hr_name: '',
    hr_phone_number: '',
    hr_email: '',
    gap_details: '',
  });

  // handling form validations and errors

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({
    organization_name: '',
    specify_organization: '',
    currently_working: '',
    start_date: dayjs(),
    end_date: dayjs(),
    tenure: '',
    reason_for_leaving: '',
    street_address: '',
    is_fresher: '',
    city: '',
    state: '',
    consent:'',
    country: '',
    pincode: '',
    company_contact_number: '',
    employee_id: '',
    designation: '',
    supervisor_name: '',
    supervisor_phone_number: '',
    supervisor_email: '',
    hr_name: '',
    hr_phone_number: '',
    hr_email: '',
    gap_details: '',
  });

  const toggleEditMode = (e:any) => {
    setIsEditMode((prevMode) => !prevMode);
    e.preventDefault();
  };
  useEffect(()=>{
    if (persona === 'admin' && form !== undefined) {
      const sanitizedForm = { ...form };
      for (const key in sanitizedForm) {
        if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
          sanitizedForm[key] = '';
        }
      }
      setFormData(sanitizedForm);
    }
  },[persona,form])
  // Example form submission handling
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formValid = validateForm();

    console.log(formValid);

    if (formValid) {
      try {
        
        const formDataToSend = new FormData();
        // Append files to formData

        if(formData.is_fresher === 'yes'){
          if(!fileInputData.resumeFile){
            alert('Please upload resume');
            return;
          }
        }else{
          if (!fileInputData.paySlipFile) {
            alert('Please upload payslip');
            return;
          }
          if (!fileInputData.relLetterFile) {
            alert('Please upload relieving letter');
            return;
          }

        }

        // Append paySlipFile to formData
        if (fileInputData.paySlipFile) {
          formDataToSend.append('pay_slip', fileInputData.paySlipFile);
        }

        if (fileInputData.resumeFile) {
          formDataToSend.append('resume', fileInputData.resumeFile);
        }
        if (fileInputData.relLetterFile) {
          formDataToSend.append('relieving_letter', fileInputData.relLetterFile);
        }

        const formattedStartDate = dayjs(formData.start_date).format('YYYY-MM-DDTHH:mm:ss');
        const formattedEndDate = dayjs(formData.end_date).format('YYYY-MM-DDTHH:mm:ss');
        if(persona === 'admin'){
          formDataToSend.append('data',JSON.stringify({
            organization_name: formData.organization_name,
            specify_organization: formData.specify_organization,
            currently_working: formData.currently_working,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            tenure: formData.tenure,
            reason_for_leaving: formData.reason_for_leaving,
            street_address: formData.street_address,
            consent:formData.consent,
            is_fresher: formData.is_fresher,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            pincode: formData.pincode,
            company_contact_number: formData.company_contact_number,
            employee_id: formData.employee_id,
            designation: formData.designation,
            supervisor_name: formData.supervisor_name,
            supervisor_phone_number: formData.supervisor_phone_number,
            supervisor_email: formData.supervisor_email,
            hr_name: formData.hr_name,
            hr_phone_number: formData.hr_phone_number,
            hr_email: formData.hr_email,
            gap_details: formData.gap_details,
          }))
          const response = await apiClient.post(
            `/api/admin/${user_id}/update-additional-candidate-details`,
            formDataToSend,
            {
              withCredentials: true,
            }
          );

          if (response.status !== 200) {
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          console.log('Form submitted successfully:', data);
          setIsEditMode((prevMode) => !prevMode);
          return;
        }
        formDataToSend.append(
          'details',
          JSON.stringify({
            organization_name: formData.organization_name,
            specify_organization: formData.specify_organization,
            currently_working: formData.currently_working,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            tenure: formData.tenure,
            reason_for_leaving: formData.reason_for_leaving,
            street_address: formData.street_address,
            consent:formData.consent,
            is_fresher: formData.is_fresher,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            pincode: formData.pincode,
            company_contact_number: formData.company_contact_number,
            employee_id: formData.employee_id,
            designation: formData.designation,
            supervisor_name: formData.supervisor_name,
            supervisor_phone_number: formData.supervisor_phone_number,
            supervisor_email: formData.supervisor_email,
            hr_name: formData.hr_name,
            hr_phone_number: formData.hr_phone_number,
            hr_email: formData.hr_email,
            gap_details: formData.gap_details,
          }),
        );

        const response = await apiClient.post(
          `/api/candidate/upload-work-experience-details`,
          formDataToSend,
          {
            withCredentials: true,
          }
        );
      
        if (response.status !== 200) {
          throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
        }
      
        const data = response.data;
        console.log('Form submitted successfully:', data);

        // Simulated fetch request
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Simulated successful submission
        setShowModal(true);

        // Reset form data after successful submission
        setFormData({
          organization_name: '',
          specify_organization: '',
          currently_working: '',
          start_date: dayjs(),
          end_date: dayjs(),
          tenure: '',
          consent:'',
          reason_for_leaving: '',
          street_address: '',
          city: '',
          is_fresher: '',
          state: '',
          country: '',
          pincode: '',
          company_contact_number: '',
          employee_id: '',
          designation: '',
          supervisor_name: '',
          supervisor_phone_number: '',
          supervisor_email: '',
          hr_name: '',
          hr_phone_number: '',
          hr_email: '',
          gap_details: '',
        });

        // reset file input field
        setFileInputData({
          paySlipFile: null,
          resumeFile: null,
          relLetterFile: null,
        });

        // Reset file input fields
        if (resumeInputRef.current) resumeInputRef.current.value = '';
        if (paySlipInputRef.current) paySlipInputRef.current.value = '';
        if (relLetterInputRef.current) relLetterInputRef.current.value = '';
      } catch (error) {
        console.error('Error submitting form:', error);
        if(persona === 'admin'){
          setIsEditMode((prevMode) => !prevMode);
        }
      }
    } else {
      console.log('Form validation failed');
    }
  };

  const validateForm = () => {
    let formValid = true;
    const errors: Partial<FormData> = {};

    const requiredFields: { field: keyof FormData; message: string }[] = [
      { field: 'organization_name', message: 'Organization name is required' },
      {field:'is_fresher',message:'This field is required'},
      { field: 'specify_organization', message: 'Please specify the organization' },
      { field: 'currently_working', message: 'This field is required' },
      { field: 'tenure', message: 'Tenure is required' },
      { field: 'reason_for_leaving', message: 'Reason for leaving is required' },
      { field: 'street_address', message: 'Street address is required' },
      { field: 'city', message: 'City is required' },
      { field: 'state', message: 'State is required' },
      { field: 'country', message: 'Country is required' },
      { field: 'pincode', message: 'Pincode is required' },
      { field: 'company_contact_number', message: 'Company contact number is required' },
      { field: 'employee_id', message: 'Employee ID is required' },
      { field: 'designation', message: 'Designation is required' },
      { field: 'supervisor_name', message: 'Supervisor name is required' },
      { field: 'supervisor_phone_number', message: 'Supervisor phone number is required' },
      { field: 'supervisor_email', message: 'Supervisor email is required' },
      { field: 'hr_name', message: 'HR name is required' },
      {field:'consent',message:'Consent is required'},
      { field: 'hr_phone_number', message: 'HR phone number is required' },
      { field: 'hr_email', message: 'HR email is required' },
      { field: 'gap_details', message: 'Gap details are required' },
    ];
    
    const isFresher = formData.is_fresher === 'yes';
    requiredFields.forEach(({ field, message }) => {
      if (isFresher && field !== 'is_fresher') {
        return;
      }
      const value = formData[field];
      if (typeof value === 'string') {
        if (!value.trim()) {
          errors[field] = message;
          formValid = false;
        }else if (field === 'company_contact_number') {
          const contactNumberError = validatePhoneNumber(value);
          if (contactNumberError) {
            errors[field] = contactNumberError;
            formValid = false;
          }
        }else if(field === 'hr_phone_number'){
          const contactNumberError = validatePhoneNumber(value);
          if (contactNumberError) {
            errors[field] = contactNumberError;
            formValid = false;
          }
        }else if(field === 'supervisor_phone_number'){
          const contactNumberError = validatePhoneNumber(value);
          if (contactNumberError) {
            errors[field] = contactNumberError;
            formValid = false;
          }
        }
      } else if (dayjs.isDayjs(value)) {
        if (!value.isValid()) {
          errors[field] = message;
          formValid = false;
        }
      }
    });

    setFormErrors(errors);
    return formValid;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    newValue?:any
  ) => {
    if(typeof newValue === 'string' && 'target' in event){
      // Cast event.target to the correct type
   const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
   const name = target.getAttribute('id')?.split('-')[0];
     setFormData((prevData) => {
       const newData = {
         ...prevData,
         [name as string]: newValue, // or `newValue.value` depending on your Autocomplete setup
       };
       console.log(newData); // Logging the updated form data
       return newData;
     });
   }
   else if('target' in event){
     const { name, value } = event.target;
     setFormData((prevData) => {
       const newData = {
         ...prevData,
         [name]: value,
       };
       console.log(newData); // Logging the updated form data
       return newData;
     });
   }

  };

  const handleDateChange = (date: Dayjs | null, field: 'start_date' | 'end_date') => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: date || dayjs(), // Use current date as default if date is null
    }));
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'start_date');
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'end_date');
  };

  const [fileInputData, setFileInputData] = useState<FileInputData>({
    paySlipFile: null,
    resumeFile: null,
    relLetterFile: null,
  });

  // Function to handle file input change
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'Payslip':
          setFileInputData((prevData) => ({
            ...prevData,
            paySlipFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'Resume':
          setFileInputData((prevData) => ({
            ...prevData,
            resumeFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'RelLetter':
          setFileInputData((prevData) => ({
            ...prevData,
            relLetterFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        default:
          break;
      }
    }
  };

  // submit popup
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isFresher = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: `No`,
    },
  ];

  return (
    <>
      {/* Modal */}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SubmitPopup handleClose={handleCloseModal} />
        </Modal>
      )}
      <p style={{ padding: '5px 40px' }}>
        All on-campus hires are categorized as freshers irrespective of their previous work experience
      </p>

      <form className={styles.mAttachDiv}>
        <div
          className={styles.fieldDiv}
          
        >
          <FileInput
            label="Upload Resume"
            file={fileInputData.resumeFile}
            onChange={handleFileInputChange}
            id="Resume"
            fileCss1="subDiv2"
            fileCss2="label"
            fileCss3="fileDiv2"
            fileCss4="fileDiv1"
            readOnly={!isEditMode}
          />
        </div>
        <div className={styles.oneEmpty}>
          <FormSelect
            label="Are you a fresher?"
            name="is_fresher"
            inputlabel="Select"
            value={formData.is_fresher}
            error={formErrors.is_fresher}
            handleChange={handleChange}
            options={isFresher}
            // selectCss1="subDiv2"
            selectCss2="label"
            selectCss3="textField2"
            readOnly={!isEditMode}
          />

        </div>

        {formData.is_fresher === 'no' && (
          <>
               <div className={styles.fieldDiv}>
          <FileInput
            label="Last 3 months payslips(not for freshers) "
            file={fileInputData.paySlipFile}
            onChange={handleFileInputChange}
            id="Payslip"
            fileCss1="subDiv2"
            fileCss2="label"
            fileCss3="fileDiv2"
            fileCss4="fileDiv1"
            readOnly={!isEditMode}
          />

          <FileInput
            label="Relieving letter(not for freshers)"
            file={fileInputData.relLetterFile}
            onChange={handleFileInputChange}
            id="RelLetter"
            fileCss1="subDiv2"
            fileCss2="label"
            fileCss3="fileDiv2"
            fileCss4="fileDiv1"
            readOnly={!isEditMode}
          />
        </div>
        <div
          className={styles.fieldDiv}
          
        >
          <div className={styles.hrDiv}>
            <div >
            <FormSelect
              label="Do you consent for reaching out to previous HR for verification?"
              name="consent"
              inputlabel="Select"
              value={formData.consent}
              error={formErrors.consent}
              handleChange={handleChange}
              options={isFresher}
              selectCss1="subDiv2"
              selectCss2="label"
              selectCss3="textField1"
              readOnly={!isEditMode}
              
            />
            </div>
            

            <FormSelect
              label="Currently working here?"
              name="currently_working"
              inputlabel="Select"
              value={formData.currently_working}
              error={formErrors.currently_working}
              handleChange={handleChange}
              options={isFresher}
              selectCss1="subDiv2"
              selectCss2="label"
              selectCss3="textField1"
              readOnly={!isEditMode}
            />
              {/* {reason for leaving} */}
            {
              formData.currently_working === 'no' && (
                <FormField
                  label="Reason for leaving"
                  name="reason_for_leaving"
                  type='text'
                  value={formData.reason_for_leaving}
                  onChange={handleChange}
                  error={formErrors.reason_for_leaving}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                />
              )
            }

            <div className={styles.subDiv}>
              <div>
                <p className={styles.label}>
                  Date of Joining<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="start_date"
                          value={dayjs(formData.start_date)}
                          className="custom-date-picker"
                          onChange={handleStartDateChange}
                          readOnly={!isEditMode}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <p className={styles.label}>
                  Date of Leaving<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="end_date"
                          value={dayjs(formData.end_date)}
                          className="custom-date-picker"
                          onChange={handleEndDateChange}
                          readOnly={!isEditMode}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            <FormField
              label="Company Contact Number"
              name="company_contact_number"
              type='number'
              value={formData.company_contact_number}
              onChange={handleChange}
              error={formErrors.company_contact_number}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            <FormField
              label="Employee ID"
              name="employee_id"
              type='text'
              value={formData.employee_id}
              onChange={handleChange}
              error={formErrors.employee_id}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            <FormField
              label="Designation"
              name="designation"
              type='text'
              value={formData.designation}
              onChange={handleChange}
              error={formErrors.designation}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            <FormField
              label="Email of Supervisor"
              name="supervisor_email"
              type='text'
              value={formData.supervisor_email}
              onChange={handleChange}
              error={formErrors.supervisor_email}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            {/* <FormField
              label="Country Code"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              error={formErrors.pincode}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
            /> */}

            <FormField
              label=" Please provide details (tenure of gap) and reasons, if there has been a gap of more than 1 month between
                  two consecutive employments?"
              name="gap_details"
              value={formData.gap_details}
              onChange={handleChange}
              type='text'
              error={formErrors.gap_details}
              cssInput1="subDiv2"
              cssInput2="label"
              cssInput5="descInput"
              placeholder="Enter Details"
              isTextarea={true}
              rows={7}
              cols={52}
              readOnly={!isEditMode}
            />
          </div>

          <div className={styles.subDiv2}>
            <FormField
              label="Organization"
              type='text'
              name="organization_name"
              value={formData.organization_name}
              onChange={handleChange}
              error={formErrors.organization_name}
              // cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            <FormField
              label="Tenure"
              type='text'
              name="tenure"
              value={formData.tenure}
              onChange={handleChange}
              error={formErrors.tenure}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            <FormField
              label="Street address of organization"
              name="street_address"
              type='text'
              value={formData.street_address}
              onChange={handleChange}
              error={formErrors.street_address}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            <div className={styles.subDiv}>
              {
                 formData.country !== 'India' ? (
                  <FormField
                    label="City"
                    name="city"
                    type='text'
                    value={formData.city}
                    onChange={handleChange}
                    error={formErrors.city}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                  />
                ):(
                  <AutocompleteInput
                  label="City"
                  name="city"
                  inputlabel="Select"
                  value={formData.city}
                  error={formErrors.city}
                  handleChange={handleChange}
                  options={cities.find((state) => state.state_name === formData.state)?.city_names || []}
                  // selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isEditMode}
                  />
                )
              }

              {
                formData.country !== 'India' ? (
                  <FormField
                    label="State"
                    name="state"
                    type='text'
                    value={formData.state}
                    onChange={handleChange}
                    error={formErrors.state}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                  />
                ):(
                  <AutocompleteInput
                  label="State"
                  name="state"
                  inputlabel="Select"
                  value={formData.state}
                  error={formErrors.state}
                  handleChange={handleChange}
                  options={union}
                  // selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isEditMode}
                  />
                )

              }
            </div>
            <div className={styles.subDiv}>
              <AutocompleteInput
                label="Country"
                name="country"
                inputlabel="Select"
                value={formData.country}
                error={formErrors.country}
                handleChange={handleChange}
                options={countries}
                // selectCss1="subDiv2"
                selectCss2="label"
                selectCss3="textField2"
                readOnly={!isEditMode}
              />

              <FormField
                label="Zip code"
                name="pincode"
                type='number'
                value={formData.pincode}
                onChange={handleChange}
                error={formErrors.pincode}
                // cssInput1="subDiv2"
                cssInput2="label"
                // cssInput3="textField2"

                placeholder="Enter Details"
                readOnly={!isEditMode}
              />
            </div>

            <FormField
              label="Name of Supervisor"
              name="supervisor_name"
              type='text'
              value={formData.supervisor_name}
              onChange={handleChange}
              error={formErrors.supervisor_name}
              cssInput1="subDiv2"
              cssInput2="label"
              // cssInput3="textField1"
              readOnly={!isEditMode}
              placeholder="Enter Details"
            />

            <FormField
              label="Phone Number of Supervisor"
              name="supervisor_phone_number"
              type='number'
              value={formData.supervisor_phone_number}
              onChange={handleChange}
              error={formErrors.supervisor_phone_number}
              cssInput1="subDiv2"
              cssInput2="label"
              // cssInput3="textField1"

              placeholder="Enter Details"
              readOnly={!isEditMode}
            />

            <FormField
              label="Name of HR"
              name="hr_name"
              type='text'
              value={formData.hr_name}
              onChange={handleChange}
              error={formErrors.hr_name}
              cssInput1="subDiv2"
              cssInput2="label"
              // cssInput3="textField1"
              readOnly={!isEditMode}
              placeholder="Enter Details"
            />

            <FormField
              label="Email of HR"
              name="hr_email"
              value={formData.hr_email}
              onChange={handleChange}
              error={formErrors.hr_email}
              cssInput1="subDiv2"
              cssInput2="label"
              type='text'
              // cssInput3="textField1"
              readOnly={!isEditMode}
              placeholder="Enter Details"
            />
            <FormField
              label="Hr Phone Number"
              name="hr_phone_number"
              type='number'
              value={formData.hr_phone_number}
              onChange={handleChange}
              error={formErrors.hr_phone_number}
              cssInput1="subDiv2"
              cssInput2="label"
              // cssInput3="textField1"
              readOnly={!isEditMode}
              placeholder="Enter HR Phone Number"
            />

            <FormSelect
              label="Do you want to add another employer details?"
              name="organization_name"
              inputlabel="Select"
              value={formData.organization_name}
              error={formErrors.organization_name}
              handleChange={handleChange}
              options={isFresher}
              selectCss1="subDiv2"
              selectCss2="label"
              selectCss3="textField2"
              readOnly={!isEditMode}
            />
          </div>
        </div>
          </>
          )}


        {/* <div className={styles.fieldDiv}>
          <div>
            <FormSelect
              label="Do you have OCI or Visa"
              name="organization_name"
              inputlabel="Select"
              value={formData.organization_name}
              error={formErrors.organization_name}
              handleChange={handleChange}
              options={isFresher}
              selectCss1="subDiv2"
              selectCss2="label"
              selectCss3="textField2"
            />
          </div>

          <div></div>
        </div>

        <div className={styles.fieldDiv}>
          <div>
            <FormField
              label="Country of origin"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              error={formErrors.pincode}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
            />

            <div className={styles.subDiv}>
              <div>
                <p className={styles.label}>
                  OCI/ VISA Date of issue<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="start_date"
                          value={dayjs(formData.start_date)}
                          className="custom-date-picker"
                          onChange={handleStartDateChange}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>

          <div>
            <FormField
              label="Email of HR"
              name="hr_email"
              value={formData.hr_email}
              onChange={handleChange}
              error={formErrors.hr_email}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
            />

            <div className={styles.subDiv}>
              <div>
                <p className={styles.label}>
                  OCI/VISA Valid From<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="end_date"
                          value={dayjs(formData.end_date)}
                          className="custom-date-picker"
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <p className={styles.label}>
                  OCI/ VISA Valid To<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="start_date"
                          value={dayjs(formData.start_date)}
                          className="custom-date-picker"
                          onChange={handleStartDateChange}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {persona !== 'admin' && (
          <div className={styles.btnDiv2}>
          <div></div>
          <div className={styles.subbtnDiv1}>
            <div className={styles.btn}>
              <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                Submit document
              </Button>
            </div>
          </div>
        </div>
          )}
          {
              persona === 'admin' && (
                <div className={styles.btnDiv2}>
                <div></div>
                <div className={styles.subbtnDiv1} >
                  <div className={styles.btn} style={{display:"flex",gap:"15px"}}>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                      Update document
                    </Button>
                    <Button type="submit" variant="contained" color="primary" onClick={toggleEditMode}>
                      Edit document
                    </Button>
                  </div>
                </div>
              </div>
              )
            }
      </form>
      <PhoneMenu/>
    </>
  );
};

export default WorkExperienceForm;

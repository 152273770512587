import { createAsyncThunk , createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "project-name/api/apiClient";
interface CandidateDocumentState {
    documents: any[]; 
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    candidateDetails: any;
  }
  const initialState: CandidateDocumentState = {
    documents: [],
    status: 'idle',
    error: null,
    candidateDetails: {}
  };

  export const fetchCandidateDocuments = createAsyncThunk(
    'candidateDocuments/fetchCandidateDocuments',
    async (user_id: string| null, thunkAPI) => {
      try {
        const response = await apiClient.get(`/api/admin/${user_id}/candidate_documents`,
           { withCredentials: true, 
      });
        return response.data
      } catch (error:any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  export const fetchCandidateForm = createAsyncThunk(
    'candidateDocuments/fetchCandidateForm',
    async (user_id: string| null, thunkAPI) => {
      try {
        const response = await apiClient.get(`/api/admin/${user_id}/candidate_forms`,
           { withCredentials: true, 
      });
        return response.data
      } catch (error:any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  const candidateDocumentSlice = createSlice({
    name: 'candidateDocuments',
    initialState,
    reducers: {
      // Define your reducers here if needed
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchCandidateDocuments.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchCandidateDocuments.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.documents = action.payload || [];
        })
        .addCase(fetchCandidateDocuments.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload as string;
        })
        .addCase(fetchCandidateForm.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchCandidateForm.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.candidateDetails = action.payload || [];
        })
        .addCase(fetchCandidateForm.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload as string;
        });
    },
  });

  export default candidateDocumentSlice.reducer;
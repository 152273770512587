import ContactSection from 'project-name/components/CandidateSecion/ContactSection/ContactSection'

const Contact = () => {
  return (
    <div>
        <ContactSection />     
    </div>
  )
}

export default Contact

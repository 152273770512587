import styles from './FaqSection.module.css';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchBar from './SearchBar';

import Accordion from './Accordion';




import faq1Img from 'project-name/assets/img/faq/faq1.png'

interface AccordionData {
  title: string;
  content: string;
  imagePath: string;
}

const accordionData: AccordionData[] = [
  { 
    title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever 1500s ?", 
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It has survived not only five centuries, but also the leap into electronic :", 
    imagePath: "" 
  },
  { 
    title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever 1500s ?", 
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It has survived not only five centuries, but also the leap into electronic :", 
    imagePath: faq1Img
  },
];
const FaqSection = () => {
  return (

    <div className={styles.mainDiv}>
    <div className={styles.faqContainer2}>
      <div className={styles.faqContainer1}>
        <div className={styles.faqTopbar}>
          <Link to={'/#'} className={styles.chevIcon}>
            <ChevronLeftIcon style={{color: '#000'}} />
          </Link>
          <h3>Frequently asked questions</h3>
        </div>
      </div>
      
        <SearchBar/>
    </div>
  
    {accordionData.map((accordion, index) => (
        <Accordion key={index} title={accordion.title}>
          {accordion.content && <p>{accordion.content}</p>}
          {accordion.imagePath && <img src={accordion.imagePath} className={styles.imgDiv} alt="Image for Accordion" />}
        </Accordion>
      ))}
    </div>
  );
};

export default FaqSection;

import React from 'react';
import { Link } from 'react-router-dom';
import { CiEdit } from 'react-icons/ci';
import { RiDeleteBinLine } from 'react-icons/ri';
import styles from './RecruiterTable.module.css';

interface ProfileRowProps {
  profile: {
    name: string;
    email: string;
    candidates: string;
    recruiterrole: string[];
  };
}

const ProfileRow: React.FC<ProfileRowProps> = ({ profile }) => {
  return (
    <div className={styles.savedProfilesList}>
      <Link to="/candidate-profile" className={styles.profileLink}>
        <p>{profile.name}</p>
        <p>{profile.email}</p>
        <p className={styles.p3}>{profile.candidates}</p>
      </Link>
      {profile.recruiterrole.includes('Onboarding agent') &&
      profile.recruiterrole.includes('Onboarding admin') ? (
        <div className={styles.Role}>
          <p className={styles.Role1}>Onboarding agent</p>
          <p className={styles.Role2}>Onboarding admin</p>
        </div>
      ) : (
        <div className={styles.Role}>
          {profile.recruiterrole.includes('Onboarding agent') && (
            <p className={styles.Role1}>Onboarding agent</p>
          )}
          {profile.recruiterrole.includes('Onboarding admin') && (
            <p className={styles.Role2}>Onboarding admin</p>
          )}
        </div>
      )}
      <p>
        <Link to="/#" aria-label="edit profile">
          <CiEdit className={styles['iconDiv']} />
        </Link>
        <Link to="/#" aria-label="delete profile">
          <RiDeleteBinLine className={styles['iconDiv']} />
        </Link>
      </p>
    </div>
  );
};

export default ProfileRow;

import styles from '../../css/layout.module.css'

import Navbar from '../../components/CandidateSecion/Global/Navbar/VerticalNavbar';

import TeamSection from 'project-name/components/CandidateSecion/TeamSection/TeamSection';

import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';


export default function Team() {
  return (

    <div className={styles.mDiv}>
        <div>
            <Navbar/>
        </div>
        <div className={styles.secondDiv}>
          <div style={{margin:'40px 100px'}}><Breadcrumb bread1="Home" bread2="Know your team" link1='/'/></div>

          

          <TeamSection />
         

        </div>

       
    </div>
    
  );
}
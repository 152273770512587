import {useState, ChangeEvent} from 'react'
import styles from './OnboardingSlider.module.css'
import {SelectChangeEvent} from '@mui/material';
import FormSelect from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormSelect';
import GlobalButton from './GlobalButton';



interface FormData{
  form_data:string;

}

const CandidateAccess = () => {

  const [formData, setFormData] = useState<FormData>({
    form_data: '',
 
  });


  const [formErrors, setFormErrors] = useState<Partial<FormData>>({
    form_data: '',
  });

  const validateField = (name: keyof FormData, value: string) => {
    return value.trim() ? '' : 'This field is required';
  };




  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string>,
  ) => {
    if ('target' in event) {
      const { name, value } = event.target as { name: string; value: string };
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
  };
    const  confirmOption=[
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
  ]

  return (
    <div>
    <div className={styles.mainformDiv}>
      <div>
        <div>
          <p className={styles.head}>Candidate view permission</p>
          <FormSelect
                  label="Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s." 
                  name="form_data_1"
                  inputlabel="Full time 5"
                  value={formData.form_data}
                  error={formErrors.form_data}
                  handleChange={handleChange}
                  options={confirmOption}
                  id="form-data-1" 
                />
        </div>
      </div>

      <div>
        <div>
          <p className={styles.head}>Candidate view permission</p>
          <FormSelect
                   label="Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s." 
                   name="form_data_2"
                   inputlabel="Full time 1"
                   value={formData.form_data}
                   error={formErrors.form_data}
                   handleChange={handleChange}
                   options={confirmOption}
                   id="form-data-2"
                />
        </div>
      </div>
    </div>


    <div className={styles.candidateAccessDiv}>
      <div className={styles.subcandidateAccessDiv}>
      <div>
        <p className={styles.head3}>Set 1</p>
        <p className={styles.para}>
          Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s.
        </p>
      </div>

      <div className={styles.iconDiv}>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none" data-testid="svg-element">
          <path d="M13.125 9.16797V16.5013H11.375V9.16797H13.125Z" fill="black" />
          <path d="M9.625 16.5013V9.16797H7.875V16.5013H9.625Z" fill="black" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.875 1.83203H6.125V5.4987H2.625V7.33203H4.375V20.1654H16.625V7.33203H18.375V5.4987H14.875V1.83203ZM13.125 5.4987V3.66536H7.875V5.4987H13.125ZM6.125 7.33203V18.332H14.875V7.33203H6.125Z"
            fill="black"
          />
        </svg>


        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none" data-testid="svg-element-1">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4874 1.83594H7V16.5026H17.5V6.03958L13.4874 1.83594ZM8.75 14.6693V3.66927H12.7626L15.75 6.79896V14.6693H8.75Z"
            fill="black"
          />
          <path d="M5.25 6.41406V18.3307H13.125V20.1641H3.5V6.41406H5.25Z" fill="black" />
        </svg>
      </div>

      <div className={styles.selectDiv}>
        <div style={{width:'100%'}}>
        <FormSelect
                  label="Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s." 
                  name="form_data_4"
                  inputlabel="Full time 2"
                  value={formData.form_data}
                  error={formErrors.form_data}
                  handleChange={handleChange}
                  options={confirmOption}
                  id="form-data-3"
                />
         
        </div>

        <div style={{width:'100%'}}>

        <FormSelect
                  label="Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s." 
                  name="form_data_3"
                  inputlabel="Full time 3"
                  value={formData.form_data}
                  error={formErrors.form_data}
                  handleChange={handleChange}
                  options={confirmOption}
                  id="form-data-4"
                />
    
        </div>

        
      </div>
      </div>

      <div className={styles.selectDiv}>
        <div style={{width:'92%'}}>

        <FormSelect
                  label="Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s." 
                  name="form_data_4"
                  inputlabel="Full time 4"
                  value={formData.form_data}
                  error={formErrors.form_data}
                  handleChange={handleChange}
                  options={confirmOption}
                  id="form-data-5"
                />

        </div>

        <div style={{width:'100%'}}>
        
        </div>

        
      </div>

      
    </div>

    <GlobalButton/>

    
  </div>
  )
}

export default CandidateAccess

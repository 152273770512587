import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import apiClient from "project-name/api/apiClient";

interface CandidateBgvStatusState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}
const initialState: CandidateBgvStatusState = {
    status: 'idle',
    error: null,
  };
  export const updateCandidateBgvStatus = createAsyncThunk(
    'candidateBgvStatus/updateCandidateBgvStatus',
    async ({ user_id }: { user_id: number }, thunkAPI) => {
      try {
        const formData = new FormData();
        formData.append('bgv_value', "Yes"); // Append the JSON value as form data
  
        const response = await apiClient.post(
          `api/admin/${user_id}/update_bgv_status`,
          formData, // Send the FormData object
          { withCredentials: true }
        );
        return response.data;
      } catch (error: any) {
        console.error("Error Response:", error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
  
  
  
  const candidateBgvStatusSlice = createSlice({
    name: 'candidateBgvStatus',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(updateCandidateBgvStatus.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(updateCandidateBgvStatus.fulfilled, (state, action) => {
          state.status = 'succeeded';
          
        })
        .addCase(updateCandidateBgvStatus.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload as string;
        });
    },
  });
  
  export default candidateBgvStatusSlice.reducer;
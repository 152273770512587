import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import styles from './Documents.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import { AutocompleteChangeDetails, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, Select, SelectChangeEvent } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Modal from '@mui/material/Modal';
import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';

import FormField from './GlobalFormsField/FormField';
import FileInput from './GlobalFormsField/FileInput';
import FormSelect from './GlobalFormsField/FormSelect';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import apiClient from 'project-name/api/apiClient';
import { cities, countries, getStatusByName, optionalEducationTypes, RequiredEducationTypes, union, yesNo } from 'project-name/util/util';
import CompletedIcon from 'project-name/components/CandidateSecion/Global/Icons/CompletedIcon';
import InprogressIcon from 'project-name/components/CandidateSecion/Global/Icons/InprogressIcon';
import PhoneMenu from 'project-name/components/CandidateSecion/Global/Navbar/phoneMenu';
import { setEducationDetails } from 'project-name/Redux/features/Education';
import AutocompleteInput from './GlobalFormsField/AutoCompleteInput';
import { useLocation } from 'react-router-dom';

interface EducationDetailsFormProps{
  persona?: string;
  tenthForm?: any;
  twelthForm?: any;
  graduationForm?: any;
  postGraduationForm?: any;
}
interface EducationType {
  education_type: string;
  degree_type?: string;
  institution_name?: string;
  roll_no?: string;
  major_specialization?: string;
  board?: string;
  country?: string;
  state?: string;
  city?: string;
  degree_name?: string;
  start_date?: string;
  completion_date?: string;
  type?: string;
}
interface FormData1 {
  degree_type: string;
  institution_name: string;
  roll_no: string;
  major_specialization: string;
  board: string;
  country: string;
  state: string;
  city: string;
}

interface FormData2 {
  degree_type: string;
  institution_name: string;
  roll_no: string;
  major_specialization: string;
  board: string;
  country: string;
  state: string;
  city: string;
}

interface FormData3 {
  degree_name: string;
  institution_name: string;
  // roll_no: string;
  degree_type: string;
  major_specialization: string;
  start_date: Dayjs | string;
  completion_date: Dayjs | string;
  country: string;
  state: string;
  city: string;
  type: string;
}
interface FormData4 {
  degree_name: string;
  institution_name: string;
  // roll_no: string;
  degree_type: string;
  major_specialization: string;
  start_date: Dayjs | string;
  completion_date: Dayjs | string;
  country: string;
  state: string;
  city: string;
  type: string;
}

interface FileInputData {
  marksheetFile?: File | null;
  certificateFile?: File | null;
}

interface FileInputData2 {
  marksheetFile?: File | null;
  certificateFile?: File | null;
}

interface FileInputData3 {
  certificate?: File | null;
}

interface FileInputData4 {
  certificate?: File | null;
}


const EducationDocument:React.FC<EducationDetailsFormProps> = ({persona,tenthForm,twelthForm,graduationForm,postGraduationForm}) => {
  const [isEditMode, setIsEditMode] = useState(()=> persona === 'admin' ? false : true);
  const [istwelthEditMode, setIstwelthEditMode] = useState(()=> persona === 'admin' ? false : true);
  const [isgraduationEditMode, setIsgraduationEditMode] = useState(()=> persona === 'admin' ? false : true);
  const [ispostgraduationEditMode, setIspostgraduationEditMode] = useState(()=> persona === 'admin' ? false : true);
  const location = useLocation();
  const [user_id, setUserId] = useState<number>(()=> persona === 'admin'? location.state.user_id : 0);
  // to get the status of the education documents
  const filteredStatus = getStatusByName("Education documents");
  const isPending = filteredStatus === "Pending";
  let educationTypes: EducationType[] = [];
  if(persona !== 'admin'){
    educationTypes = useSelector((state: RootState) => state.education.EducationList);
    if (educationTypes.length === 0) {
      educationTypes = localStorage.getItem("matchingEducationTypes") ? JSON.parse(localStorage.getItem("matchingEducationTypes") || "") : [];
    }
  }
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(educationTypes.length > 0 ? educationTypes.length : 0);
  const dispatch = useDispatch<AppDispatch>();
  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const storedDocTypeIdsWithStatus105 = JSON.parse(localStorage.getItem("docTypeIdsWithStatus105") || '[]');

  const checkIfAnyDocTypeIdsExist = (storedDocTypeIds: number[]): boolean => {
    return storedDocTypeIdsWithStatus105.some((docTypeId: number) => storedDocTypeIds.includes(docTypeId));
  };
let tenthOpen = true;
let twelthOpen = true;
let gradOpen = true;
let pGradOpen = true;
if(persona !== 'admin' || persona == null || persona === undefined){
  tenthOpen = checkIfAnyDocTypeIdsExist([301, 302]);
  twelthOpen = checkIfAnyDocTypeIdsExist([303, 304]);
  gradOpen = checkIfAnyDocTypeIdsExist([305]);
  pGradOpen = checkIfAnyDocTypeIdsExist([306]);
}

  const [fileInputData, setFileInputData] = useState<FileInputData>({
    marksheetFile: null,
    certificateFile: null,
  });

  const [fileInputData2, setFileInputData2] = useState<FileInputData2>({
    marksheetFile: null,
    certificateFile: null,
  });

  const [fileInputData3, setFileInputData3] = useState<FileInputData3>({
    certificate: null,
  });

  const [fileInputData4, setFileInputData4] = useState<FileInputData4>({
    certificate: null,
  });

  const [isPostGraduate, setIsPostGraduate] = useState<boolean>(false);

  const [formData1, setFormData1] = useState<FormData1>({
    degree_type: '',
    institution_name: '',
    roll_no: '',
    major_specialization: '',
    board: '',
    country: '',
    state: '',
    city: '',
  });

  const [formData2, setFormData2] = useState<FormData2>({
    degree_type: '',
    institution_name: '',
    roll_no: '',
    major_specialization: '',
    board: '',
    country: '',
    state: '',
    city: '',
  });

  const [formData3, setFormData3] = useState<FormData3>({
    degree_name: '',
    institution_name: '',
    // roll_no: '',
    degree_type: '',
    major_specialization: '',
    start_date: dayjs(),
    completion_date: dayjs(),
    country: '',
    state: '',
    city: '',
    type: '',
  });
  const [formData4, setFormData4] = useState<FormData4>({
    degree_name: '',
    institution_name: '',
    // roll_no: '',
    degree_type: '',
    major_specialization: '',
    start_date: dayjs(),
    completion_date: dayjs(),
    country: '',
    state: '',
    city: '',
    type: '',
  });

  useEffect(()=>{
    if (persona === 'admin' && tenthForm !== undefined) {
      const sanitizedForm = { ...tenthForm };
      for (const key in sanitizedForm) {
        if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
          sanitizedForm[key] = '';
        }
      }
      setFormData1(sanitizedForm);
    }
  },[persona,tenthForm])

useEffect(()=>{

  if(persona === 'admin' && twelthForm !== undefined){
    const sanitizedForm = { ...twelthForm };
    for (const key in sanitizedForm) {
      if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
        sanitizedForm[key] = '';
      }
    }
    setFormData2(sanitizedForm);
  }

},[persona,twelthForm])

useEffect(()=>{
  if(persona === 'admin' && graduationForm !== undefined){
    const sanitizedForm = { ...graduationForm };
    for (const key in sanitizedForm) {
      if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
        sanitizedForm[key] = '';
      }
    }
    setFormData3(sanitizedForm);
  }
},[persona,graduationForm])

useEffect(()=>{
  if(persona === 'admin' && postGraduationForm !== undefined){
    if(Object.keys(postGraduationForm).length > 0){
      setIsPostGraduate(true);
    }
    const sanitizedForm = { ...postGraduationForm };
    for (const key in sanitizedForm) {
      if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
        sanitizedForm[key] = '';
      }
    }
    setFormData4(sanitizedForm);
  }
},[persona,postGraduationForm])
const toggletenthEditMode = (e:any) => {
  setIsEditMode((prevMode) => !prevMode);
  e.preventDefault();
};

const toggleTwelthEditMode = (e:any) => {
  setIstwelthEditMode((prevMode) => !prevMode);
  e.preventDefault();
}

const toggleGraduationEditMode = (e:any) => {
  setIsgraduationEditMode((prevMode) => !prevMode);
  e.preventDefault();
}

const togglePostGraduationEditMode = (e:any) => {
  setIspostgraduationEditMode((prevMode) => !prevMode);
  e.preventDefault();
}


  const handleCheckboxChange = () => {
    setIsPostGraduate((prevValue) => !prevValue);
  }

  useEffect(() => {
    const newOpenTabs = [
      tenthOpen,
      twelthOpen,
      gradOpen,
      pGradOpen
    ];
    console.log(newOpenTabs)
    // setOpenTabs(newOpenTabs);

    if (tenthOpen) {
      setActiveIndex(0);
    } if (twelthOpen) {
      setActiveIndex(1);
    } if (gradOpen) {
      setActiveIndex(2);
    }
    if (pGradOpen) {
      setActiveIndex(3);
      handleCheckboxChange();
    }
  }, [tenthOpen, twelthOpen, gradOpen, pGradOpen]);




  useEffect(() => {
    if (educationTypes.length > 0) {
      const tenthEducation: EducationType | undefined = educationTypes.find((doc: EducationType) => doc.education_type === "10th") as EducationType | undefined;

      if (tenthEducation) {
        // Update the form data with the details from the "10th" education entry
        setFormData1({
          degree_type: tenthEducation.degree_type || '',
          institution_name: tenthEducation.institution_name || '',
          roll_no: tenthEducation.roll_no || '',
          major_specialization: tenthEducation.major_specialization || '',
          board: tenthEducation.board || '',
          country: tenthEducation.country || '',
          state: tenthEducation.state || '',
          city: tenthEducation.city || '',
        });
      }

      const twelthEducation: EducationType | undefined = educationTypes.find((doc: EducationType) => doc.education_type === "12th") as EducationType | undefined;

      if (twelthEducation) {
        setFormData2({
          degree_type: twelthEducation.degree_type || '',
          institution_name: twelthEducation.institution_name || '',
          roll_no: twelthEducation.roll_no || '',
          major_specialization: twelthEducation.major_specialization || '',
          board: twelthEducation.board || '',
          country: twelthEducation.country || '',
          state: twelthEducation.state || '',
          city: twelthEducation.city || '',
        });
      }

      const graduationEducation: EducationType | undefined = educationTypes.find((doc: EducationType) => doc.education_type === "Graduation") as EducationType | undefined;

      if (graduationEducation) {

        setFormData3({
          degree_name: graduationEducation.degree_name || '',
          institution_name: graduationEducation.institution_name || '',
          degree_type: graduationEducation.degree_type || '',
          major_specialization: graduationEducation.major_specialization || '',
          start_date: dayjs(graduationEducation.start_date) || dayjs(),
          completion_date: dayjs(graduationEducation.completion_date) || dayjs(),
          country: graduationEducation.country || '',
          state: graduationEducation.state || '',
          city: graduationEducation.city || '',
          type: graduationEducation.type || '',
        });
      }

      const postGraduationEducation: EducationType | undefined = educationTypes.find((doc: EducationType) => doc.education_type === "Post Graduation") as EducationType | undefined;

      if (postGraduationEducation) {
        setFormData4({
          degree_name: postGraduationEducation.degree_name || '',
          institution_name: postGraduationEducation.institution_name || '',
          degree_type: postGraduationEducation.degree_type || '',
          major_specialization: postGraduationEducation.major_specialization || '',
          start_date: dayjs(postGraduationEducation.start_date) || dayjs(),
          completion_date: dayjs(postGraduationEducation.completion_date) || dayjs(),
          country: postGraduationEducation.country || '',
          state: postGraduationEducation.state || '',
          city: postGraduationEducation.city || '',
          type: postGraduationEducation.type || '',
        });

      }

    }
  }, [educationTypes])

  // Example form submission handling
  const handleSubmit = async (e: FormEvent, formType: string) => {
    if (formType === 'form1') {
      e.preventDefault();
      const formValid = validateForm();
      if (formValid) {
        try {


          const formDataToSend = new FormData();
          // Append files to formData

          // Append adhaarCardFile to formData
          if (fileInputData.marksheetFile) {
            formDataToSend.append('marksheet', fileInputData.marksheetFile);
          }

          if (fileInputData.certificateFile) {
            formDataToSend.append('certificate', fileInputData.certificateFile);
          }

          if(persona === 'admin'){
            formDataToSend.append('data', JSON.stringify({
              degree_type: formData1.degree_type,
              institution_name: formData1.institution_name,
              roll_no: formData1.roll_no,
              major_specialization: formData1.major_specialization,
              board: formData1.board,
              country: formData1.country,
              state: formData1.state,
              city: formData1.city,
              education_type: "10th",
            }));
            const response = await apiClient.post(
              `/api/admin/${user_id}/update-additional-candidate-details`,
              formDataToSend,
              {
                withCredentials: true,
              }
            );
  
            if (response.status !== 200) {
              throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
            }
  
            const data = response.data;
            console.log('Form submitted successfully:', data);
            setIsEditMode((prevMode) => !prevMode);
            return;
          }

          formDataToSend.append(
            'details',
            JSON.stringify({
              degree_type: formData1.degree_type,
              institution_name: formData1.institution_name,
              roll_no: formData1.roll_no,
              major_specialization: formData1.major_specialization,
              board: formData1.board,
              country: formData1.country,
              state: formData1.state,
              city: formData1.city,
              education_type: "10th",
            }),
          );

          const response = await apiClient.post(
            `/api/candidate/upload-education-details`,
            formDataToSend,
            {
              withCredentials: true,
            }
          );

          if (response.status !== 200) {
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          console.log('Form submitted successfully:', data);
          const candidateResponse = await apiClient.get(`/api/candidate/candidate-details`, {
            withCredentials: true,
          });

          const candidate_data = candidateResponse.data;

          if (candidate_data.candidate) {
            const hasAllEducationTypes = RequiredEducationTypes.every(educationType =>
              candidate_data.education_details.some((doc: { education_type: string; }) => doc.education_type === educationType)
            );

            const education_status = hasAllEducationTypes ? "Completed" : 0;
            console.log(education_status);
            const matchingEducationTypes = candidate_data.education_details.filter((doc: { education_type: string; }) =>
              RequiredEducationTypes.includes(doc.education_type) || optionalEducationTypes.includes(doc.education_type)
            );
            localStorage.setItem("matchingEducationTypes", JSON.stringify(matchingEducationTypes));
            dispatch(setEducationDetails(matchingEducationTypes))
            // Check if matchingEducationTypes contains an entry with education_type: "10th"
          }
          // Simulated fetch request
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Simulated successful submission
          setShowModal(true);
          setActiveIndex(1);
          // Reset form data after successful submission
          // setFormData1({
          //   degree_type: '',
          //   institution_name: '',
          //   roll_no: '',
          //   major_specialization: '',
          //   board: '',
          //   country: '',
          //   state: '',
          //   city: '',
          // });

          // setFileInputData({
          //   marksheetFile: null,
          //   certificateFile: null,
          // });
        } catch (error) {
          console.error('Error submitting form:', error);
          if(persona === 'admin'){
            setIsEditMode((prevMode) => !prevMode);
          }
        }
      } else {
        console.log('Form validation failed');
      }
    } else if (formType === 'form2') {
      e.preventDefault();
      const formValid1 = validateForm2();
      if (formValid1) {
        try {


          const formDataToSend = new FormData();
          // Append files to formData

          // Append adhaarCardFile to formData
          if (fileInputData2.marksheetFile) {
            formDataToSend.append('marksheet', fileInputData2.marksheetFile);
          }

          if (fileInputData2.certificateFile) {
            formDataToSend.append('certificate', fileInputData2.certificateFile);
          }
          if(persona === 'admin'){
            formDataToSend.append('data', JSON.stringify({
              degree_type: formData2.degree_type,
              institution_name: formData2.institution_name,
              roll_no: formData2.roll_no,
              major_specialization: formData2.major_specialization,
              board: formData2.board,
              country: formData2.country,
              state: formData2.state,
              city: formData2.city,
              education_type: "12th",
            }));
            const response = await apiClient.post(
              `/api/admin/${user_id}/update-additional-candidate-details`,
              formDataToSend,
              {
                withCredentials: true,
              }
            );
  
            if (response.status !== 200) {
              throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
            }
  
            const data = response.data;
            console.log('Form submitted successfully:', data);
            setIstwelthEditMode((prevMode) => !prevMode);
            return;
          }

          formDataToSend.append(
            'details',
            JSON.stringify({
              degree_type: formData2.degree_type,
              institution_name: formData2.institution_name,
              roll_no: formData2.roll_no,
              major_specialization: formData2.major_specialization,
              board: formData2.board,
              country: formData2.country,
              state: formData2.state,
              city: formData2.city,
              education_type: "12th",
            }),
          );

          const response = await apiClient.post(
            `/api/candidate/upload-education-details`,
            formDataToSend,
            {
              withCredentials: true,
            }
          );

          if (response.status !== 200) {
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          console.log('Form submitted successfully:', data);
          const candidateResponse = await apiClient.get(`/api/candidate/candidate-details`, {
            withCredentials: true,
          });

          const candidate_data = candidateResponse.data;

          if (candidate_data.candidate) {
            const hasAllEducationTypes = RequiredEducationTypes.every(educationType =>
              candidate_data.education_details.some((doc: { education_type: string; }) => doc.education_type === educationType)
            );

            const education_status = hasAllEducationTypes ? "Completed" : 0;
            console.log(education_status);
            const matchingEducationTypes = candidate_data.education_details.filter((doc: { education_type: string; }) =>
              RequiredEducationTypes.includes(doc.education_type) || optionalEducationTypes.includes(doc.education_type)
            );
            localStorage.setItem("matchingEducationTypes", JSON.stringify(matchingEducationTypes));
            dispatch(setEducationDetails(matchingEducationTypes))
            // Check if matchingEducationTypes contains an entry with education_type: "10th"
          }
          // Simulated fetch request
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Simulated successful submission
          setShowModal(true);
          setActiveIndex(2);
          // Reset form data after successful submission
          // setFormData2({
          //   degree_type: '',
          //   institution_name: '',
          //   roll_no: '',
          //   major_specialization: '',
          //   board: '',
          //   country: '',
          //   state: '',
          //   city: '',
          // });

          setFileInputData2({
            marksheetFile: null,
            certificateFile: null,
          });
        } catch (error) {
          console.error('Error submitting form:', error);
          if(persona === 'admin'){
            setIstwelthEditMode((prevMode) => !prevMode);
          }
        }
      } else {
        console.log('Form validation failed');
      }
    } else if (formType === 'form3') {
      e.preventDefault();
      const formValid2 = validateForm3();
      if (formValid2) {
        try {

          const formDataToSend = new FormData();

          if (fileInputData3.certificate) {
            formDataToSend.append('certificate', fileInputData3.certificate);
          }

          // Format the dates to YYYY-MM-DD
          const formattedStartDate = dayjs(formData3.start_date).format('YYYY-MM-DDTHH:mm:ss');
          const formattedCompletionDate = dayjs(formData3.completion_date).format('YYYY-MM-DDTHH:mm:ss');
          if(persona === 'admin'){
            formDataToSend.append('data', JSON.stringify({
              degree_name: formData3.degree_name,
              institution_name: formData3.institution_name,
              degree_type: formData3.degree_type,
              major_specialization: formData3.major_specialization,
              start_date: formattedStartDate,
              completion_date: formattedCompletionDate,
              country: formData3.country,
              state: formData3.state,
              city: formData3.city,
              type: formData3.type,
              education_type: "Graduation",
            }));
            const response = await apiClient.post(
              `/api/admin/${user_id}/update-additional-candidate-details`,
              formDataToSend,
              {
                withCredentials: true,
              }
            );
  
            if (response.status !== 200) {
              throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
            }
  
            const data = response.data;
            console.log('Form submitted successfully:', data);
            setIsgraduationEditMode((prevMode) => !prevMode);
            return;
          }

          formDataToSend.append(
            'details',
            JSON.stringify({
              degree_name: formData3.degree_name,
              institution_name: formData3.institution_name,
              degree_type: formData3.degree_type,
              major_specialization: formData3.major_specialization,
              start_date: formattedStartDate,
              completion_date: formattedCompletionDate,
              country: formData3.country,
              state: formData3.state,
              city: formData3.city,
              type: formData3.type,
              education_type: "Graduation",
            }),
          );

          const response = await apiClient.post(
            `/api/candidate/upload-education-details`,
            formDataToSend,
            {
              withCredentials: true,
            }
          );

          if (response.status !== 200) {
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          console.log('Form submitted successfully:', data);
          const candidateResponse = await apiClient.get(`/api/candidate/candidate-details`, {
            withCredentials: true,
          });

          const candidate_data = candidateResponse.data;

          if (candidate_data.candidate) {
            const hasAllEducationTypes = RequiredEducationTypes.every(educationType =>
              candidate_data.education_details.some((doc: { education_type: string; }) => doc.education_type === educationType)
            );

            const education_status = hasAllEducationTypes ? "Completed" : 0;
            console.log(education_status);
            const matchingEducationTypes = candidate_data.education_details.filter((doc: { education_type: string; }) =>
              RequiredEducationTypes.includes(doc.education_type) || optionalEducationTypes.includes(doc.education_type)
            );
            localStorage.setItem("matchingEducationTypes", JSON.stringify(matchingEducationTypes));
            dispatch(setEducationDetails(matchingEducationTypes))
            // Check if matchingEducationTypes contains an entry with education_type: "10th"
          }
          // Simulated fetch request
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Simulated successful submission
          setShowModal(true);
          // Reset form data after successful submission
          // setFormData3({
          //   degree_name: '',
          //   institution_name: '',
          //   degree_type: '',
          //   major_specialization: '',
          //   start_date: dayjs(),
          //   completion_date: dayjs(),
          //   country: '',
          //   state: '',
          //   city: '',
          //   type: '',
          // });
          setActiveIndex(-1);
          setFileInputData3({
            certificate: null,
          });
        } catch (error) {
          console.error('Error submitting form:', error);
          if(persona === 'admin'){
            setIsgraduationEditMode((prevMode) => !prevMode);
          }
        }
      } else {
        console.log('Form validation failed');
      }
    } else if (formType === 'form4') {
      e.preventDefault();
      const formValid3 = validateForm4();
      if (formValid3) {
        try {

          const formDataToSend = new FormData();

          if (fileInputData4.certificate) {
            formDataToSend.append('certificate', fileInputData4.certificate);
          }

          // Format the dates to YYYY-MM-DD
          const formattedStartDate = dayjs(formData4.start_date).format('YYYY-MM-DDTHH:mm:ss');
          const formattedCompletionDate = dayjs(formData4.completion_date).format('YYYY-MM-DDTHH:mm:ss');
          if(persona === 'admin'){
            formDataToSend.append('data', JSON.stringify({
              degree_name: formData4.degree_name,
              institution_name: formData4.institution_name,
              degree_type: formData4.degree_type,
              major_specialization: formData4.major_specialization,
              start_date: formattedStartDate,
              completion_date: formattedCompletionDate,
              country: formData4.country,
              state: formData4.state,
              city: formData4.city,
              type: formData4.type,
              education_type: "Post Graduation",
            }));
            const response = await apiClient.post(
              `/api/admin/${user_id}/update-additional-candidate-details`,
              formDataToSend,
              {
                withCredentials: true,
              }
            );
  
            if (response.status !== 200) {
              throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
            }
  
            const data = response.data;
            console.log('Form submitted successfully:', data);
            setIspostgraduationEditMode((prevMode) => !prevMode);
            return;
          }
          formDataToSend.append(
            'details',
            JSON.stringify({
              degree_name: formData4.degree_name,
              institution_name: formData4.institution_name,
              degree_type: formData4.degree_type,
              major_specialization: formData4.major_specialization,
              start_date: formattedStartDate,
              completion_date: formattedCompletionDate,
              country: formData4.country,
              state: formData4.state,
              city: formData4.city,
              type: formData4.type,
              education_type: "Post Graduation",
            }),
          );

          const response = await apiClient.post(
            `/api/candidate/upload-education-details`,
            formDataToSend,
            {
              withCredentials: true,
            }
          );

          if (response.status !== 200) {
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          console.log('Form submitted successfully:', data);
          const candidateResponse = await apiClient.get(`/api/candidate/candidate-details`, {
            withCredentials: true,
          });

          const candidate_data = candidateResponse.data;

          if (candidate_data.candidate) {
            const hasAllEducationTypes = RequiredEducationTypes.every(educationType =>
              candidate_data.education_details.some((doc: { education_type: string; }) => doc.education_type === educationType)
            );

            const education_status = hasAllEducationTypes ? "Completed" : 0;
            console.log(education_status);
            const matchingEducationTypes = candidate_data.education_details.filter((doc: { education_type: string; }) =>
              RequiredEducationTypes.includes(doc.education_type) || optionalEducationTypes.includes(doc.education_type)
            );
            localStorage.setItem("matchingEducationTypes", JSON.stringify(matchingEducationTypes));
            dispatch(setEducationDetails(matchingEducationTypes))
            // Check if matchingEducationTypes contains an entry with education_type: "10th"
          }
          // Simulated fetch request
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Simulated successful submission
          setShowModal(true);
          // Reset form data after successful submission
          // setFormData4({
          //   degree_name: '',
          //   institution_name: '',
          //   degree_type: '',
          //   major_specialization: '',
          //   start_date: dayjs(),
          //   completion_date: dayjs(),
          //   country: '',
          //   state: '',
          //   city: '',
          //   type: '',
          // });

          setFileInputData4({
            certificate: null,
          });
        } catch (error) {
          console.error('Error submitting form:', error);
          if(persona === 'admin'){
            setIspostgraduationEditMode((prevMode) => !prevMode);
          }
        }
      } else {
        console.log('Form validation failed');
      }
    }

  };

  const [form1Errors, setForm1Errors] = useState<Partial<FormData1>>({
    degree_type: '',
    institution_name: '',
    roll_no: '',
    major_specialization: '',
    board: '',
    country: '',
    state: '',
    city: '',
  });

  const [form2Errors, setForm2Errors] = useState<Partial<FormData2>>({
    degree_type: '',
    institution_name: '',
    roll_no: '',
    major_specialization: '',
    board: '',
    country: '',
    state: '',
    city: '',
  });

  const [form3Errors, setForm3Errors] = useState<Partial<FormData3>>({
    degree_name: '',
    institution_name: '',
    // roll_no: '',
    degree_type: '',
    major_specialization: '',
    start_date: dayjs(),
    completion_date: dayjs(),
    country: '',
    state: '',
    city: '',
    type: '',
  });
  const [form4Errors, setForm4Errors] = useState<Partial<FormData4>>({
    degree_name: '',
    institution_name: '',
    // roll_no: '',
    degree_type: '',
    major_specialization: '',
    start_date: dayjs(),
    completion_date: dayjs(),
    country: '',
    state: '',
    city: '',
    type: '',
  });

  const validateField = (name: keyof FormData1, value: string) => {
    if (!isNaN(Number(value))) {
      return '';
    }
    return value.trim() ? '' : 'This field is required';
  };

  const validateField2 = (field: keyof FormData3, value: string): string | null => {
    console.log(`Validating field: ${field}, value: ${value}`);
    switch (field) {
      case 'degree_name':
      case 'institution_name':
      // case 'roll_no':
      case 'degree_type':
      case 'major_specialization':
      case 'country':
      case 'state':
      case 'city':
      case 'type':
        return value.trim() === '' ? `${field.replace('_', ' ')} is required` : null;
      case 'start_date':
      case 'completion_date':
        return value ? null : `${field.replace('_', ' ')} is required`;
      default:
        return null;
    }
  };

  const validateForm = () => {
    const errors: Partial<FormData1> = {};
    let formValid = true;

    (Object.keys(formData1) as (keyof FormData1)[]).forEach((field) => {
      const error = validateField(field, String(formData1[field])); // Convert Dayjs to string
      if (error) {
        errors[field] = error;
        formValid = false;
      }
    });

    setForm1Errors(errors);
    return formValid;
  };

  const validateForm2 = () => {
    const errors: Partial<FormData2> = {};
    let formValid1 = true;

    (Object.keys(formData2) as (keyof FormData2)[]).forEach((field) => {
      const error = validateField(field, String(formData2[field])); // Convert Dayjs to string
      if (error) {
        errors[field] = error;
        formValid1 = false;
      }
    });

    setForm2Errors(errors);
    return formValid1;
  };

  const validateForm3 = (): boolean => {
    const errors: Partial<FormData3> = {};
    let formValid2 = true;

    console.log('Form data before validation:', formData3);

    (Object.keys(formData3) as (keyof FormData3)[]).forEach((field) => {
      const error = validateField2(field, String(formData3[field]));
      if (error) {
        errors[field] = error;
        formValid2 = false;
      }
    });
    // Additional validation for start_date and completion_date
    const { start_date, completion_date } = formData3;
    if (dayjs(completion_date).isBefore(dayjs(start_date)) || dayjs(completion_date).isSame(dayjs(start_date))) {
      errors.completion_date = 'Completion date must be after the start date.';
      formValid2 = false;
    }

    setForm3Errors(errors);
    console.log('Validation errors:', errors);
    return formValid2;
  };


  const validateForm4 = (): boolean => {
    const errors: Partial<FormData3> = {};
    let formValid2 = true;

    console.log('Form data before validation:', formData4);

    (Object.keys(formData4) as (keyof FormData4)[]).forEach((field) => {
      const error = validateField2(field, String(formData4[field]));
      if (error) {
        errors[field] = error;
        formValid2 = false;
      }
    });
    // Additional validation for start_date and completion_date
    const { start_date, completion_date } = formData4;
    if (dayjs(completion_date).isBefore(dayjs(start_date)) || dayjs(completion_date).isSame(dayjs(start_date))) {
      errors.completion_date = 'Completion date must be after the start date.';
      formValid2 = false;
    }

    setForm4Errors(errors);
    console.log('Validation errors:', errors);
    return formValid2;
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string> | AutocompleteChangeDetails<any>,
    newValue?: any // Only needed for Autocomplete
  ) => {
    if (typeof newValue === 'string' && 'target' in event) {
      // Cast event.target to the correct type
      const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
      const name = target.getAttribute('id')?.split('-')[0];
      setFormData1((prevData) => {
        const newData = {
          ...prevData,
          [name as string]: newValue, // or `newValue.value` depending on your Autocomplete setup
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
    else if ('target' in event) {
      // Handle standard input/select changes
      const { name, value } = event.target as { name: string; value: string };
      setFormData1((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
  };

  const handleChange2 = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string> | AutocompleteChangeDetails<any>,
    newValue?: any // Only needed for Autocomplete
  ) => {
    if (typeof newValue === 'string' && 'target' in event) {
      // Cast event.target to the correct type
      const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
      const name = target.getAttribute('id')?.split('-')[0];
      setFormData2((prevData) => {
        const newData = {
          ...prevData,
          [name as string]: newValue, // or `newValue.value` depending on your Autocomplete setup
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
    else if ('target' in event) {
      const { name, value } = event.target as { name: string; value: string };
      setFormData2((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
  };
  const handleChange3 = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string> | AutocompleteChangeDetails<any>,
    newValue?: any // Only needed for Autocomplete
  ) => {
    if (typeof newValue === 'string' && 'target' in event) {
      // Cast event.target to the correct type
      const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
      const name = target.getAttribute('id')?.split('-')[0];
      setFormData3((prevData) => {
        const newData = {
          ...prevData,
          [name as string]: newValue, // or `newValue.value` depending on your Autocomplete setup
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
    else if ('target' in event) {
      const { name, value } = event.target as { name: string; value: string };
      setFormData3((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        return newData;
      });
    }
  };

  const handleChange4 = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string> | AutocompleteChangeDetails<any>,
    newValue?: any // Only needed for Autocomplete
  ) => {
    if (typeof newValue === 'string' && 'target' in event) {
      // Cast event.target to the correct type
      const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
      const name = target.getAttribute('id')?.split('-')[0];
      setFormData4((prevData) => {
        const newData = {
          ...prevData,
          [name as string]: newValue, // or `newValue.value` depending on your Autocomplete setup
        };
        console.log(newData); // Logging the updated form data
        return newData;
      });
    }
    else if ('target' in event) {
      const { name, value } = event.target as { name: string; value: string };
      setFormData4((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        return newData;
      });
    }
  };



  // Function to handle file input change
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'marksheetInput':
          setFileInputData((prevData) => ({
            ...prevData,
            marksheetFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'certificateInput':
          setFileInputData((prevData) => ({
            ...prevData,
            certificateFile: files[0] || null, // Set to null if no file selected
          }));
          break;
      }
    }
  };

  const handleFileInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'marksheetInput1':
          setFileInputData2((prevData) => ({
            ...prevData,
            marksheetFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'certificateInput1':
          setFileInputData2((prevData) => ({
            ...prevData,
            certificateFile: files[0] || null, // Set to null if no file selected
          }));
          break;
      }
    }
  };

  const handleFileInputChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'marksheetInput3':
          setFileInputData3((prevData) => ({
            ...prevData,
            certificate: files[0] || null, // Set to null if no file selected
          }));
          break;
      }
    }
  };

  const handleFileInputChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'marksheetInput4':
          setFileInputData4((prevData) => ({
            ...prevData,
            certificate: files[0] || null, // Set to null if no file selected
          }));
          break;
      }
    }
  };

  // Handler for when the date changes
  const handleDateChange = (date: Dayjs | null, field: 'start_date' | 'completion_date') => {
    setFormData3((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: date || dayjs(), // Use current date as default if date is null
      };
      console.log('Updated Form Data:', updatedData);
      return updatedData;
    });
  };

  // Handler for when the date changes
  const handleDateChange1 = (date: Dayjs | null, field: 'start_date' | 'completion_date') => {
    setFormData4((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: date || dayjs(), // Use current date as default if date is null
      };
      console.log('Updated Form Data:', updatedData);
      return updatedData;
    });
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'start_date');
  };

  const handleCompletionDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'completion_date');
  };

  const handleStartDateChange1 = (date: Dayjs | null) => {
    handleDateChange1(date, 'start_date');
  };

  const handleCompletionDateChange1 = (date: Dayjs | null) => {
    handleDateChange1(date, 'completion_date');
  };

  // submit popup
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const degree = [
    {
      value: 'fulltime',
      label: 'Full Time',
    },
    {
      value: 'parttime',
      label: 'Part Time',
    },
    {
      value: 'Correspondence',
      label: 'Correspondence',
    },
    {
      value: 'Distance Learning',
      label: 'Distance Learning',
    },
  ];

  const boards = [
    {
      value: 'Central Board of Secondary Education (CBSE)',
      label: 'Central Board of Secondary Education (CBSE)',
    },
    {
      value: 'Indian Certificate of Secondary Education (ICSE)',
      label: 'Indian Certificate of Secondary Education (ICSE)',
    },
    {
      value: 'Andhra Pradesh Board of Secondary Education (BSEAP)',
      label: 'Andhra Pradesh Board of Secondary Education (BSEAP)',
    },
    {
      value: 'Maharashtra State Board of Secondary and Higher Secondary Education (MSBSHSE)',
      label: 'Maharashtra State Board of Secondary and Higher Secondary Education (MSBSHSE)',
    },
    {
      value: 'Tamil Nadu State Board',
      label: 'Tamil Nadu State Board',
    },
    {
      value: 'Karnataka Secondary Education Examination Board (KSEEB)',
      label: 'Karnataka Secondary Education Examination Board (KSEEB)',
    },
    {
      value: 'Gujarat Secondary and Higher Secondary Education Board (GSEB)',
      label: 'Gujarat Secondary and Higher Secondary Education Board (GSEB)',
    },
    {
      value: 'West Bengal Board of Secondary Education (WBBSE)',
      label: 'West Bengal Board of Secondary Education (WBBSE)',
    },
    {
      value: 'Punjab School Education Board (PSEB)',
      label: 'Punjab School Education Board (PSEB)',
    },
    {
      value: 'Rajasthan Board of Secondary Education (RBSE)',
      label: 'Rajasthan Board of Secondary Education (RBSE)',
    },
    {
      value: 'Haryana Board of School Education (HBSE)',
      label: 'Haryana Board of School Education (HBSE)',
    },
    {
      value: 'Bihar School Examination Board (BSEB)',
      label: 'Bihar School Examination Board (BSEB)',
    },
    {
      value: 'Madhya Pradesh Board of Secondary Education (MPBSE)',
      label: 'Madhya Pradesh Board of Secondary Education (MPBSE)',
    },
    {
      value: 'Himachal Pradesh Board of School Education (HPBOSE)',
      label: 'Himachal Pradesh Board of School Education (HPBOSE)',
    },
    {
      value: 'Chhattisgarh Board of Secondary Education (CGBSE)',
      label: 'Chhattisgarh Board of Secondary Education (CGBSE)',
    },
    {
      value: 'National Institute of Open Schooling (NIOS)',
      label: 'National Institute of Open Schooling (NIOS)',
    },
    {
      value: 'International General Certificate of Secondary Education (IGCSE)',
      label: 'International General Certificate of Secondary Education (IGCSE)',
    },
    {
      value: 'International Baccalaureate (IB) Middle Years Programme (MYP)',
      label: 'International Baccalaureate (IB) Middle Years Programme (MYP)',
    },
    {
      value: 'other',
      label: 'Other'
    }
  ];

  const board_12 = [
    {
      value: 'Central Board of Secondary Education (CBSE)',
      label: 'Central Board of Secondary Education (CBSE)',
    },
    {
      value: 'Indian School Certificate (ISC)',
      label: 'Indian School Certificate (ISC)',
    },
    {
      value: 'Andhra Pradesh Board of Intermediate Education (BIEAP)',
      label: 'Andhra Pradesh Board of Intermediate Education (BIEAP)',
    },
    {
      value: 'Maharashtra State Board of Secondary and Higher Secondary Education (MSBSHSE)',
      label: 'Maharashtra State Board of Secondary and Higher Secondary Education (MSBSHSE)',
    },
    {
      value: 'Tamil Nadu State Board',
      label: 'Tamil Nadu State Board',
    },
    {
      value: 'Karnataka Pre-University Education Board (KSEEB)',
      label: 'Karnataka Pre-University Education Board (KSEEB)',
    },
    {
      value: 'Gujarat Secondary and Higher Secondary Education Board (GSEB)',
      label: 'Gujarat Secondary and Higher Secondary Education Board (GSEB)',
    },
    {
      value: 'West Bengal Council of Higher Secondary Education (WBCHSE)',
      label: 'West Bengal Council of Higher Secondary Education (WBCHSE)',
    },
    {
      value: 'Punjab School Education Board (PSEB)',
      label: 'Punjab School Education Board (PSEB)',
    },
    {
      value: 'Rajasthan Board of Secondary Education (RBSE)',
      label: 'Rajasthan Board of Secondary Education (RBSE)',
    },
    {
      value: 'Haryana Board of School Education (HBSE)',
      label: 'Haryana Board of School Education (HBSE)',
    },
    {
      value: 'Bihar School Examination Board (BSEB)',
      label: 'Bihar School Examination Board (BSEB)',
    },
    {
      value: 'Madhya Pradesh Board of Secondary Education (MPBSE)',
      label: 'Madhya Pradesh Board of Secondary Education (MPBSE)',
    },
    {
      value: 'Himachal Pradesh Board of School Education (HPBOSE)',
      label: 'Himachal Pradesh Board of School Education (HPBOSE)',
    },
    {
      value: 'Chhattisgarh Board of Secondary Education (CGBSE)',
      label: 'Chhattisgarh Board of Secondary Education (CGBSE)',
    },
    {
      value: 'National Institute of Open Schooling (NIOS)',
      label: 'National Institute of Open Schooling (NIOS)',
    },
    {
      value: 'International General Certificate of Secondary Education (IGCSE)',
      label: 'International General Certificate of Secondary Education (IGCSE)',
    },
    {
      value: 'International Baccalaureate (IB) Diploma Programme (DP)',
      label: 'International Baccalaureate (IB) Diploma Programme (DP)',
    },
    {
      value: 'other',
      label: 'Other'
    }
  ]


  return (
    <section className={styles.mainAccord}>
      {/* Modal */}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SubmitPopup handleClose={handleCloseModal} />
        </Modal>
      )}
      {/* first accordion  */}
      <div className={`${styles.subAccord} ${!tenthOpen && !isPending ? styles.disabled : ''}`}>

        <button
          className={`${styles.accordion} ${activeIndex === 0 ? styles.active : ''}`}
          onClick={() => toggleAccordion(0)}
          aria-expanded={activeIndex === 0}
        >
          <p>10th documents</p>
          <b className={styles.statusDiv}>
          {persona === 'admin' && tenthForm && Object.keys(tenthForm).length > 0 ? (
        <CompletedIcon />
      ) : (
        educationTypes.find((doc) => doc.education_type === '10th') ? (
          <CompletedIcon />
        ) : (
          <InprogressIcon />
        )
      )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            {/* <div className={styles.fieldDiv}> */}
            <div className={`${styles.fieldDiv} ${persona === 'admin' ? '' : (tenthOpen ? styles.disabled : '')}`}>

              <div>
                <FormSelect
                  label="Degree type"
                  name="degree_type"
                  inputlabel="Full time"
                  value={formData1.degree_type}
                  error={form1Errors.degree_type}
                  handleChange={handleChange}
                  options={degree}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isEditMode}
                />

                <FormField
                  label="Institute name"
                  name="institution_name"
                  type='text'
                  value={formData1.institution_name}
                  onChange={handleChange}
                  error={form1Errors.institution_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                />

                <div className={styles.subDiv}>
                  <FormField
                    type='text'
                    label="Roll Number"
                    name="roll_no"
                    value={formData1.roll_no}
                    onChange={handleChange}
                    error={form1Errors.roll_no}
                    cssInput2="label"
                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                  />

                  <AutocompleteInput
                    label="Country"
                    name="country"
                    inputlabel="Select"
                    value={formData1.country}
                    error={form1Errors.country}
                    handleChange={handleChange}
                    options={countries}
                    selectCss1=""
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!isEditMode}
                  />
                </div>



              </div>

              <div>
                <FormSelect
                  label="Board"
                  name="board"
                  inputlabel="Select"
                  value={formData1.board}
                  error={form1Errors.board}
                  handleChange={handleChange}
                  options={boards}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isEditMode}
                />


                <FormField
                  label="Specialization/Major"
                  type='text'
                  name="major_specialization"
                  value={formData1.major_specialization}
                  onChange={handleChange}
                  error={form1Errors.major_specialization}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Specialization"
                  readOnly={!isEditMode}
                />

                <div className={styles.subDiv}>
                  {
                    formData1.country !== 'India' ? (

                      <FormField
                        label='State'
                        type='text'
                        name='state'
                        value={formData1.state}
                        onChange={handleChange}
                        error={form1Errors.state}
                        cssInput2='label'
                        cssInput1='subDiv2'
                        placeholder='Enter State'
                        readOnly={!isEditMode}
                      />
                    ) : (
                      <AutocompleteInput
                        label="State"
                        name="state"
                        inputlabel="Select"
                        value={formData1.state}
                        error={form1Errors.state}
                        handleChange={handleChange}
                        options={union}
                        selectCss1=""
                        selectCss2="label1"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )
                  }

                  {formData1.country !== 'India' ? (
                    <FormField
                      label='City'
                      type='text'
                      name='city'
                      value={formData1.city}
                      onChange={handleChange}
                      error={form1Errors.city}
                      cssInput2='label'
                      cssInput1='subDiv2'
                      placeholder='Enter City'
                      readOnly={!isEditMode}
                    />
                  ) : (
                    <AutocompleteInput
                      label="City"
                      name="city"
                      inputlabel="Select"
                      value={formData1.city}
                      error={form1Errors.city}
                      handleChange={handleChange}
                      options={cities.find((state) => state.state_name === formData1.state)?.city_names || []}
                      selectCss1=""
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!isEditMode}
                    />
                  )

                  }

                </div>
                <div className={styles.uploadDiv}>


                </div>

              </div>
            </div>
            <div className={styles.uploadForm}>
            <div className={`${persona !== 'admin' && !checkIfAnyDocTypeIdsExist([301]) && !isPending ? styles.disabled : ''}`}>

                <FileInput
                  label="Upload marksheet"
                  file={fileInputData.marksheetFile}
                  onChange={handleFileInputChange}
                  id="marksheetInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                  readOnly={!isEditMode}
                />
              </div>
              <div className={`${persona !== 'admin' && !checkIfAnyDocTypeIdsExist([302]) && !isPending ? styles.disabled : ''}`}>

                <FileInput
                  label="Upload certificate"
                  file={fileInputData.certificateFile}
                  onChange={handleFileInputChange}
                  id="certificateInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                  readOnly={!isEditMode}
                />
              </div>
            </div>
            {persona !== 'admin' && (
              <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn}>
                  {educationTypes.find((doc) => doc.education_type === '10th') && !tenthOpen ? (
                    <Button type="submit" variant="contained" color="secondary" disabled>
                      Document Submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form1')}>
                      Submit document
                    </Button>
                  )}
                </div>

              </div>
            </div>
              )}

        {
              persona === 'admin' && (
                <div className={styles.btnDiv2}>
                <div></div>
                <div className={styles.subbtnDiv1} >
                  <div className={styles.btn} style={{display:"flex",gap:"15px"}}>
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form1')}>
                      Update document
                    </Button>
                    {!isEditMode && (

                    <Button type="submit" variant="contained" color="primary" onClick={toggletenthEditMode}>
                      Edit document
                    </Button>
                    )}
                  </div>
                </div>
              </div>
              )
            }
          </div>
        </form>
      </div>

      {/* second accordion  */}
      <div className={`${styles.subAccord} ${!twelthOpen && !isPending ? styles.disabled : ''}`}>

        {/* <div className={styles.subAccord}> */}
        <button
          className={`${styles.accordion} ${activeIndex === 1 ? styles.active : ''}`}
          onClick={() => toggleAccordion(1)}
          aria-expanded={activeIndex === 1}
        >
          <p>12th documents</p>
          <b className={styles.statusDiv}>
          {persona === 'admin' && twelthForm && Object.keys(twelthForm).length > 0 ? (
            <CompletedIcon />
          ) : (
        educationTypes.find((doc) => doc.education_type === '12th') ? (
          <CompletedIcon />
        ) : (
          <InprogressIcon />
        ))}
           
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 1 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv}>

              <div>
                <div className={`${persona === 'admin' ? '' : (twelthOpen ? styles.disabled : '')}`}>

                  <FormSelect
                    label="Degree type"
                    name="degree_type"
                    inputlabel="Full time"
                    value={formData2.degree_type}
                    error={form2Errors.degree_type}
                    handleChange={handleChange2}
                    options={degree}
                    selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!istwelthEditMode}
                  />
                  <FormField
                    label="Institute name"
                    type='text'
                    name="institution_name"
                    value={formData2.institution_name}
                    onChange={handleChange2}
                    error={form2Errors.institution_name}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    placeholder="Enter Details"
                    readOnly={!istwelthEditMode}
                  />

                  <div className={styles.subDiv}>
                    <FormField
                      label="Roll number"
                      name="roll_no"
                      type='text'
                      value={formData2.roll_no}
                      onChange={handleChange2}
                      error={form2Errors.roll_no}
                      cssInput2="label"
                      placeholder="Enter Details"
                      readOnly={!istwelthEditMode}
                    />

                    <AutocompleteInput
                      label="Country"
                      name="country"
                      inputlabel="Select"
                      value={formData2.country}
                      error={form2Errors.country}
                      handleChange={handleChange2}
                      options={countries}
                      selectCss1=""
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!istwelthEditMode}
                    />
                  </div>
                </div>
                <div className={`${persona !== 'admin' && !checkIfAnyDocTypeIdsExist([303]) && !isPending ? styles.disabled : ''}`}>

                  <FileInput
                    label="Upload marksheet"
                    file={fileInputData2.marksheetFile}
                    onChange={handleFileInputChange2}
                    id="marksheetInput1"
                    fileCss1="subDiv2"
                    fileCss2="label"
                    fileCss3="fileDiv2"
                    fileCss4="fileDiv1"
                    readOnly={!istwelthEditMode}
                  />
                </div>
              </div>

              <div>
                <div className={`${ persona !=='admin' && twelthOpen ? styles.disabled : ''}`}>

                  <FormSelect
                    label="Board"
                    name="board"
                    inputlabel="Select"
                    value={formData2.board}
                    error={form2Errors.board}
                    handleChange={handleChange2}
                    options={board_12}
                    selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!istwelthEditMode}
                  />
                  <FormField
                    label="Specialization/Major"
                    name="major_specialization"
                    type='text'
                    value={formData2.major_specialization}
                    onChange={handleChange2}
                    error={form2Errors.major_specialization}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    placeholder="Enter Specialization"
                    readOnly={!istwelthEditMode}
                  />

                  <div className={styles.subDiv}>
                    {formData2.country !== 'India' ? (
                      <FormField
                        label='State'
                        type='text'
                        name='state'
                        value={formData2.state}
                        onChange={handleChange2}
                        error={form2Errors.state}
                        cssInput2='label'
                        cssInput1='subDiv2'
                        placeholder='Enter State'
                        readOnly={!istwelthEditMode}
                      />
                    ) : (
                      <AutocompleteInput
                        label="State"
                        name="state"
                        inputlabel="Select"
                        value={formData2.state}
                        error={form2Errors.state}
                        handleChange={handleChange2}
                        options={union}
                        selectCss1=""
                        selectCss2="label1"
                        selectCss3="textField2"
                        readOnly={!istwelthEditMode}
                      />
                    )}

                    {
                      formData2.country !== 'India' ? (
                        <FormField
                          label='City'
                          type='text'
                          name='city'
                          value={formData2.city}
                          onChange={handleChange2}
                          error={form2Errors.city}
                          cssInput2='label'
                          cssInput1='subDiv2'
                          placeholder='Enter City'
                          readOnly={!istwelthEditMode}
                        />
                      ) : (
                        <AutocompleteInput
                          label="City"
                          name="city"
                          inputlabel="Select"
                          value={formData2.city}
                          error={form2Errors.city}
                          handleChange={handleChange2}
                          options={cities.find((state) => state.state_name === formData2.state)?.city_names || []}
                          selectCss1=""
                          selectCss2="label"
                          selectCss3="textField2"
                          readOnly={!istwelthEditMode}
                        />
                      )

                    }
                  </div>
                </div>
                <div className={`${ persona !== 'admin' && !checkIfAnyDocTypeIdsExist([304]) && !isPending ? styles.disabled : ''}`}>

                  <FileInput
                    label="Upload certificate"
                    file={fileInputData2.certificateFile}
                    onChange={handleFileInputChange2}
                    id="certificateInput1"
                    fileCss1="subDiv2"
                    fileCss2="label"
                    fileCss3="fileDiv2"
                    fileCss4="fileDiv1"
                    readOnly={!istwelthEditMode}
                  />
                </div>
              </div>

            </div>

            {persona !== 'admin' && (
                          <div className={styles.btnDiv}>
                          <div></div>
                          <div className={styles.subbtnDiv}>
                            <div className={styles.btn}>
                              {educationTypes.find((doc) => doc.education_type === '12th') && !twelthOpen ? (
                                <Button type="submit" variant="contained" color="secondary" disabled>
                                  Document Submitted
                                </Button>
                              ) : (
                                <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form2')}>
                                  Submit document
                                </Button>
                              )}
            
                            </div>
            
                          </div>
                        </div>
              )}

        {
              persona === 'admin' && (
                <div className={styles.btnDiv2}>
                <div></div>
                <div className={styles.subbtnDiv1} >
                  <div className={styles.btn} style={{display:"flex",gap:"15px"}}>
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form2')}>
                      Update document
                    </Button>
                    {!istwelthEditMode && (
                    <Button type="submit" variant="contained" color="primary" onClick={toggleTwelthEditMode}>
                      Edit document
                    </Button>
                      )}
                  </div>
                </div>
              </div>
              )
            }

          </div>
        </form>
      </div>

      {/* third accorion */}
      <div className={`${styles.subAccord} ${!gradOpen && !isPending ? styles.disabled : ''}`}>

        {/* <div className={styles.subAccord}> */}
        <button
          className={`${styles.accordion} ${activeIndex === 2 ? styles.active : ''}`}
          onClick={() => toggleAccordion(2)}
          aria-expanded={activeIndex === 2}
        >
          <p>Graduation documents</p>
          <b className={styles.statusDiv}>
          {persona === 'admin' && graduationForm && Object.keys(graduationForm).length > 0 ? (
            <CompletedIcon />
          ) : (
            educationTypes.find((doc) => doc.education_type === 'Graduation') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            ))}

            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 2 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            {/* <div className={styles.fieldDiv}> */}
            <div className={`${styles.fieldDiv} ${persona === 'admin' ? '' : (gradOpen ? styles.disabled : '')} `}>

              <div>
                <FormSelect
                  label="Qualification type"
                  name="degree_type"
                  inputlabel="Full time"
                  value={formData3.degree_type}
                  error={form3Errors.degree_type}
                  handleChange={handleChange3}
                  options={degree}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isgraduationEditMode}
                />

                <FormField
                  label="Name of the degree"
                  name="degree_name"
                  type='text'
                  value={formData3.degree_name}
                  onChange={handleChange3}
                  error={form3Errors.degree_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter details"
                  readOnly={!isgraduationEditMode}
                />
                <div className={styles.subDiv}>
                  <div>
                    <p className={styles.label}>
                      Start date<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              label="Select"
                              name="start_date"
                              value={dayjs(formData3.start_date)}
                              className="custom-date-picker"
                              onChange={handleStartDateChange}
                              readOnly={!isgraduationEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div>
                    <p className={styles.label}>
                      End date<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              label="Select"
                              className="custom-date-picker"
                              name="completion_date"
                              value={dayjs(formData3.completion_date)}
                              onChange={handleCompletionDateChange}
                              readOnly={!isgraduationEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className={styles.subDiv2}>
                  <div className={styles.textField2}>
                    <AutocompleteInput
                      label="Country"
                      name="country"
                      inputlabel="Select"
                      value={formData3.country}
                      error={form3Errors.country}
                      handleChange={handleChange3}
                      options={countries}
                      selectCss1=""
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!isgraduationEditMode}
                    />
                  </div>
                </div>
              </div>

              <div>
                <FormField
                  label="Institute name"
                  name="institution_name"
                  type='text'
                  value={formData3.institution_name}
                  onChange={handleChange3}
                  error={form3Errors.institution_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter details"
                  readOnly={!isgraduationEditMode}
                />

                <FormField
                  label="Specialization/Major"
                  name="major_specialization"
                  type='text'
                  value={formData3.major_specialization}
                  onChange={handleChange3}
                  error={form3Errors.major_specialization}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Specialization"
                  readOnly={!isgraduationEditMode}
                />

                <div className={styles.subDiv}>
                  {formData3.country !== 'India' ? (

                    <FormField
                      label='State'
                      type='text'
                      name='state'
                      value={formData3.state}
                      onChange={handleChange3}
                      error={form3Errors.state}
                      cssInput2='label'
                      cssInput1='subDiv2'
                      placeholder='Enter State'
                      readOnly={!isgraduationEditMode}
                    />
                  ) : (
                    <AutocompleteInput
                      label="State"
                      name="state"
                      inputlabel="Select"
                      value={formData3.state}
                      error={form3Errors.state}
                      handleChange={handleChange3}
                      options={union}
                      selectCss1=""
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!isgraduationEditMode}
                    />
                  )}

                  {formData3.country !== 'India' ? (
                    <FormField
                      label='City'
                      type='text'
                      name='city'
                      value={formData3.city}
                      onChange={handleChange3}
                      error={form3Errors.city}
                      cssInput2='label'
                      cssInput1='subDiv2'
                      readOnly={!isgraduationEditMode}
                      placeholder='Enter City'
                    />
                  ) : (
                    <AutocompleteInput
                      label="City"
                      name="city"
                      inputlabel="Select"
                      value={formData3.city}
                      error={form3Errors.city}
                      handleChange={handleChange3}
                      options={cities.find((state) => state.state_name === formData3.state)?.city_names || []}
                      selectCss1=""
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!isgraduationEditMode}
                    />
                  )}
                </div>

                <FormSelect
                  label="Do you have any other degree?"
                  name="type"
                  inputlabel="yes / no"
                  value={formData3.type}
                  error={form3Errors.type}
                  handleChange={handleChange3}
                  options={yesNo}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField2"
                  readOnly={!isgraduationEditMode}
                />
              </div>
            </div>

            <FileInput
              label="Upload marksheet"
              file={fileInputData3.certificate}
              onChange={handleFileInputChange3}
              id="marksheetInput3"
              fileCss1="docDiv1"
              fileCss2="label"
              fileCss3="fileDiv"
              fileCss4="fileDiv1"
              readOnly={!isgraduationEditMode}
            />

            {persona !=='admin' && (
              <div className={styles.btnDiv1} style={{ paddingRight: '60px' }}>
              <div></div>
              <div className={styles.subbtnDiv1}>
                <div className={styles.btn}>
                  {educationTypes.find((doc) => doc.education_type === 'Graduation') && !gradOpen ? (
                    <Button type="submit" variant="contained" color="secondary" disabled>
                      Document Submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form3')}>
                      Submit document
                    </Button>
                  )}
                </div>

              </div>
            </div>
              )}

{
              persona === 'admin' && (
                <div className={styles.btnDiv2}>
                <div></div>
                <div className={styles.subbtnDiv1} >
                  <div className={styles.btn} style={{display:"flex",gap:"15px"}}>
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form3')}>
                      Update document
                    </Button>
                    {!isgraduationEditMode && (
                      
                    <Button type="submit" variant="contained" color="primary" onClick={toggleGraduationEditMode}>
                      Edit document
                    </Button>
                    )
                    }
                  </div>
                </div>
              </div>
              )
            }
          </div>
        </form>
      </div>

      {/* check box to select Post  Graduation */}
      <div style={{ padding: "20px" }} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={isPostGraduate} onChange={handleCheckboxChange} name="Post Graduate" />} label="Post Graduate" />
        </FormGroup>
      </div>

      {/* Post Graduation form */}
      {isPostGraduate && (
        <>
          {/* <div className={styles.subAccord}> */}
          <div className={`${styles.subAccord} ${!pGradOpen && !isPending ? styles.disabled : ''}`}>

            <button
              className={`${styles.accordion} ${activeIndex === 3 ? styles.active : ''}`}
              onClick={() => toggleAccordion(3)}
              aria-expanded={activeIndex === 3}
            >
              <p>Post Graduation documents</p>
              <b className={styles.statusDiv}>
              {persona === 'admin' && postGraduationForm && Object.keys(postGraduationForm).length > 0 ? (
            <CompletedIcon />
          ) : (
            educationTypes.find((doc) => doc.education_type === 'Post Graduation') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            ))}
                
                <ExpandMoreIcon
                  className={styles.moreIcon}
                  style={{ transform: activeIndex === 3 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
                />
              </b>
            </button>
            <form className={styles.panel} style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
              <div className={styles.mAttachDiv}>
                {/* <div className={styles.fieldDiv}> */}
                <div className={`${styles.fieldDiv} ${persona === 'admin' ? '' : (pGradOpen ? styles.disabled : '')}`}>

                  <div>
                    <FormSelect
                      label="Qualification type"
                      name="degree_type"
                      inputlabel="Full time"
                      value={formData4.degree_type}
                      error={form4Errors.degree_type}
                      handleChange={handleChange4}
                      options={degree}
                      selectCss1="subDiv2"
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!ispostgraduationEditMode}
                    />

                    <FormField
                      label="Name of the degree"
                      name="degree_name"
                      type='text'
                      value={formData4.degree_name}
                      onChange={handleChange4}
                      error={form4Errors.degree_name}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      placeholder="Enter details"
                      readOnly={!ispostgraduationEditMode}
                    />
                    <div className={styles.subDiv}>
                      <div>
                        <p className={styles.label}>
                          Start date<span style={{ color: 'red' }}>*</span>
                        </p>
                        <div className={styles.textField}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                              <div style={{ backgroundColor: '#fff' }}>
                                <DatePicker
                                  label="Select"
                                  name="start_date"
                                  value={dayjs(formData4.start_date)}
                                  className="custom-date-picker"
                                  onChange={handleStartDateChange1}
                                  readOnly={!ispostgraduationEditMode}
                                />
                              </div>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div>
                        <p className={styles.label}>
                          End date<span style={{ color: 'red' }}>*</span>
                        </p>
                        <div className={styles.textField}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                              <div style={{ backgroundColor: '#fff' }}>
                                <DatePicker
                                  label="Select"
                                  className="custom-date-picker"
                                  name="completion_date"
                                  value={dayjs(formData4.completion_date)}
                                  onChange={handleCompletionDateChange1}
                                  readOnly={!ispostgraduationEditMode}
                                />
                              </div>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className={styles.subDiv2}>
                      <div className={styles.textField2}>
                        <AutocompleteInput
                          label="country"
                          name="country"
                          inputlabel="select"
                          value={formData4.country}
                          error={form4Errors.country}
                          handleChange={handleChange4}
                          options={union}
                          selectCss1=""
                          selectCss2="label"
                          selectCss3="textField2"
                          readOnly={!ispostgraduationEditMode}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <FormField
                      label="Institute name"
                      name="institution_name"
                      type='text'
                      value={formData4.institution_name}
                      onChange={handleChange4}
                      error={form4Errors.institution_name}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      placeholder="Enter details"
                      readOnly={!ispostgraduationEditMode}
                    />

                    <FormField
                      label="Specialization/Major"
                      name="major_specialization"
                      type='text'
                      value={formData4.major_specialization}
                      onChange={handleChange4}
                      error={form4Errors.major_specialization}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      placeholder="Enter Specialization"
                      readOnly={!ispostgraduationEditMode}
                    />

                    <div className={styles.subDiv}>

                      {formData4.country !== 'India' ? (

                        <FormField
                          label='State'
                          type='text'
                          name='state'
                          value={formData4.state}
                          onChange={handleChange4}
                          error={form4Errors.state}
                          cssInput2='label'
                          cssInput1='subDiv2'
                          placeholder='Enter State'
                          readOnly={!ispostgraduationEditMode}
                        />
                      ) : (
                        <AutocompleteInput
                          label="State"
                          name="state"
                          inputlabel="select"
                          value={formData4.state}
                          error={form4Errors.state}
                          handleChange={handleChange4}
                          options={union}
                          selectCss1=""
                          selectCss2="label1"
                          selectCss3="textField2"
                          readOnly={!ispostgraduationEditMode}
                        />
                      )}

                      {formData4.country !== 'India' ? (
                        <FormField
                          label='City'
                          type='text'
                          name='city'
                          value={formData4.city}
                          onChange={handleChange4}
                          error={form4Errors.city}
                          cssInput2='label'
                          cssInput1='subDiv2'
                          placeholder='Enter City'
                          readOnly={!ispostgraduationEditMode}
                        />
                      ) : (
                        <AutocompleteInput
                          label="City"
                          name="city"
                          inputlabel="select"
                          value={formData4.city}
                          error={form4Errors.city}
                          handleChange={handleChange4}
                          options={cities.find((state) => state.state_name === formData4.state)?.city_names || []}
                          selectCss1=""
                          selectCss2="label"
                          selectCss3="textField2"
                          readOnly={!ispostgraduationEditMode}
                        />
                      )

                      }
                    </div>

                    <FormSelect
                      label="Do you have any other degree?"
                      name="type"
                      inputlabel="yes / no"
                      value={formData4.type}
                      error={form4Errors.type}
                      handleChange={handleChange4}
                      options={yesNo}
                      selectCss1="subDiv2"
                      selectCss2="label"
                      selectCss3="textField2"
                      readOnly={!ispostgraduationEditMode}
                    />
                  </div>
                </div>

                <FileInput
                  label="Upload marksheet"
                  file={fileInputData4.certificate}
                  onChange={handleFileInputChange4}
                  id="marksheetInput4"
                  fileCss1="docDiv1"
                  fileCss2="label"
                  fileCss3="fileDiv"
                  fileCss4="fileDiv1"
                  readOnly={!ispostgraduationEditMode}
                />

              {persona !== 'admin' && (
                <div className={styles.btnDiv1} style={{ paddingRight: '60px' }}>
                <div></div>
                <div className={styles.subbtnDiv1}>
                  <div className={styles.btn}>
                    {educationTypes.find((doc) => doc.education_type === 'Post Graduation') && !pGradOpen ? (
                      <Button type="submit" variant="contained" color="secondary" disabled>
                        Document Submitted
                      </Button>
                    ) : (
                      <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form4')}>
                        Submit document
                      </Button>
                    )}

                  </div>

                </div>
              </div>
                )}

              {
              persona === 'admin' && (
                <div className={styles.btnDiv2}>
                <div></div>
                <div className={styles.subbtnDiv1} >
                  <div className={styles.btn} style={{display:"flex",gap:"15px"}}>
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'form4')}>
                      Update document
                    </Button>
                    {!ispostgraduationEditMode &&(           
                    <Button type="submit" variant="contained" color="primary" onClick={togglePostGraduationEditMode}>
                      Edit document
                    </Button>
                    )}
                  </div>
                </div>
              </div>
              )
            }
              </div>
            </form>
          </div></>)}
      <PhoneMenu />
    </section>
  );
};

export default EducationDocument;

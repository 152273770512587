// FormSelectWrapper.tsx

import React from 'react';
import FormSelect from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormSelect';
import styles from './OnboardingSlider.module.css';
import { SelectChangeEvent } from '@mui/material';

interface FormSelectWrapperProps {
  label: string;
  name: string;
  inputlabel: string;
  value: string | undefined; // Accepts string or undefined
  error: string | undefined; // Accepts string or undefined
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string>) => void;
  options: { value: string; label: string }[];
}

const FormSelectWrapper: React.FC<FormSelectWrapperProps> = ({ label, name, inputlabel, value, error, handleChange, options }) => {
  const labelId = `${name}-label`; // Generate a unique label id

  return (
    <div>
      <label id={labelId} className={styles.head}>{label}</label>
      <FormSelect
        label={inputlabel}
        name={name}
        value={value || ''}
        error={error || ''}
        handleChange={handleChange}
        options={options}
        aria-labelledby={labelId} // Use aria-labelledby for accessibility
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default FormSelectWrapper;

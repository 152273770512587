import styles from '../../css/layout.module.css'

import Navbar from '../../components/CandidateSecion/Global/Navbar/VerticalNavbar';

import Profilebar2 from '../../components/CandidateSecion/Profile/Profiebar2/Profilebar2';

import Doctable from '../../components/CandidateSecion/TableSection/Doctable';

import Blog from '../../components/CandidateSecion/BlogSection/Blog';


export default function Layout2() {
  return (

    <div className={styles.mDiv}>
        <div>
            <Navbar/>
        </div>
        <div className={styles.secondDiv}>

          <Profilebar2 />

        

          <Doctable thead1='Documents' thead2='Status' thead3='Type' thead4='Action' progressLayout={false} />

          <Blog />
         
          

        </div>

       
    </div>
    
  );
}
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { MdEmail, MdVisibility, MdVisibilityOff, MdLock } from 'react-icons/md';
import styles from './AdminLogin.module.css';
import logo from 'project-name/assets/img/admin/logo/logo.png';
import { AuthContext } from 'project-name/components/AuthContext/AuthContext';
import apiClient from 'project-name/api/apiClient';
const AdminLogin: React.FC = () => {
  // const AuthContext = React.createContext({
  //   setAuthenticated: (auth: boolean) => { },
  //   setUserName: (name: string) => { }
  // });

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  // const { setAuthenticated, setUserName } = useContext(AuthContext);
  const authContext = useContext(AuthContext);


  const navigate = useNavigate();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    let isValid = true;
    if (!email) {
      setEmailError('Email is required');
      isValid = false;
    } else {
      setEmailError('');
    }
    if (!password) {
      setPasswordError('Password is required');
      isValid = false;
    } else {
      setPasswordError('');
    }
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);

        const response = await apiClient.post(`api/sign-in`, formData, {
          withCredentials: true,
        });

        if (response.status === 200) {
          const data = response.data;
          if (data) {
            console.log(data.user_name)


            authContext?.setAuthenticated(true);
            // authContext?.setUserName(data.user_name);
            localStorage.setItem('userName', data.user_name);

            if (data.persona === "admin") {
              navigate('/admin-home')
            } else
              navigate('/');
          } else {
            setApiError('Invalid response from server');
          }
        } else {
          setApiError(response.data.error || 'Invalid username or password');
        }
      } catch (error: any) {
        if (error.response && error.response.data) {
          setApiError(error.response.data.error || 'An error occurred during login');
        } else {
          setApiError('An error occurred during login');
        }
      }
    }
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.logoDiv}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.maincontainer}>
        <div className={styles.subContainer}>
          <div>
            <Typography variant="h4" className={styles.head}>
              Sign in
            </Typography>
            <Typography className={styles.para}>
              Please sign in to your account to access the portal
            </Typography>
          </div>
          <div className={styles.formInput1}>
            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label htmlFor="email">Email address</label>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={styles.formControl}
                  placeholder="Enter email"
                  error={!!emailError}
                  helperText={emailError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdEmail />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="password">Password</label>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={styles.formControl}
                  placeholder="Enter password"
                  error={!!passwordError}
                  helperText={passwordError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdLock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handlePasswordVisibility} edge="end">
                          {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <p className={styles.forgotPassword}>
                <Link to="/forgot-password" className={styles.link}>
                  Forgot password?
                </Link>
              </p>

              {apiError && (
                <Typography variant="body2" className={styles.apiError} data-testid="api-error">
                  {apiError}
                </Typography>
              )}

              <div className={styles.btn}>
                <Button type="submit" variant="contained" data-testid="signin-button">
                  Sign in
                </Button>
              </div>
            </form>
          </div>
          <div className={styles.helpsec}>
            <MdEmail className={styles.emaiIcon} />
            <Typography variant="body2">
              <Link to="/need-help" className={styles.help}>
                Need help?
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

import React from 'react';
// import styles from '../components/CandidateSecion/TableSection/Doctable.module.css';
import styles from "../TableSection/doctablePhoneScreen.module.css"


interface DocTablePhoneScreenProps {
  isError: boolean;
  statusList: {
    name: string;
    status: string;
    type?: string;
  }[];
}

const DocTablePhoneScreen: React.FC<DocTablePhoneScreenProps> = ({ isError, statusList }) => {
  return (
    <>
    <div>
   
    </div>
    <div className={styles['documents-list-rows-container']}>
      {!isError && statusList.length !== 0 && (
        <div className={styles['documents-list-rows-container']}>
          {statusList.map((doc) => (
            <div className={styles['documents-list-rows']} key={doc.name}>
              <p>{doc.name}</p>
              <p>{doc.status === 'Completed' ? 'Completed' : 'Submit'}</p>
            </div>
          ))}
        </div>
      )}
      {isError && <div>Error: Unable to load data</div>}
    </div>
    </>
  );
};


export default DocTablePhoneScreen;

import Ticket from 'project-name/components/AdminSection/Global/TicketSection/Ticket'
import React from 'react'

const ticketsLayout:React.FC = () => {
  return (
    <div>
        <Ticket/>
      
    </div>
  )
}

export default ticketsLayout

import React from 'react';
import { Container, Typography, Button, Card, CardContent, CardActions } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const NeedHelpPage = () => {
  const handleContactSupport = () => {
    // Replace with the actual action, e.g., navigating to a contact form or sending an email
    alert('Contact support action triggered');
  };

  const handleFAQClick = () => {
    // Replace with the actual action, e.g., navigating to a FAQ page
    alert('Redirecting to FAQs');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
            Need Help? <HelpOutlineIcon fontSize="large" />
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or need assistance, we are here to help! Check out our Frequently Asked Questions (FAQ) or contact our support team for further assistance.
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFAQClick}
            sx={{ mr: 2, textTransform: 'capitalize' }}
          >
            View FAQs
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleContactSupport}
            sx={{ textTransform: 'capitalize' }}
          >
            Contact Support
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default NeedHelpPage;

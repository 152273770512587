import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'project-name/api/apiClient';
export interface Ticket {
  id: number;
  ticketType: string;
  dateRaised: string;
  supportStatus: string;
  user_id: number;
  profileImgsrc: string;
  status_type:string;
  subject:string
}

interface TicketState {
  tickets: Ticket[];
  loading: boolean;
  error: string | null;
}
  const initialState: TicketState = {
    tickets: [],
    loading: false,
    error: null,
  };
  export const fetchTicketsRaised = createAsyncThunk(
    'tickets/fetchTicketsRaised',
    async (_, { rejectWithValue }) => {
      try {
        const response = await apiClient.get('/api/admin/tickets_raised',{
            withCredentials: true,
        });
        return response.data;
      } catch (err: any) {
        return rejectWithValue(err.response?.data || 'Something went wrong');
      }
    }
  );
  const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchTicketsRaised.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchTicketsRaised.fulfilled, (state, action) => {
          state.loading = false;
          state.tickets = action.payload;
        })
        .addCase(fetchTicketsRaised.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
    },
  });
  
  export default ticketsSlice.reducer;
import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './SavedProfiles.module.css';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid #FFF',
  background: 'rgba(255, 255, 255, 0.95)',
  boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
  backdropFilter: 'blur(10px)',
};

interface OnboardingModalProps {
  open: boolean;
  status: string;
  onClose: () => void;
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ open, status, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" data-testid="onboarding-modal">
      <Box sx={style}>
        <div className={styles.cancelIcon} onClick={onClose} data-testid="modal-close-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M8.81836 16.1821L16.184 8.81641" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M8.81836 8.81641L16.184 16.1821" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </div>
        <div className={styles.statusIcon}>
          {status === "succeeded" ? (
            <>
          <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
            <circle cx="26.3564" cy="26.3564" r="26.3564" fill="#1DA223" />
            <path d="M22.7455 37.283C22.1939 37.283 21.6699 37.0623 21.2838 36.6762L13.4788 28.8713C12.679 28.0715 12.679 26.7477 13.4788 25.9479C14.2785 25.1481 15.6023 25.1481 16.4021 25.9479L22.7455 32.2911L36.9211 18.1155C37.7209 17.3157 39.0447 17.3157 39.8445 18.1155C40.6443 18.9153 40.6443 20.2391 39.8445 21.0389L24.2072 36.6762C23.8211 37.0623 23.2971 37.283 22.7455 37.283Z" fill="white" />
          </svg>
              <h4>Onboarding Started!</h4>
              <p>Onboarding process for the candidate has begun! You can find the profiles under all profiles category.</p>
            </>
          ) : (
            <>
             <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
            <circle cx="26.3564" cy="26.3564" r="26.3564" fill="#1DA223" />
            <path d="M22.7455 37.283C22.1939 37.283 21.6699 37.0623 21.2838 36.6762L13.4788 28.8713C12.679 28.0715 12.679 26.7477 13.4788 25.9479C14.2785 25.1481 15.6023 25.1481 16.4021 25.9479L22.7455 32.2911L36.9211 18.1155C37.7209 17.3157 39.0447 17.3157 39.8445 18.1155C40.6443 18.9153 40.6443 20.2391 39.8445 21.0389L24.2072 36.6762C23.8211 37.0623 23.2971 37.283 22.7455 37.283Z" fill="white" />
          </svg>
              <h4>Onboarding Failed!</h4>
              <p>Onboarding process for the candidate has failed! Try again.</p>
            </>
          )}

        </div>
      </Box>
    </Modal>
  );
};

export default OnboardingModal;

import React, { useState, FormEvent } from 'react';
import styles from './Documents.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import Modal from '@mui/material/Modal';
import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';

import FileInput from './GlobalFormsField/FileInput';

import { handleDownload } from 'project-name/util/util';
import apiClient from 'project-name/api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import { setPdfData } from 'project-name/Redux/features/Candidate';

interface FileInputData {
  confidentialFile?: File | null;
}

const AcknowledgeDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();
  let pdfData = useSelector((state: RootState) => state.candidate.pdfData);
  const fetchFiles = async () => {
    try {
      const response = await apiClient.get(`/api/get_files`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch candidate documents: ${response.status}`);
      }
      const data = response.data;
      if (data) {
      pdfData = data;
       // localStorage.setItem("pdfData", JSON.stringify(data));
      dispatch(setPdfData(data));
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }

  if(pdfData && Object.keys(pdfData).length === 0){
    fetchFiles();
  }
  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  // Example form submission handling
  const handleSubmit = async (e: FormEvent, formType: string) => {
    e.preventDefault();

    const formData = new FormData();
    if(formType === 'Confidentiality') {
      if (ConfidentialityFileInput.confidentialFile) {
      formData.append('document', ConfidentialityFileInput.confidentialFile);
      formData.append('document_type', 'c_u_f');
      }
    } 

    // Example POST request
    try {
      const response = await apiClient.post(
        `/api/candidate/upload-documents`,
        formData,
        {
          withCredentials: true,
        }
      );
    
      if (response.status !== 200) {
        throw new Error('File upload failed');
      }
    
      const result = response.data;
      console.log('File uploaded successfully:', result);
      setShowModal(true);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };


  const [ConfidentialityFileInput, setConfidentialityFileInput] = useState<FileInputData>({
    confidentialFile: null,
  });


  // Function to handle file input change
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'confidentialityInput':
          setConfidentialityFileInput((prevData) => ({
            ...prevData,
            confidentialFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        default:
          break;
      }
    }
  };


  // submit popup
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section className={styles.mainAccord}>
      {/* Modal */}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SubmitPopup handleClose={handleCloseModal} />
        </Modal>
      )}
      {/* first accordion  */}
      <div className={styles.subAccord}>
        <button
          className={`${styles.accordion} ${activeIndex === 0 ? styles.active : ''}`}
          onClick={() => toggleAccordion(0)}
          aria-expanded={activeIndex === 0}
        >
          <p>Confidentiality Undertaking Form</p>
          <b className={styles.statusDiv}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
              <circle cx="11.4545" cy="11.4545" r="11.4545" fill="#026547" />
              <path
                d="M6.36328 11.7486L9.62316 14.9727C9.78759 15.1353 10.0553 15.125 10.2067 14.9502L16.5451 7.63672"
                stroke="white"
                strokeWidth="1.3"
                strokeLinecap="round"
              />
            </svg>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Please upload Confidentiality Undertaking form. In case you don't have a current Confidentiality Undertaking Form, then download a template from link below and upload the same after filling the details.</p>
                <div className={styles.btnDivack}>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('auth', pdfData?.LOA?.["Ab_InBev_Letter of Authorization.pdf"] ?? '')}>
                    Download Acknowledgement Form
                  </Button>
                </div>
              </div>
              <div>


                <FileInput
                  label="Upload filled Confidentiality Undertaking Form"
                  file={ConfidentialityFileInput.confidentialFile}
                  onChange={handleFileInputChange}
                  id="confidentialityInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn}>
                  <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'Confidentiality')}>
                    Submit document
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AcknowledgeDetails;

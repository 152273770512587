// OnboardingStatus.tsx
import React, { useState, ChangeEvent } from 'react';
import styles from './OnboardingSlider.module.css';
import FormSelect from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormSelect';
import GlobalButton from './GlobalButton';
import { SelectChangeEvent } from '@mui/material';

interface FormData {
  form_data: string;
}

const OnboardingStatus = () => {
  const [formData, setFormData] = useState<FormData>({
    form_data: '',
  });

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({
    form_data: '',
  });

  const validateField = (name: keyof FormData, value: string) => {
    return value.trim() ? '' : 'This field is required';
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | SelectChangeEvent<string>
  ) => {
    if ('target' in event) {
      const { name, value } = event.target as { name: string; value: string };
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const confirmOption = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  return (
    <div>
      <div className={styles.candidateAccessDiv}>
        <div className={styles.onboardStatusDiv}>
          <div>
            <p className={styles.head}>Candidate onboarding status</p>
            <FormSelect
              label="Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s."
              name="form_data"
              inputlabel="Full time"
              value={formData.form_data}
              error={formErrors.form_data}
              handleChange={handleChange}
              options={confirmOption}
            />
          </div>
          <div></div>
        </div>
      </div>
      <GlobalButton />
    </div>
  );
};

export default OnboardingStatus;

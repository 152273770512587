import React , {useEffect} from 'react'
import CandidateProfileSection from 'project-name/components/AdminSection/CandiateSection/CandidateProfileSection/CandidateProfileSection'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  AppDispatch } from 'project-name/Redux/Store';
  import { fetchCandidateDocuments, fetchCandidateForm } from 'project-name/Redux/features/candidateDocumentSlice';
const candidateProfile: React.FC = () => {
  const location = useLocation();
  console.log(location.state)
  const state = location.state
  const user_id = state.user_id
 localStorage.setItem("user_id", user_id)
  
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
   
    dispatch(fetchCandidateDocuments(user_id));
    dispatch(fetchCandidateForm(user_id));
   
  }, []);
  return (
    <div>
        <CandidateProfileSection/>
      
    </div>
  )
}

export default candidateProfile

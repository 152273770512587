import React from "react";

const InprogressIcon = () => {

    return (
        <>
         <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
              <g clipPath="url(#clip0_701_10798)">
                <path
                  d="M13.9987 26.0306C7.58203 26.0306 2.33203 20.7806 2.33203 14.3639C2.33203 7.94727 7.58203 2.69727 13.9987 2.69727C20.4154 2.69727 25.6654 7.94727 25.6654 14.3639C25.6654 20.7806 20.4154 26.0306 13.9987 26.0306ZM13.9987 5.0306C8.86537 5.0306 4.66536 9.2306 4.66536 14.3639C4.66536 19.4973 8.86537 23.6973 13.9987 23.6973C19.132 23.6973 23.332 19.4973 23.332 14.3639C23.332 9.2306 19.132 5.0306 13.9987 5.0306Z"
                  fill="#745500"
                />
                <path
                  d="M19.7154 15.5304H13.9987C13.2987 15.5304 12.832 15.0638 12.832 14.3638V8.64714C12.832 7.94714 13.2987 7.48047 13.9987 7.48047C14.6987 7.48047 15.1654 7.94714 15.1654 8.64714V13.1971H19.7154C20.4154 13.1971 20.882 13.6638 20.882 14.3638C20.882 15.0638 20.4154 15.5304 19.7154 15.5304Z"
                  fill="#745500"
                />
              </g>
              <defs>
                <clipPath id="clip0_701_10798">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
        </>
    )

}
    export default InprogressIcon;
import React from 'react';
import styles from './Blog.module.css'; // Import styles from separate file
import blog1 from '../../../assets/img/blog/blog1.png';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import blog2 from '../../../assets/img/blog/blog2.png';
import blog3 from '../../../assets/img/blog/blog3.png';


interface ImageBox {
  imageUrl: string;
  description: string;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 200,
  arrows:false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // Set the autoplay speed
};

const imageBoxes: ImageBox[] = [
  {
    imageUrl: blog1,
    description: 'Lorem Ipsum is simply dummy text of the industry standard when ...',
  },
  {
    imageUrl: blog2,
    description: 'Lorem Ipsum is simply dummy text of the industry standard when ...',
  },
  {
    imageUrl: blog3,
    description: 'Lorem Ipsum is simply dummy text of the industry standard when ...',
  },
];

const Blog: React.FC = () => {
  return (
    <>
      <h2 className={styles.smallHeading}>Know what’s trending at ABI</h2>
      <div className={styles.mainBlog}>
        <h2 className={styles.largeHeading}>Know what’s trending at ABI</h2>
        <div className={styles.blogContainer}>
          {imageBoxes.map((imageBox, index) => (
            <div key={index} className={styles.imageBox}>
              <img src={imageBox.imageUrl} alt={`Image ${index + 1}`} className={styles.blogImages} />
              <p className={styles.description}>{imageBox.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.secondBlog}  >
        <Slider {...settings} className={styles.slider} >
          {imageBoxes.map((imageBox, index) => (
            <div key={index} className=''>
              <img src={imageBox.imageUrl} alt="" width='280px'/>
              <p className={styles.desc}>{imageBox.description}</p>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Blog;

import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import styles from './Notification.module.css';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import bell from 'project-name/assets/img/bell.png';
import CancelIcon from '@mui/icons-material/Cancel';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Link } from 'react-router-dom';

// import { useAuth } from 'project-name/components/AuthContext/AuthContext'; // Adjust the import path

import apiClient from 'project-name/api/apiClient';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  padding: '32px 40px',
  borderRadius: '8px',
  border: '1px solid #FFF',
  background: 'rgba(255, 255, 255, 0.92)',
  boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
  backdropFilter: 'blur(30px)',
};

interface Notification {
  id: number;
  title: string;
  status: string;
  created_at: string;
  description: string;
  user_id: number;
  updated_at: string | null;
}

interface NotificationProps {
  pos?: boolean;
}

const NotificationComponent: React.FC<NotificationProps> = ({ pos }) => {
  // const { accessToken } = useAuth(); // Use the accessToken from AuthContext
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showNotificationCount, setShowNotificationCount] = useState(true);

  useEffect(() => {
    apiClient.get(`/api/notifications`, {
      withCredentials: true
    })
    .then(response => {
      setNotifications(response.data);
    })
    .catch(error => {
      console.error('Error fetching notifications:', error);
    });
  }, []);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
    setShowNotificationCount(false);
  };

  const handleCancelClick = () => {
    setShowNotifications(false);
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Document Rejected':
        return <CancelIcon style={{ color: 'red' }} />;
      case 'Documents pending':
      case 'Deadline approaching':
      case 'Active':
        return <AccessTimeIcon style={{ color: '#000' }} />;
      default:
        return null;
    }
  };

  console.log('notifications:', notifications);

  return (
    <div className={styles.notificationContainer}>
      <div className={pos ? '' : styles.notificationIcon}  onClick={handleNotificationClick}>
        <span className={styles.bell}>
          {notifications.length>0 && ( <img src={bell} alt="Notifications" />) }  
        </span>
        <div className={styles.menuIcon}>
          
        </div>
        {showNotificationCount && notifications.length > 0 && <span className={styles.notificationCount}>+{notifications.length} new</span>}
      </div>
      {showNotifications && (
        <div className={styles.notificationDropdown}>
          <div className={styles.notificationDiv}>
            <div><h4>Notification</h4></div>
            <div className={styles.cancelIcon} onClick={handleCancelClick}>X</div>
          </div>
          {notifications.map(notification => (
            <div key={notification.id} className={styles.notificationMain} onClick={handleOpen}>
              <div className={styles.statusIconDiv}>
                {getStatusIcon(notification.status)}
              </div>
              <div className={styles.notificationItem}>
                <div className={styles.notificationStatus}>
                  <span>{notification.status}</span>
                </div>
                <div className={styles.notificationDescription}>{notification.description}</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Notification modal */}
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div>
                <div className={styles.mainNotDiv}>
                  <div>
                    <span>
                      <NotificationsNoneIcon />
                    </span>
                    <p>Notification</p>
                  </div>
                  <div className={styles.cancelIcon} onClick={handleClose}>X</div>
                </div>

                {notifications.map(notification => (
                  <div className={styles.mainMsgDiv} key={notification.id}>
                    <div className={styles.msgDiv}>
                      <div>
                        <span>{getStatusIcon(notification.status)}</span>
                        <p>{notification.status}</p>
                      </div>
                      <div>
                        <Button color="primary" variant="contained" style={{ marginRight: 10 }} component={Link} to={'#'} className={styles.clearButton}>
                          Clear
                        </Button>
                      </div>
                    </div>
                    <p className={styles.msgDesc}>{notification.description}</p>
                  </div>
                ))}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default NotificationComponent;

import styles from 'project-name/css/layout.module.css';
import VerticalNavbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
import Layer2 from 'project-name/components/CandidateSecion/Documents/Layer2';
import OtherDetails from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/OtherDetailsForm';
import useBeforeUnload from 'project-name/components/hooks/useBeforeUnload';
import DocumentsLayer from 'project-name/components/CandidateSecion/Documents/DocumentsLayer';

const OtherDocLayout = () => {
  useBeforeUnload();
  return (
    <div className={styles.mDiv}>
      <div>
        <VerticalNavbar />
      </div>
      <div className={styles.Layout3Div}>
        <Breadcrumb
          bread1="Home"
          bread2="Documentation"
          bread3="Statutory documents"
          threeDiv={true}
          link1="/"
          link2="/documents"
        />
        <DocumentsLayer title="Statutory documents" />
        <OtherDetails />
      </div>
    </div>
  );
};

export default OtherDocLayout;

import styles from './OnboardingSlider.module.css'
import Checkbox from '@mui/material/Checkbox';


const Activities = () => {
  return (
    <div>
    <div className={styles.candidateAccessDiv}>
      <div>
      <p className={styles.head}>User activity permission </p>
            <p className={styles.para}>Lorem Ipsum is simply dummy text of the printing industry text ever since the 1500s.</p>
            <div>
            <div className={styles.checkInput}><Checkbox  defaultChecked /> <label htmlFor="" className={styles.label}>Retrigger</label></div>
            <div className={styles.checkInput}><Checkbox  defaultChecked /> <label htmlFor="" className={styles.label}>Remind</label></div>
            <div className={styles.checkInput}><Checkbox  defaultChecked /> <label htmlFor="" className={styles.label}>Ask to resubmit</label></div>
            <div className={styles.checkInput}><Checkbox  defaultChecked /> <label htmlFor="" className={styles.label}>Skip this step</label></div>
            <div className={styles.checkInput}><Checkbox  defaultChecked /> <label htmlFor="" className={styles.label}>Reschedule</label></div>
            </div>

      </div>

    </div>
  </div>
  )
}

export default Activities

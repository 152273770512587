import React from 'react'
import styles from 'project-name/css/admin/adminLayout.module.css'
import HorizontalNavbar from "../../Global/Navbar/HorizontalNavbar"
import Breadcrumb from "project-name/components/CandidateSecion/Documents/Breadcrumb"
import RecruiterTable from "project-name/components/AdminSection/Global/RecruiterSection/RecruiterTable"


const RecruiterSection:React.FC = () => {

  const headers = ['Recruiter name', 'Email', 'Candidates', 'Recruiter role', 'Action'];

  return (
    <div className={styles.mDiv}>
        <HorizontalNavbar/>
        <div style={{padding:'40px'}}>
        <Breadcrumb bread1="Home" bread2="Recruiter profile" link1="/admin-home"/>
        </div>
      
        <RecruiterTable headers={headers} />
      
    </div>
  )
}

export default RecruiterSection

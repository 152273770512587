import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

import { documentVariants, mapSelectedToNames } from 'project-name/util/util';

import styles from '../../css/layout.module.css';

import Navbar from '../../components/CandidateSecion/Global/Navbar/VerticalNavbar';

// import Doctable3 from '../components/TableSection/Doctable3/Doctable3';

import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';

import FilterTab from 'project-name/components/CandidateSecion/TableSection/Doctable4/FilterTab';

// import Tabledemo from 'project-name/components/TableSection/Doctable3/Tabledemo';

import Doctable4 from 'project-name/components/CandidateSecion/TableSection/Doctable4/Doctable4';

const Layout4: React.FC = () => {
  const [variantName, setVariantName] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const newValue = Array.isArray(value) ? value : [value];
    setVariantName(newValue);
  };

  return (
    <div className={styles.mDiv}>
      <div>
        <Navbar />
      </div>
      <div className={styles.secondDiv}>
        <div style={{ margin: '40px 100px' }}>
          {' '}
          <Breadcrumb bread1="Home" bread2="Documentation" link1="/" />
        </div>

        <FilterTab selected={variantName} onChange={handleChange} variants={documentVariants} label="Documents" filterHead="Documents Checklist" />

        {/* <Doctable3 thead1="Documents" thead2="Type" thead3="Size limit" thead4="Status" thead5="Action" /> */}
        <Doctable4 thead1="Documents" thead2="Type" thead3="Deadline" thead4="Status" thead5="Action"  variants={variantName}/>
        

      </div>
    </div>
  );
};

export default Layout4;

// candidateSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Status {
  name: string;
  status: string;
  type: string | null;
  error?: string;
}

interface StatusState {
    statusList: Status[];
    percentage: number;
    deadline?: string ;
}

const initialState: StatusState = {
    statusList: [],
    percentage: 0,
    deadline: '',
    
};

const StatusSlice = createSlice({
    name: 'StatusList',
    initialState,
    reducers: {
        setStatusList: (state, action: PayloadAction<StatusState>) => {
            state.statusList = action.payload.statusList;
            state.percentage = action.payload.percentage;
            state.deadline = action.payload.deadline;
        },
    },
});

export const { setStatusList } = StatusSlice.actions;
export default StatusSlice.reducer;
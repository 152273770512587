import React, {useState} from 'react';
import HorizontalNavbar from '../Navbar/HorizontalNavbar';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
// import TicketFilter from './TicketFilter';

import FilterTab from 'project-name/components/CandidateSecion/TableSection/Doctable4/FilterTab';

import styles from 'project-name/css/admin/adminLayout.module.css';
import TicketTable from './TicketTable';
import { SelectChangeEvent } from '@mui/material/Select';
import { ticketVariants } from 'project-name/util/util';

const Ticket: React.FC = () => {
  const [variantName, setVariantName] = useState<string[]>([ ]);

  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;
    
    const newValue = Array.isArray(value) ? value : [value];
    setVariantName(newValue);
  };

  return (
    <div>
      <div className={styles.mDiv}>
        <HorizontalNavbar />
        <Breadcrumb bread1="Home" bread2="Tickets" link1="/admin-home" />
        {/* <TicketFilter /> */}
        <FilterTab selected={variantName} onChange={handleChange} variants={ticketVariants} label="Status" filterHead="Tickets raised" />

        <TicketTable variants={variantName} />
        
      </div>
    </div>
  );
};

export default Ticket;

import React, { useEffect, useRef, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { OpenFile } from '@react-pdf-viewer/core';

interface Props {
  onClose: () => void;
  base64: string; // Pass the base64 string for the PDF
  fileName: string; // Pass the file name for the PDF
}

const PDFview: React.FC<Props> = ({ onClose, base64, fileName }) => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(true); // Track loading status

  useEffect(() => {
    if (base64) {
      setLoading(false); // Set loading to false when PDF is loaded
    }
  }, [base64]);

  const pdfjsVersion = '3.4.120';
  const workerUrl = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      // `file.name` is the URL of opened file]
      return fileName;
  },
  });

  return (
    <>
      <div className="viewSourcePopup">
        <div className="viewSourcePopupContentpdf">
          <div ref={popupRef} className="DataSourcepdf">
            {loading ? ( // Display loading message while loading
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Loading PDF...
              </div>
            ) : (
              <Worker workerUrl={workerUrl}>
                <div
                  className="rpv-core__viewer"
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                      display: 'flex',
                      padding: '4px',
                    }}
                  >
                    <getFilePluginInstance.DownloadButton />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <Viewer fileUrl={`data:application/pdf;base64,${base64}`} plugins={[getFilePluginInstance]} />
                  </div>
                </div>
              </Worker>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFview;

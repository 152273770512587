import React, { useState, useEffect } from 'react';
import styles from './SavedProfiles.module.css'; // Import your CSS module here
import profilesData from '../../../../../Data/admin/savedProfiles.json'; // Import your JSON data file here
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'project-name/Redux/Store';
import { RootState } from 'project-name/Redux/Store';
import OnboardingModal from './OnboardingModal';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Pagination from '../RecruiterSection/Pagination';
import { updateCandidateBgvStatus } from 'project-name/Redux/features/updatebgvStatusSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

interface SavedProfile {
    full_name: string;
    tower: string;
    office_location: string;
    d_o_j: string;
    user_id: number;
    bgv_status: string
    bgv_initiated:string
}

const SavedProfilesSection: React.FC = () => {

    const dispatch: AppDispatch = useDispatch()
    const status = useSelector((state: RootState) => state.user.status);
   
    const [confirmOpen, setConfirmOpen] = useState(false);
   
    const [userId, setUserId] = useState<number | null>(null);
    let statusList = useSelector((state: RootState) => state.adminProfile.profile);
    const responseStatusCode = useSelector((state: RootState) => state.user.status);
    const [savedProfiles, setSavedProfiles] = useState<SavedProfile[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [open, setOpen] = useState(false);
    const itemsPerPage: number = 5;

    useEffect(() => {
        if (statusList && statusList.length > 0) {
            setSavedProfiles(statusList);
        }
    }, [statusList]);

    // Calculate total number of pages
    const totalPages: number = Math.ceil(savedProfiles.length / itemsPerPage);

    // Function to get paginated profiles for the current page
    const getCurrentPageProfiles = (): SavedProfile[] => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return savedProfiles.slice(startIndex, endIndex);
    };

    // Function to handle page change
    const handlePageChange = (page: number): void => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Effect to scroll to the top of the page when page changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    const handleOpenConfirmation = (user_id: number) => {
        setConfirmOpen(true);
        setUserId(user_id);
    };

    const handleConfirmYes = () => {
        setConfirmOpen(false);
        handleOnboarding();
    };

    const handleOnboarding = () => {
        if (userId) {
            dispatch(updateCandidateBgvStatus({ user_id: userId }))
                .then(() => {
                    const updatedProfiles = savedProfiles.map((profile) =>
                        profile.user_id === userId ? { ...profile, bgv_status: 'Initiated' } : profile
                    );
                    setSavedProfiles(updatedProfiles);
toast.success('Onboarding started successfully');
                    
                })
                .catch(() => {
                  
                   
                    toast.error('Failed to start onboarding. Please try again.');
                })
                .finally(() => {
                    setOpen(true);
                });
        }
    };

    const handleConfirmNo = () => {
        setConfirmOpen(false);
    };

    const handleClose = () => setOpen(false);

    return (
        <section data-testid="profile-name">
            <div className={styles.savedProfilesContainer} style={{ padding: '0px 40px 20px 40px' }} data-testid="pagination-component">
                <div className={styles.savedProfilesHead}>
                    <h4>Saved profiles</h4>
                </div>
                <div className={styles.savedProfilesListContainer}>
                    <div className={styles.savedProfilesListTitle}>
                        <p>Name</p>
                        <p>Tower</p>
                        <p>Location</p>
                        <p>Joining date</p>
                        <p>Action</p>
                    </div>
                    {getCurrentPageProfiles().map((profile, index) => (
                        <div className={styles.savedProfilesList} key={index}>
                            <p>{profile.full_name}</p>
                            <p>{profile.tower}</p>
                            <p>{profile.office_location}</p>
                            <p>{new Date(profile.d_o_j).toLocaleDateString()}</p>
                            {profile.bgv_initiated === "No" && !profile.bgv_status ? (
                                <a onClick={() => handleOpenConfirmation(profile.user_id)}>Start onboarding</a>
                            ) : (
                                <p
                                    style={{
                                        color: profile.bgv_status === "Pending" ? "black" : "white",
                                        backgroundColor: profile.bgv_status === "Rejected" ? "red" :
                                            profile.bgv_status === "InProgress" ? "green" :
                                                profile.bgv_status === "Initiated" ? "#007FFF" : "#FFE862",
                                        fontWeight: "bold",
                                        padding: "7px 10px",
                                        borderRadius: "5px",
                                    }}
                                >
                                        {profile.bgv_status}
                                </p>
                            )}
                        </div>
                    ))}
                    <Dialog open={confirmOpen} onClose={handleConfirmNo}>
                        <DialogTitle>Confirm Start Onboarding</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to start onboarding?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmNo} color="primary">
                                No
                            </Button>
                            <Button onClick={handleConfirmYes} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Toast Notification */}
                   
                </div>
                <div className={styles.ListPaginationBox}>
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
            </div>

            <OnboardingModal open={open} status={status} onClose={handleClose} />
        </section>
    );
};

export default SavedProfilesSection;
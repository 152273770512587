import { useState, useEffect } from 'react';
import styles from '../../css/layout.module.css';
import Navbar from '../../components/CandidateSecion/Global/Navbar/VerticalNavbar';
import Profilebar from '../../components/CandidateSecion/Profile/Profilebar/Profilebar';
import ProfileDesc from '../../components/CandidateSecion/Profile/ProfileDesc/ProfileDesc';
import Doctable from '../../components/CandidateSecion/TableSection/Doctable';
import Blog from '../../components/CandidateSecion/BlogSection/Blog';
import AkPopUp from 'project-name/components/CandidateSecion/Global/Popup/AKPopUp/AKPopUp';
import { useSelector } from 'react-redux';
import PhoneMenu from 'project-name/components/CandidateSecion/Global/Navbar/phoneMenu';


import { RootState } from 'project-name/Redux/Store';


export default function Layout() {
 

  
  

  
  const [isModalOpen, setIsModalOpen] = useState(false);

const candidate = useSelector((state:RootState) => state.candidate.candidate);

const pdfData = useSelector((state:RootState)=>state.candidate.pdfData);
  useEffect(() => {
    // Check if the modal has been displayed before by checking localStorage
    if(candidate?.is_acknowledged === "No" && pdfData && Object.keys(pdfData).length !== 0){ 
      setIsModalOpen(true);
    }
    
  }, [candidate,pdfData]); // This effect runs only once after the component mounts

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      {isModalOpen && <AkPopUp onClose={handleCloseModal} />}
      <div className={styles.mDiv}>
        <div>
          <Navbar />
        </div>
        <div className={styles.secondDiv}>
          <Profilebar />
          <ProfileDesc />
          <Doctable thead1="Documents" thead2="Status" thead3="Type" thead4="Action" progressLayout={true} />
          <Blog />

        </div>
        {/* <div className={styles.bottomMenu}>
      {icons.map((item, index) => (
        <div
          key={index}
          className={`${styles.iconContainer} ${activeIndex === index ? styles.active : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index)}
        >
          {item.icon}
          {(hoveredIndex === index || activeIndex === index) && (
            <p className={styles.iconText}>{item.text}</p>
          )}
        </div>
      ))}
    </div> */}
    <PhoneMenu/>
      </div>
    </>
  );
}
// 
import React from 'react'
import RecruiterProfileSection from 'project-name/components/AdminSection/Global/RecruiterSection/RecruiterProfileSection'

const recruiterProfile: React.FC = () => {
  return (
    <div>
        <RecruiterProfileSection/>
    </div>
  )
}

export default recruiterProfile

import React  from 'react';
import HorizontalNavbar from '../../Global/Navbar/HorizontalNavbar';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
import Onboarding from 'project-name/components/AdminSection/CandiateSection/CandidateProfileSection/OnboardingSlider/OnboardingSlider';
import styles from 'project-name/css/admin/adminLayout.module.css';


const CandidateProfileSection: React.FC = () => {
  
  return (
    <div className={styles.mDiv}>
      <HorizontalNavbar />
      <div style={{ padding: '40px' }}>
        {' '}
        <Breadcrumb
          bread1="Home"
          bread2="Candidate profile"
          bread3="Candidate"
          link1="/admin-home"
          link2="/candidate"
          threeDiv={true}
        />
      </div>

      <Onboarding />
    </div>
  );
};

export default CandidateProfileSection;

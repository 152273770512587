import { configureStore } from '@reduxjs/toolkit';
import CandidateReducer from './features/Candidate';
import EducationReducer from './features/Education';
import StatusReducer from './features/DocumentStatus'
import OtherStatusReducer from './features/OtherCompletedDocuments'
import adminProfileReducer from "./features/adminProfileSlice"
import candidateDocumentReducer from "./features/candidateDocumentSlice"
import profileReducer from "./features/candidateDetailsSlice"
import userReducer from "./features/updatebgvStatusSlice"
import ticketsReducer from "./features/adminTicketSlice"
import candidateReducer from "./features/createCandidateSlice"
import updateCandidateReducer from "./features/updateCandidateSlice"
import passwordReducer from "./features/forgetPasswordSlice"
const store = configureStore({
    reducer: {
    candidate:CandidateReducer,
    education: EducationReducer,
    status: StatusReducer,
    OtherStatus: OtherStatusReducer,
    adminProfile: adminProfileReducer,
    candidateDocuments:candidateDocumentReducer,
    profile: profileReducer,
    user: userReducer,
    tickets: ticketsReducer,
    createCandidate:candidateReducer,
    updateCandidate:updateCandidateReducer,
    forgetPassword:passwordReducer


    
    }
});

export default store;    
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
import React, { useEffect, useState } from 'react';
import styles from '../OnboardingSlider.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';

import doc from 'project-name/assets/img/admin/docs/doc1.png';

// import FormSelect from './FormSelect';

import FormField from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormField';
import FormSelect from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FormSelect';
import Attachment from './Attachment';

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import PersonalDetailsForm from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/PersonalDetailsForm';
import AddressDetailForm from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/AddressDetailForm';
import BankForm from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/BankForm/BankForm';
import WorkExperienceForm from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/WorkExperienceForm/WorkExperienceForm';
import EducationDocument from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/EducationForm';


const CandidateForms: React.FC = () => {
  
  const candidateData = useSelector((state: RootState) => state.candidateDocuments.candidateDetails);
  const [formData, setFormData] = useState<any>({
      "title": "",
      "first_name": "",
      "middle_name": "",
      "last_name": "",
      "full_name": "",
      "gender": "",
      "shirt_size": "",
      "fav_beverage": "",
      "phone_no": "",
      "alt_no": null,
      "p_o_b_city": "",
      "p_o_b_country": "",
      "religion": "",
      "nationality": "",
      "known_language": "",
      "relationship_status": "",
      "spouses_first_name": null,
      "spouses_middle_name": null,
      "spouses_last_name": null,
      "spouses_d_o_b": null,
      "spouses_address": null,
      "spouses_contact_no": null,
      "em_contact_name": "",
      "em_contact_relation": "",
      "em_country_code": "",
      "em_contact_no": "",
      "blood_group": "",
      "company_work_relation": "",
      "aadhaar_no": "",
      "aadhaar_can_doc_id": "",
      "aadhaar_name": "",
      "pan_no": "",
      "pan_can_doc_id": "",
      "vaccine_status": null,
      "vacc_can_doc_id": null,
      "d_o_b": null,
      "user_id": "",
      "created_at": null,
      "updated_at": null,
  });
  const [addressData, setAddressData] = useState<any>({
    "id": "",
    "cur_address": "",
    "banglore_address": "",
    "per_address": "",
    "cur_city": "",
    "cur_state": "",
    "cur_country": "",
    "cur_pin": "",
    "banglore_city": "",
    "banglore_state": "",
    "banglore_country": "",
    "banglore_pin": "",
    "per_city": "",
    "per_state": "",
    "per_country": "",
    "per_pin": "",
    "is_passport": "",
    "passport_issue_date": "",
    "passport_exp_date": "",
    "passport_issuance_place": "",
    "doc_id": "",
    "user_id": "",
    "created_at": "",
    "updated_at": null
});
const [bankDetails, setBankDetails] = useState<any>({
    "id": "",
    "bank_name": "",
    "ifsc_code": "",
    "account_no": "",
    "account_holder_name": "",
    "is_epf": "",
    "epf_scheme_1952": "",
    "epf_scheme_1995": "",
    "doc_id": "",
    "user_id": "",
    "created_at": "",
    "updated_at": null

});

const [tenthEducationDetails, setTenthEducationDetails] = useState<any>({
  degree_type: '',
    institution_name: '',
    roll_no: '',
    major_specialization: '',
    board: '',
    country: '',
    state: '',
    city: '',
});

const [twelfthEducationDetails, setTwelfthEducationDetails] = useState<any>({
  degree_type: '',
  institution_name: '',
  roll_no: '',
  major_specialization: '',
  board: '',
  country: '',
  state: '',
  city: '',
});

const [graduationEducationDetails, setGraduationEducationDetails] = useState<any>({
  degree_name: '',
  institution_name: '',
  // roll_no: '',
  degree_type: '',
  major_specialization: '',
  start_date: dayjs(),
  completion_date: dayjs(),
  country: '',
  state: '',
  city: '',
  type: '',

});

const [postGraduationEducationDetails, setPostGraduationEducationDetails] = useState<any>({
  degree_name: '',
  institution_name: '',
  // roll_no: '',
  degree_type: '',
  major_specialization: '',
  start_date: dayjs(),
  completion_date: dayjs(),
  country: '',
  state: '',
  city: '',
  type: '',

})


const [educationDetails, setEducationDetails] = useState<any>({
  "id": "",
  "education_type": "",
  "degree_type": "",
  "institution_name": "",
  "roll_no": "",
  "start_date": "",
  "completion_date": "",
  "major_specialization": "",
  "board": "",
  "country": "",
  "state": "",
  "city": "",
  "degree_name": "",
  "type": "",
  "course_status": "",
  "certification_exp_date": "",
  "certification_name": "",
  "certification_institute": "",
  "certification_details": null,
  "certification_completion_date": "",
  "user_id": "",
  "mark_doc_id": null,
  "cert_doc_id": null,
  "created_at": null,
  "updated_at": null
});
const [workDetails, setWorkDetails] = useState<any>({
  "id": "",
  "is_fresher": "",
  "organization_name": "",
  "specify_organization": "",
  "currently_working": "",
  "start_date": "",
  "end_date": "",
  "tenure": "",
  "reason_for_leaving": null,
  "street_address": "",
  "city": "",
  "state": "",
  "country": "",
  "pincode": "",
  "company_contact_number": "",
  "employee_id": "",
  "designation": "",
  "supervisor_name": "",
  "supervisor_phone_number": "",
  "supervisor_email": "",
  "hr_name": "",
  "hr_consent": "",
  "hr_phone_number": "",
  "hr_email": "",
  "gap_details": null,
  "doc_id": null,
  "user_id": "",
  "created_at": "",
  "updated_at": null
});

  const [formErrors, setFormErrors] = useState<any>({
    designation: '',
    first_name: '',
    last_name: '',
    full_name: '',
    gender: '',
    religion: '',
    father_name: '',
    recruiter: '',
    emergency_contact_name: '',
    adhaar_card_number: '',
    country_code: '',
    mother_name: '',
    hiring_manager: '',
    emergency_contact_relation_with_candidate: '',
    name_on_adhaar_card: '',
    phone_number:'',
  });

  useEffect(() => {
    if (candidateData) {
      setFormData(candidateData.personal_details?.length > 0 ? candidateData.personal_details[0] : {});
      setAddressData(candidateData.address_details?.length > 0 ? candidateData.address_details[0] : {});
      setBankDetails(candidateData.bank_details?.length > 0 ? candidateData.bank_details[0] : {});
      setWorkDetails(candidateData.work_experience_details?.length > 0 ? candidateData.work_experience_details[0] : {});

      const education_details = candidateData.education_details?.length> 0 ? candidateData.education_details : [];
      const tenthEducation = education_details.filter((education: any) => education.education_type === '10th')[0];
      const twelfthEducation = education_details.filter((education: any) => education.education_type === '12th')[0];
      const graduationEducation = education_details.filter((education: any) => education.education_type === 'Graduation')[0];
      const postGraduationEducation = education_details.filter((education: any) => education.education_type === 'Post Graduation')[0];
      setTenthEducationDetails(tenthEducation);
      setTwelfthEducationDetails(twelfthEducation);
      setGraduationEducationDetails(graduationEducation);
      setPostGraduationEducationDetails(postGraduationEducation);
    }
  }, [candidateData]);
  const [expanded, setExpanded] = React.useState<string | false>('');
  const handleChangePanel =
  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

   // Handle input change
   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if(expanded === "panel0"){
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    } 
    if(expanded === "panel1"){
    setAddressData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    }
    if(expanded === "panel2"){
    setBankDetails((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  } if(expanded === "panel3"){
    setEducationDetails((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  } if(expanded === "panel4"){
    setWorkDetails((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  }
  };

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // code for documents
  const [activeIndex, setActiveIndex] = useState<number>(0); // State to track active accordion index

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index)); // Toggle active index if it matches the clicked index
  };

  const selectOptions = [
    { value: '1', label: 'Accepted' },
    { value: '2', label: 'Rejected' },
    { value: '3', label: 'BGC3' },
  ];

  const nationalityOptions = [
    { value: 'indian', label: 'Indian' },
    { value: 'canadian', label: 'Canadian' },
  ];

  

  return (
    <section className={styles.mainAccord}>
      {/* First Accordion */}
      {/* <div className={styles.subAccord}> */}
        {/* <button
          className={`${styles.accordion} ${activeIndex === 0 ? styles.active : ''}`}
          onClick={() => toggleAccordion(1)}
        >
          <p>Personal details</p>
          <b className={styles.statusDiv}>
            <p style={{ color: '#877A0A' }}>Pending</p>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </b>
        </button> */}
        {/* <div style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
          {/* Panel content */}
          {/* <div className={styles.panelDiv}> */}
            {/* <div> */}
            {/* <Grid container spacing={3} width={"85vw"}>
              {Object.keys(formData).map((key) => (
                 <Grid item xs={12} sm={6} key={key}>
                <FormField
                  key={key}
                  label={key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())} // Convert key to label format
                  name={key}
                  value={formData[key] || ""}
                  onChange={handleChange}
                  error={formErrors[key]}
                  cssInput1="subDiv2"
                  cssInput2="label1"
                  placeholder={`Enter ${key.replace(/_/g, " ")}`}
                />
                </Grid>
              ))}
            </Grid> */}
              {/* <FormField
                label="Designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                error={formErrors.designation}
                cssInput1="formGroup4"
                cssInput2="label1"
                placeholder="Enter Details"
              />

              <FormField
                label="Last name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                error={formErrors.last_name}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Enter Details"
              />

              <div className={styles.subInput1}>
                <FormSelect
                  label="Gender"
                  name="gender"
                  value={formData.gender}
                  error={formErrors.gender}
                  inputlabel="Full time"
                  // handleChange={handleChange}
                  options={selectOptions}
                  selectCss1="subDiv2"
                  selectCss2="label1"
                  selectCss3="formControl"
                />

                <FormField
                  label="Religion"
                  name="religion"
                  value={formData.religion}
                  onChange={handleChange}
                  error={formErrors.religion}
                  cssInput1="subDiv2"
                  cssInput2="label1"
                  placeholder="None"
                />
              </div>

              <FormField
                label="Father’s name"
                name="father_name"
                value={formData.father_name}
                onChange={handleChange}
                error={formErrors.father_name}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Father’s name"
              />

              <FormField
                label="Recruiter"
                name="recruiter"
                value={formData.recruiter}
                onChange={handleChange}
                error={formErrors.recruiter}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Recruiter"
              />

              <FormField
                label="Emergency contact name"
                name="emergency_contact_name"
                value={formData.emergency_contact_name}
                onChange={handleChange}
                error={formErrors.emergency_contact_name}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Emergency contact name"
              />

              <FormField
                label="Adhaar card number"
                name="adhaar_card_number"
                value={formData.adhaar_card_number}
                onChange={handleChange}
                error={formErrors.adhaar_card_number}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="9414 6279 9908 8890"
              />
            </div>

            <div>
              <FormField
                label="First name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                error={formErrors.first_name}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="First name"
              />

              <FormField
                label="Full name"
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}
                error={formErrors.full_name}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Full Name"
              />

              <div className={styles.subInput1}>
                <FormField
                  label="Country code"
                  name="country_code"
                  value={formData.country_code}
                  onChange={handleChange}
                  error={formErrors.country_code}

                  cssInput1="subDiv2"
                  cssInput2="label1"
                  placeholder="Full Name"
                />

                <FormField
                  label="Phone number"
                  name="institution_name"
                  value={formData.phone_number}
                  onChange={handleChange}
                  error={formErrors.phone_number}
                  cssInput1="subDiv2"
                  cssInput2="label1"
                  placeholder="Full Name"
                />
              </div>

              <FormField
                label="Mother’s name"
                name="mother_name"
                value={formData.mother_name}
                onChange={handleChange}
                error={formErrors.mother_name}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Mother’s name"
              />

              <FormField
                label="Hiring manager"
                name="hiring_manager"
                value={formData.hiring_manager}
                onChange={handleChange}
                error={formErrors.hiring_manager}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Hiring manager"
              />

              <FormField
                label="Emergency contact relation with candidate"
                name="emergency_contact_relation_with_candidate"
                value={formData.emergency_contact_relation_with_candidate}
                onChange={handleChange}
                error={formErrors.emergency_contact_relation_with_candidate}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Emergency contact relation with candidate"
              />

              <FormField
                label="Name on adhaar card"
                name="name_on_adhaar_card"
                value={formData.name_on_adhaar_card}
                onChange={handleChange}
                error={formErrors.name_on_adhaar_card}

                cssInput1="subDiv2"
                cssInput2="label1"
                placeholder="Name on adhaar card"
              /> */}
            {/* </div> */}

            {/* <div className={styles.attachmainDiv}>
              <Attachment
                attachCss1="attachDiv1"
                attachCss2="subAttachDiv1"
                attachCss3="iconDiv"
                attachCss4="docimgDiv"
                imagepath={doc}
              />
              <Attachment
                attachCss1="attachDiv1"
                attachCss2="subAttachDiv1"
                attachCss3="iconDiv"
                attachCss4="docimgDiv"
                imagepath={doc}
              />

              <div className={styles.datepicker}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'MobileDatePicker', 'DesktopDatePicker']}>
                    <DemoItem label="Date of birth">
                      <MobileDatePicker defaultValue={dayjs('2022-04-17')} />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <div className={styles.datepicker}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'MobileDatePicker', 'DesktopDatePicker']}>
                    <DemoItem label="Date of birth">
                      <MobileDatePicker defaultValue={dayjs('2022-04-17')} />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div> */}
          {/* </div> */}

          {/* <div className={styles.btn}>
            <Button variant="outlined">Update candidate</Button>
            <Button variant="outlined">Ask to resubmit</Button>
            <Button type="submit" variant="contained" color="primary">
              Approve document
            </Button>
          </div>
        </div>  */}
      {/* </div> */}
      <Accordion expanded={expanded === 'panel0'} onChange={handleChangePanel('panel0')}>
        <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
        <Box display={"flex"} justifyContent={"space-between"} alignContent={"center"} width={"100%"}>
          <Typography>Personal Details</Typography>
          <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: expanded === 'panel0' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3} width={"85vw"}>
              {/* {Object.keys(formData).map((key) => {
                if(!key.includes("id") && !key.includes("doc_id") && !key.includes("user_id") && !key.includes("created_at") && !key.includes("updated_at"))
                return(
                 <Grid item xs={12} sm={6} key={key}>
                <FormField
                  key={key}
                  label={key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())} // Convert key to label format
                  name={key}
                  value={formData[key] || ""}
                  onChange={handleChange}
                  error={formErrors[key]}
                  cssInput1="subDiv2"
                  cssInput2="label1"
                  placeholder={`Enter ${key.replace(/_/g, " ")}`}
                />
                </Grid>
              )})} */}
              <PersonalDetailsForm persona='admin' formData={formData} />
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChangePanel('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box display={"flex"} justifyContent={"space-between"} alignContent={"center"} width={"100%"}>
          <Typography>Address Details</Typography>
          <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: expanded === 'panel1' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3} width={"85vw"}>
              <AddressDetailForm persona='admin' formData={addressData} />
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChangePanel('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Box display={"flex"} justifyContent={"space-between"} alignContent={"center"} width={"100%"}>
            <Typography>Bank Details</Typography>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: expanded === 'panel2' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3} width={"85vw"}>
        <BankForm  persona='admin' formData={bankDetails}/>
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChangePanel('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
        <Box display={"flex"} justifyContent={"space-between"} alignContent={"center"} width={"100%"}>
          <Typography>Education Details</Typography>
          <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: expanded === 'panel3' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3} width={"85vw"}>
              <EducationDocument persona='admin' tenthForm={tenthEducationDetails} twelthForm={twelfthEducationDetails} graduationForm={graduationEducationDetails} postGraduationForm={postGraduationEducationDetails} />
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChangePanel('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
        <Box display={"flex"} justifyContent={"space-between"} alignContent={"center"} width={"100%"}>
          <Typography>Work Experience Details</Typography>
          <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: expanded === 'panel4' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3} width={"85vw"}>
              <WorkExperienceForm persona='admin' formData={workDetails} />
            </Grid>
        </AccordionDetails>
      </Accordion>
    </section>
  );
};

export default CandidateForms;

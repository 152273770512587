import styles from './TeamSection.module.css';
import userImg from '../../../assets/img/user/profileuser.png';
import rp1 from '../../../assets/img/user/rp1.png'
import rp2 from '../../../assets/img/user/rp2.png'
import rp3 from '../../../assets/img/user/rp3.png'

import {Button } from '@mui/material';
import PhoneMenu from '../Global/Navbar/phoneMenu';


const reportingManagers = [
    { name: 'James Peterson', position: 'Position/Designation', image: rp1 },
   
  ];

const TeamSection = () => {
  return (
    <div className={styles.mainDiv}>
      <div className={styles.div1}>
        <h3>Know your team</h3>
        <></>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book. It has survived not only five centuries.
        </p>
      </div>

      <div className={styles.mainTeamDiv}>
        <div className={styles.subDiv}>
          <h4>Team</h4>
          <div className={styles.teamDiv}>
            <p>PBS</p>
            <span>UPSCALE . UI/UX</span>
          </div>
        </div>
        <div className={styles.subDiv}>
          <h4>Tower Lead</h4>
          <div className={styles.teamDiv2}>
            <div className={styles.userProfile}>
              <div>
              <img src={userImg} alt="User"  />
              </div>
              
              <div className={styles.userDesc}>
              <h3>James Peterson</h3>
              <div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.
              </p>
              </div>
             
            </div>
            </div>
           
           
           
          </div>
         
          
        </div>
      </div>

      <div className={styles.mainDiv2}>
        <h4>Reporting managers</h4>
        <div className={styles.team}>
          {reportingManagers.map((manager, index) => (
            <div className={styles.teamDiv3} key={index}>
              <div>
                <img src={manager.image} alt="User"  className={styles.reporters} />
              </div>
              <div className={styles.userDesc}>
                <h3 className={styles.userName}>{manager.name}</h3>
                <h5 className={styles.userPos}>{manager.position}</h5>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.btn}>
          {/* <Button type="submit" variant="contained" color="primary">
            Need Help?
          </Button> */}
        </div>
      </div>
      <PhoneMenu/>
    </div>
  );
};

export default TeamSection;

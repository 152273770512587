// FormField.tsx
import React from 'react';
import { TextField, SxProps } from '@mui/material';
import styles from '../Documents.module.css'; // Ensure your CSS module path is correct

interface FormFieldProps {
  label?: string;
  name?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: string | undefined;
  cssInput1?: keyof typeof styles; 
  cssInput2?: keyof typeof styles; 
  cssInput3?: keyof typeof styles; 
  cssInput4?: keyof typeof styles; 
  cssInput5?: keyof typeof styles; 
  rows?: number;
  cols?: number;
  isTextarea?: boolean;
  placeholder?: string;
  id?:string;
  type:string;
  sx?: SxProps;
  readOnly?:boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  value,
  onChange,
  error,
  cssInput1,
  cssInput2,
  cssInput3,
  cssInput4,
  cssInput5,
  rows,
  cols,
  isTextarea = false,
  placeholder,
  type,
  readOnly,
  sx,
  
}) => {
  return (
    <div className={cssInput1 ? styles[cssInput1] : styles.cssInput1}>
      <p className={cssInput2 ? styles[cssInput2] : styles.cssInput2} style={{ paddingBottom: '10px' }}>
        {label} <span style={{ color: 'red' }}>*</span>
      </p>
      <div className={cssInput3 ? styles[cssInput3] : styles.cssInput3}>
        {isTextarea ? (
          <TextField
            placeholder={placeholder}
            rows={rows}
            multiline
            className={cssInput5 ? styles[cssInput5] : styles.cssInput5}
            name={name}
            value={value}
            onChange={onChange}
            aria-labelledby={label}
            id={`${name}-label`}
            required
            
            sx={{...sx, padding: "padding: 10px 45px", borderColor: 'red', borderRadius: '8px' }}
            disabled={readOnly}
          />
        ) : (
          <TextField
            // label={placeholder}
            fullWidth
            placeholder={placeholder}
            variant="outlined"
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            disabled={readOnly}
            className={cssInput4 ? styles[cssInput4] : styles.cssInput4 }
            sx={{ 
              ...sx,
              backgroundColor:"white",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'EBEBEB',
                  borderRadius: '4px',
                  backgroundColor:""
                },
                '&:hover fieldset': {
                  borderColor: '',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '',
                },
              },
            }}            aria-labelledby={`${name}-label`}
            id={`${name}-label`}
            
            required
          />
        )}
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
};

export default FormField;

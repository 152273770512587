import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import theme from './theme/theme';

import Layout from './pages/CandidatePages/Layout';
import Layout2 from './pages/CandidatePages/Layout2';
// import Layout3 from './pages/CandidatePages/Layout3';
import Layout4 from './pages/CandidatePages/Layout4';
import PersonalDetails from './components/CandidateSecion/Profile/mainProfile/personalDetails';
import Team from './pages/CandidatePages/Team';
import Faq from './pages/CandidatePages/Faq';
import Contact from './pages/CandidatePages/Contact';

import DocumentHelp from './pages/CandidatePages/DocumentHelp';
import { ActiveItemProvider } from 'project-name/components/CandidateSecion/Global/Navbar/ActiveItemContext';

import AdminHome from './pages/AdminPages/adminHome';
import AdminPersonalLayout from './pages/AdminPages/adminPersonalLayout';
import LoginScreen from './pages/AdminPages/LoginScreen1'
import CandidateLayout from './pages/AdminPages/CandidateLayout1';
import CandidateProfile from './pages/AdminPages/CandidateProfile1';
import RecruitersLayout from './pages/AdminPages/recruitersLayout'
import RecruiterProfile from './pages/AdminPages/recruiterProfile';
import TicketsLayout from './pages/AdminPages/ticketsLayout';
import EducatinDocLayout from './pages/CandidatePages/Forms/EducationDocLayout';
import PersonalFormLayout from './pages/CandidatePages/Forms/PersonalFormLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AddressDetailFormLayout from './pages/CandidatePages/Forms/AddressDetailFormLayout';
import BankFormLayout from './pages/CandidatePages/Forms/BankFormLayout';
import WorkExperienceLayout from './pages/CandidatePages/Forms/WorkExperienceLayout'
import ProtectedRoute from './PrivateRoute';
import OtherDocLayout from './pages/CandidatePages/Forms/OtherDetailsLayout';
import AcknowledgeDocLayout from './pages/CandidatePages/Forms/AcknowledgementFormLayout';
import useSetupInterceptors from './api/InterceptorHook';
import CandidateDetails from './components/AdminSection/CandiateSection/CandidateProfileSection/OnboardingSlider/CandidateDetailsSection/CandidateDetails';
import AddCandidatePage from './pages/AdminPages/AddCandidatePage';
import AddCandidateSection from './pages/AdminPages/AddCandidateSection';
import ForgetPasswordPage from './pages/AdminPages/ForgetPasswordPage';
import NeedHelpPage from './pages/NeedHelp';







const App = () => {
  return (
    <ThemeProvider theme={theme}>
    <ActiveItemProvider>
      <BrowserRouter>
      <InterceptorSetup />
        <Routes>
          {/* candidate screens */}
         {/* candidate screens */}
    <Route path="/login" element={<LoginScreen />} />
    <Route path="/" element={<ProtectedRoute element={Layout} persona={'user'} />} />
    <Route path="/user" element={<ProtectedRoute element={Layout2} persona={'user'}/>} />
    <Route path="/documents" element={<ProtectedRoute element={Layout4} persona={'user'}/>} />
    <Route path="/edit/profile" element={<ProtectedRoute element={PersonalDetails} persona={'user'}/>} />
    <Route path="/team" element={<ProtectedRoute element={Team} persona={'user'}/>} />
    <Route path="/faq" element={<ProtectedRoute element={Faq} persona={'user'}/>} />
    <Route path="/contact" element={<ProtectedRoute element={Contact} persona={'user'}/>} />
    <Route path="/document-help" element={<ProtectedRoute element={DocumentHelp} persona={'user'}/>} />
    <Route path="/need-help" element={<NeedHelpPage/>} />

    {/* Candidate forms */}
    <Route path="/candidate/upload-education-details" element={<ProtectedRoute element={EducatinDocLayout} persona={'user'} />} />
    <Route path="/candidate/upload-personal-details" element={<ProtectedRoute element={PersonalFormLayout}  persona={'user'}/>} />
    <Route path="/candidate/upload-address-details" element={<ProtectedRoute element={AddressDetailFormLayout} persona={'user'}/>} />
    <Route path="/candidate/upload-bank-details" element={<ProtectedRoute element={BankFormLayout} persona={'user'}/>} />
    <Route path="/candidate/upload-work-experience-details" element={<ProtectedRoute element={WorkExperienceLayout} persona={'user'}/>} />
    <Route path="/candidate/upload-other-details" element={<ProtectedRoute element={OtherDocLayout}persona={'user'} />} />
    <Route path="/candidate/upload-acknowledgment-details" element={<ProtectedRoute element={AcknowledgeDocLayout}persona={'user'} />} />

    {/* Admin screens */}
    <Route path="/admin-home" element={<ProtectedRoute element={AdminHome} persona={'admin'} />} />
    <Route path="/forget-password" element={<ProtectedRoute element={ForgetPasswordPage} persona={'admin'} />} />
    <Route path="/edit/admin-profile" element={<ProtectedRoute element={AdminPersonalLayout} persona={'admin'} />} />
    <Route path="/candidate-details" element={<ProtectedRoute element={CandidateDetails} persona={'admin'}/>} />
    <Route path="/add-candidate" element={<ProtectedRoute element={AddCandidateSection} persona={'admin'}/>} />
    <Route path="/candidate" element={<ProtectedRoute element={CandidateLayout} persona={'admin'}/>} />
    <Route path="/candidate-profile" element={<ProtectedRoute element={CandidateProfile} persona={'admin'}/>} />
    <Route path="/recruiters" element={<ProtectedRoute element={RecruitersLayout} persona={'admin'}/>} />
    <Route path="/recruiter-profile" element={<ProtectedRoute element={RecruiterProfile} persona={'admin'}/>} />
    <Route path="/tickets" element={<ProtectedRoute element={TicketsLayout} persona={'admin'}/>} />
    <Route path="/unauthorized" element={<div>Unauthorized</div>} />
    {/* <Route path="/documentHelp" element={<ProtectedRoute element={DocumentHelp} persona={'admin'}/>} /> */}
    <Route path="*" element={<div>Not Found</div>} />
  </Routes>      </BrowserRouter>
    </ActiveItemProvider>
  </ThemeProvider>
  )
};

const InterceptorSetup = () => {
  useSetupInterceptors();
  return null;
};

export default App;

import React, { useEffect } from "react"
import styles from 'project-name/css/admin/adminLayout.module.css'
import HorizontalNavbar from "../../components/AdminSection/Global/Navbar/HorizontalNavbar";
import Breadcrumb from "project-name/components/CandidateSecion/Documents/Breadcrumb"

import { fetchAdminProfile } from 'project-name/Redux/features/adminProfileSlice';
import { useDispatch } from 'react-redux';
import {
    AppDispatch
} from 'project-name/Redux/Store';
import AddCandidatePage from "project-name/pages/AdminPages/AddCandidatePage";


const AddCandidateSection: React.FC = () => {
    // const threeDiv = true
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {

        dispatch(fetchAdminProfile());

    }, [dispatch]);
    return (
        <div data-testid="candidate-section">
            <div className={styles.mDiv} data-testid="candidate-list">
                <HorizontalNavbar />
                <div style={{ padding: '40px' }}>
                    <Breadcrumb threeDiv bread1="Home" bread2="Candidate profiles" bread3="Candidate profile" link1="/admin-home" link2="/candidate" /></div>


                <AddCandidatePage />
            </div>
        </div>

    )
}

export default AddCandidateSection

// candidateSlice.ts
import { createSlice } from '@reduxjs/toolkit';

export interface Status {
    doc_type_name: string;
  id:string
}

interface StatusState {
   documentList:Status[]
}

const initialState: StatusState = {
    documentList:[]
    
};

const OtherStatusSlice = createSlice({
    name: 'StatusList',
    initialState,
    reducers: {
        setOtherCompletedDocuments: (state, action:any) => {
        // update document List
        state.documentList = action.payload;
        },
    },
});

export const { setOtherCompletedDocuments } = OtherStatusSlice.actions;
export default OtherStatusSlice.reducer;